export const BASE_URL = "https://test-backend-laravel.merchhq.io/";
// export const BASE_URL = "http://192.168.1.40/merchhq/public/";

export const URL = {
  REGISTER: "vendor/vendor-store",
  LOGIN: "vendor/vendor-user-login",
  VENDOR_CATEGORY: "vendor/parent-categories",
  REGISTER_VIEW: "vendor/vendor-view/",
  VERIFICATION: "vendor/verification/",
  PROFILE_LIST: "vendor/vendor-user-list/",
  USER_LIST: "vendor/vendor-user-list",
  STATUS_LIST: "vendor/vendor-user-status/",
  ADD_PROFILE: "vendor/vendor-user-store",
  ROLE_LIST: "vendor/admin/role/active-list",
  ROLE_ACTIVE_LIST: "/vendor/admin/role/lists",
  EDIT_PROFILE: "vendor/vendor-user-edit",
  PROFILE_VIEW: "vendor/vendor-user-view/",
  ROLE_VIEW: "vendor/role/",
  ROLE_CREATE: "vendor/role/create",
  VENDOR_PARENT_CATEGORIES: "crm/parent-categories",
  FORGOT_PASSWORD: "/vendor/forgot-password",
  RESET_PASSWORD: "/vendor/reset-password",
  CURRENCY: "/crm/active/currency",
};

export const TOKEN = "token";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";

export const NUMBER = /^\+?(0|[6-9]\d*)$/;
export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const productDetailImg = {
  loop: true,
  autoplay: false,
  center: true,
  dots: true,
  autoplayTimeout: 3000,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
    },
  },
};
