import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, URL } from "./constants";
import { getToken } from "../store/userTocken";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem("token");
      headers.set("Authorization", "Bearer " + token);
      //   headers.set("Content-Type", "multipart/form-data");
      //   headers.set("Content-Type", "application/json");
      //   headers.set("Accept", "application/json");
    },
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: [],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (payload) => ({
        url: URL.LOGIN,
        method: "POST",
        body: payload,
        headers: {},
      }),
    }),
    vendor_category: builder.query({
      query: () => ({
        url: URL.VENDOR_CATEGORY,
      }),
    }),
    register: builder.mutation({
      query: (payload) => ({
        url: URL.REGISTER,
        method: "POST",
        body: payload,
      }),
    }),
    register_view: builder.query({
      query: (id) => ({
        url: URL.REGISTER_VIEW + id,
      }),
    }),
    verification: builder.query({
      query: (token) => ({
        url: URL.VERIFICATION + token,
      }),
    }),
    //PROFILE
    profile_list: builder.query({
      query: (id) => ({
        url: URL.PROFILE_LIST + id,
      }),
    }),

    //PROFILE
    profileList: builder.mutation({
      query: ({ params, payload }) => ({
        url: URL.USER_LIST + params,
        method: "POST",
        body: payload,
      }),
    }),

    profile_status: builder.query({
      query: (id) => ({
        url: URL.STATUS_LIST + id,
      }),
    }),
    profile_view: builder.query({
      query: (id) => ({
        url: URL.PROFILE_VIEW + id,
      }),
    }),
    addProfile: builder.mutation({
      query: (payload) => ({
        url: URL.ADD_PROFILE,
        method: "POST",
        body: payload,
      }),
    }),
    editProfile: builder.mutation({
      query: (payload) => ({
        url: URL.EDIT_PROFILE,
        method: "POST",
        body: payload,
      }),
    }),
    roleList: builder.mutation({
      query: (payload) => ({
        url: URL.ROLE_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    roleActiveList: builder.mutation({
      query: (payload) => ({
        url: URL.ROLE_ACTIVE_LIST,
        method: "POST",
        body: payload,
      }),
    }),

    roleLists: builder.mutation({
      query: ({ params, payload }) => ({
        url: URL.ROLE_ACTIVE_LIST + params,
        body: payload,
        method: "POST",
      }),
    }),

    roleview: builder.query({
      query: (id) => ({
        url: URL.ROLE_VIEW + id,
      }),
    }),

    roleModule: builder.query({
      query: () => URL.ROLE_CREATE,
    }),

    // CATERGORY LIST VENDOR
    categoriesListVendor: builder.query({
      query: () => URL.VENDOR_PARENT_CATEGORIES,
    }),

    // FORGOT PASSWORD
    forgotPassword: builder.mutation({
      query: (payload) => ({
        url: URL.FORGOT_PASSWORD,
        method: "POST",
        body: payload,
      }),
    }),

    // RESET PASSWORD
    resetPassword: builder.mutation({
      query: (payload) => ({
        url: URL.RESET_PASSWORD,
        method: "POST",
        body: payload,
      }),
    }),

    // CURRENCY LIST
    currencyList: builder.query({
      query: () => URL.CURRENCY,
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useLazyRegister_viewQuery,
  useLazyVerificationQuery,
  useLazyProfile_listQuery,
  useLazyProfile_statusQuery,
  useAddProfileMutation,
  useEditProfileMutation,
  useLazyVendor_categoryQuery,
  useRoleListMutation,
  useLazyProfile_viewQuery,
  useRoleActiveListMutation,
  useLazyRoleviewQuery,
  useLazyRoleModuleQuery,
  useLazyCategoriesListVendorQuery,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useRoleListsMutation,
  useProfileListMutation,
  useLazyCurrencyListQuery,
} = api;
