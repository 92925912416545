import ProfileNavBar from "../components/ProfileNavBar";
import { Search } from "@mui/icons-material";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import intelImg from "../assets/img/download.jpeg";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WestIcon from "@mui/icons-material/West";
import { useLazyProfile_viewQuery } from "../redux/api/api";
import { useEffect, useState } from "react";
import loderimage from "../assets/img/loderimage.png"

const ViewProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;


  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [Email, setEmail] = useState("");
  const [Role, setRole] = useState('');
  const [rolelist,setRoleList]=useState([])
  const [banner, setBanner] = useState();

  //RTK
  const [viewApi]=useLazyProfile_viewQuery()
  

  const getview=()=>{
    viewApi(location?.state?.data?.id).
    unwrap().
    then(res=>{
      console.log(res)
    const data=res?.vendor_user
    setFirst_name(data?.first_name)
    setLast_name(data?.last_name)
    setEmail(data?.email)
    setRole(data?.role?.role)
    setBanner(data?.image_url)
    }).catch(err=>{})
  }
 useEffect(()=>{
  if(location?.state?.data?.id){

    getview()
  }
  },[])
  return (
    <div className="dashRightView overflow-scroll">
      <div className="d-flex w-90 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, number or PO# to search"
        />
        <img src={manImage} className="searchManImg" />
      </div>
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> Profile Details
      </p>
      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            First Name
          </p>
          <input
            placeholder="First Name"
            value={first_name?first_name:""}
            disabled
            className=" editBtn mt-lg-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Last Name
          </p>
          <input
            placeholder="Last Name"
            disabled
            value={last_name?last_name:""}
            className=" editBtn mt-lg-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
      </div>
      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Email
          </p>
          <input
            placeholder="Email"
            value={Email?Email:""}
            disabled
            className=" editBtn mt-lg-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Roles
          </p>
          <input
            placeholder="Roles"
            disabled
            value={Role?Role:""}
            className=" editBtn mt-lg-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
      </div>

      <div className="w-90 mt-5 d-flex flex-md-row flex-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Profile Image
          </p>
          <img src={banner?banner:loderimage} className="proImg mt-lg-2" />
        </div>
      </div>
      <button
        onClick={() => navigate("/profile")}
        className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
      >
        Back
      </button>
    </div>
  );
};

export default ViewProfile;
