import { Search } from "@mui/icons-material";
import { animatedImg, profilePic } from "../assets/img";
import BusinessIcon from "@mui/icons-material/Business";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ProfileDetails from "../components/VendorDetails/ProfileDetails";
import EditProfileDetails from "../components/VendorDetails/EditProfileDetails";
import HistoryDetails from "../components/VendorDetails/HistoryDetails";
import EditContactPopup from "../components/Popup/EditContactPopup";
import EditAddressPopup from "../components/Popup/EditAddressPopup";
import ContactDetails from "../components/VendorDetails/ContactDetails";
import PositionPopup from "../components/Popup/PositionPopup";
import LibraryDetails from "../components/VendorDetails/LibraryDetails";
import DecorativeDetails from "../components/DecorativeVendor/DecorativeDetails";
import { useSelector } from "react-redux";
import POSandBills from "../components/VendorDetails/POSandBills";
import CollaborateList from "../components/VendorDetails/CollaborateList";

const DecorativeVendorScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location?.state?.type);
  const formtypes = location?.state?.type;
  console.log(location?.pathname);
  const path = location?.pathname;
  const vendor = location?.state;
  console.log(vendor);
  const vendor3 = location?.state?.form;
  const name = location?.state?.catogory;
  console.log(name, "sam");
  // console.log(location.state.type);
  // console.log(location.state);
  const [profile, setProfile] = useState(false);
  const [contact, setContact] = useState(false);
  const [library, setLibrary] = useState(false);
  const [products, setProducts] = useState(false);
  const [bills, setBills] = useState(false);
  const [history, setHistory] = useState(false);
  const [collaborate, setCollaborate] = useState(false);
  const [productName, setProductName] = useState("productTab");

  const [editProfile, setEditProfile] = useState(false);
  const [contactPopup, setContactPopup] = useState(false);
  const [addressPopup, setAddressPopup] = useState(false);
  const [positionPopup, setPositionPopup] = useState(false);

  const [action, setAction] = useState(false);
  console.log(location?.state?.type);

  const place = location?.state?.type;
  useEffect(() => {
    if (location?.state?.type === "library") {
      // setContact(true);
      // setContactPopup(true);

      setProfile(false);
      setContact(false);
      setLibrary(true);
      setProducts(false);
      setProducts(false);
      setHistory(false);
      setEditProfile(false);
      setCollaborate(false);
    } else if (location?.state?.type === "collaborate") {
      setCollaborate(true);
    } else if (vendor3 === "decorative") {
      setProfile(false);
      setContact(false);
      setLibrary(false);
      setProducts(false);
      setProducts(true);
      setHistory(false);
      setEditProfile(false);
      setCollaborate(false);
    } else if (
      location?.pathname === "/vendor-details" ||
      location?.pathname === "/vendor-details"
    ) {
      setProfile(false);
      setContact(false);
      setLibrary(false);
      setProducts(false);
      setProducts(true);
      setHistory(false);
      setEditProfile(false);
      setCollaborate(false);
    } else {
      setProfile(true);
    }
    if (vendor === "decorative") {
      setProfile(false);
      setContact(false);
      setLibrary(false);
      setProducts(true);
      setBills(false);
      setHistory(false);
      setEditProfile(false);
      setCollaborate(false);
    }
  }, []);

  const showPopupHander = () => {
    setContactPopup(false);
    setAddressPopup(false);
    setPositionPopup(false);
  };

  return (
    <div className="dashRightView p-5">
      {contactPopup && (
        <EditContactPopup
          setContactPopup={setContactPopup}
          showPopupHander={showPopupHander}
        />
      )}
      {addressPopup && (
        <EditAddressPopup
          setAddressPopup={setAddressPopup}
          showPopupHander={showPopupHander}
        />
      )}
      {positionPopup && <PositionPopup showPopupHander={showPopupHander} />}
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, number or PO# to search"
        />
        <img src={animatedImg} className="searchManImg" />
      </div>
      <div className="w-100 bannerVendor p-1">
        <div className="d-flex as-jb flex-md-row flex-column ms-xl-5 ms-lg-5 ms-md-5 vendorDetSpace w-xs-100 w-sm-100 w-md-90 w-lg-90 w-xl-90">
          <div className=" d-lg-flex d-xl-flex d-md-flex as-jc">
            <img src={profilePic} className="profileImg" />
            <div className="ms-xl-5 ms-lg-5 ms-md-5">
              <p className="f4 fs-xs-22 fs-sm-23 fs-md-24 fs-lg-25 fs-xl-26 fs-xxl-27">
                John Rolph
              </p>
              <div className="d-flex mt-2">
                <BusinessIcon className="text-light f4 fs-xs-15 fs-sm-17 fs-md-18 fs-lg-19 fs-xl-20 fs-xxl-21" />
                <p className="text-light ms-2 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  Ecphasis Infotech
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column ac-jc ">
            <button
              onClick={() => setAction(!action)}
              className="mb-3 cust-btn addbtn mt-sm-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Actions
            </button>
            {action && (
              <div
                onClick={() =>
                  navigate("/add-vendor", { state: { type: "email" } })
                }
                className="bg-light pointerView px-2 py-2 ac-jc rounded-3"
              >
                <h6 className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14">
                  Edit Profile
                </h6>
                {/* <div className="d-flex flex-column">
                  <button onClick={() =>
                    navigate("/add-vendor", { state: { type: "email" } })
                  } className="cust-btn primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14">
                    Email Accounts
                  </button>
                  <button onClick={() =>
                    navigate("/add-vendor", { state: { type: "email" } })
                  } className="cust-btn primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14">
                    Our Accounts
                  </button>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-100 d-flex mt-4">
        <div className="d-flex flex-wrap mt-3 w-xs-100 ac-jc">
          <div
            onClick={() => {
              setProfile(true);
              setContact(false);
              setLibrary(false);
              setProducts(false);
              setBills(false);
              setHistory(false);
              setEditProfile(false);
              setCollaborate(false);
            }}
            className={`${
              profile || editProfile
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } pointerView`}
          >
            <p
              className={`${
                profile || editProfile ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Profile
            </p>
          </div>
          <div
            onClick={() => {
              setProfile(false);
              setContact(true);
              setLibrary(false);
              setProducts(false);
              setBills(false);
              setHistory(false);
              setEditProfile(false);
              setCollaborate(false);
            }}
            className={`${
              contact
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
          >
            <p
              className={`${
                contact ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Contact
            </p>
          </div>
          <div
            onClick={() => {
              setProfile(false);
              setContact(false);
              setLibrary(true);
              setProducts(false);
              setBills(false);
              setHistory(false);
              setEditProfile(false);
              setCollaborate(false);
            }}
            className={`${
              library
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
          >
            <p
              className={`${
                library ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Library
            </p>
          </div>
          <div
            onClick={() => {
              setProfile(false);
              setContact(false);
              setLibrary(false);
              setProducts(true);
              setBills(false);
              setHistory(false);
              setEditProfile(false);
              setCollaborate(false);
            }}
            className={`${
              products
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
          >
            <p
              className={`${
                products ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Decoration
            </p>
          </div>
          <div
            onClick={() => {
              setProfile(false);
              setContact(false);
              setLibrary(false);
              setProducts(false);
              setBills(true);
              setHistory(false);
              setEditProfile(false);
              setCollaborate(false);
            }}
            className={`${
              bills
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-2 pointerView`}
          >
            <p
              className={`${
                bills ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              PO
            </p>
          </div>
          <div
            onClick={() => {
              setProfile(false);
              setContact(false);
              setLibrary(false);
              setProducts(false);
              setBills(false);
              setHistory(true);
              setEditProfile(false);
            }}
            className={`${
              history
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-3 pointerView`}
          >
            <p
              className={`${
                history ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Meeting History
            </p>
          </div>
          <div
            onClick={() => {
              setProfile(false);
              setContact(false);
              setLibrary(false);
              setProducts(false);
              setBills(false);
              setHistory(false);
              setEditProfile(false);
              setCollaborate(true);
            }}
            className={`${
              collaborate
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            }  ms-xl-5 ms-lg-5 ms-md-3 ms-sm-3 ms-3 pointerView`}
          >
            <p
              className={`${
                collaborate ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Collaborate
            </p>
          </div>
        </div>
      </div>
      {profile && (
        <ProfileDetails
          setEditProfile={setEditProfile}
          setProfile={setProfile}
          setAddressPopup={setAddressPopup}
          setContactPopup={setContactPopup}
          setHistory={setHistory}
          setPositionPopup={setPositionPopup}
          name={name}
          path={path}
        />
      )}
      {editProfile && (
        <EditProfileDetails
          setContactPopup={setContactPopup}
          setAddressPopup={setAddressPopup}
          setPositionPopup={setPositionPopup}
          setEditProfile={setEditProfile}
          setProfile={setProfile}
          setHistory={setHistory}
        />
      )}
      {history && <HistoryDetails />}
      {contact && (
        <ContactDetails
          setContactPopup={setContactPopup}
          setAddressPopup={setAddressPopup}
          setPositionPopup={setPositionPopup}
        />
      )}
      {library && <LibraryDetails />}
      {products && (
        <DecorativeDetails
          name={name}
          place={place}
          formtypes={formtypes}
          path={path}
          vendor={vendor}
        />
      )}
      {bills && <POSandBills type={"decorative"} />}
      {collaborate && <CollaborateList />}
    </div>
  );
};

export default DecorativeVendorScreen;
