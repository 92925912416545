import { animatedImg, empty_profile } from "../../assets/img";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { useState } from "react";
import AddNotes from "./AddNotes";
import DownloadIcon from "@mui/icons-material/Download";
import PushPinIcon from "@mui/icons-material/PushPin";
import DeleteIcon from "@mui/icons-material/Delete";

const ViewThread = () => {
  const [reply, setReply] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="dashRightView p-5">
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, number or PO# to search"
        />
        <img src={animatedImg} className="searchManImg" />
      </div>
      <div className="d-flex flex-md-row flex-column mt-5 w-100">
        <div className="w-lg-50 w-md-100 w-sm-100 w-xs-100 dailyStatus mt-4 rounded-3">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Profile Post Details
          </p>
        </div>
      </div>
      <div className="w-100 ac-jc mt-5">
        <div className="w-100 d-flex  ac-jc gap-3">
          <div>
            <img src={empty_profile} className="replyImg rounded-3" />
          </div>
          <div className="w-100">
            <p className="primary f3 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18">
              Roger Daniel{" "}
              <span className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Hourglass Essentials Pvt. Ltd.
              </span>
            </p>
            <p className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              Roger added the vendor account{" "}
              <span className="text-black f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                Charles
              </span>
            </p>
            <p className="primary1 f3 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
              Tagged in this post.
            </p>
          </div>
        </div>
        <div className="mt-3 w-xl-100 w-lg-100 w-xs-100 w-sm-100 d-flex ac-jb">
          <div onClick={() => setReply(true)} className="d-flex pointerView">
            <ChatBubbleOutlineIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
            <p className="black f2 ms-1 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
              Reply
            </p>
          </div>
          <div onClick={() => setReply(true)} className="d-flex pointerView">
            <DownloadIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
            <p className="black f2 ms-1 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
              Download
            </p>
          </div>
          <div onClick={() => setReply(true)} className="d-flex pointerView">
            <DeleteIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
            <p className="black f2 ms-1 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
              Delete
            </p>
          </div>
        </div>
        <div className="">
          <p className="primary f2 ms-1 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
            Augest 23, 2023. 3:00 AM
          </p>
        </div>
      </div>
      <AddNotes type="thread" />
    </div>
  );
};

export default ViewThread;
