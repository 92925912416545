import { Search } from "@mui/icons-material";
import React from "react";
import CachedIcon from "@mui/icons-material/Cached";

const ProductLibraryComp = () => {
    return (
        <div className="mt-5 banner-left">
            <div className="d-flex ac-jb flex-column flex-md-row">
                <div className="d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
                    <input
                        type="text"
                        className="get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3"
                        placeholder="Product Name"
                    />
                    <button className="get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
                        <select className="bg-transparent border-0 py-3 primary1">
                            <option value="7">Vendors </option>
                            <option value="8">Mercedes</option>
                            <option value="9">Mini</option>
                            <option value="10">Nissan</option>
                            <option value="11">Toyota</option>
                            <option value="12">Volvo</option>
                        </select>{" "}
                    </button>
                    <input
                        type="text"
                        className="get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4 px-3"
                        placeholder="Lead time"
                    />
                    <button className="get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
                        <select className="bg-transparent border-0 py-3 primary1">
                            <option value="0">Tagged</option>
                            <option value="1">Audi</option>
                            <option value="2">BMW</option>
                            <option value="3">Citroen</option>
                            <option value="4">Ford</option>
                            <option value="5">Honda</option>
                            <option value="6">Jaguar</option>
                            <option value="7">Land Rover</option>
                            <option value="8">Mercedes</option>
                            <option value="9">Mini</option>
                            <option value="10">Nissan</option>
                            <option value="11">Toyota</option>
                            <option value="12">Volvo</option>
                        </select>
                    </button>
                    <input
                        type="text"
                        className="get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3"
                        placeholder="Net min"
                    />
                    <input
                        type="text"
                        className="get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3"
                        placeholder="Net max"
                    />
                    <button className="get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
                        <select className="bg-transparent border-0 py-3 primary1">
                            <option value="1">Ratings </option>
                            <option value="2">1</option>
                            <option value="3">2</option>
                            <option value="4">3</option>
                            <option value="5">4</option>
                            <option value="6">5</option>
                        </select>
                    </button>
                </div>
                <div className="d-flex ac-jc gap-3">
                    <button
                        // onClick={() => navigate("/add-vendor")}
                        className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
                    >
                        <Search />
                    </button>
                    <button
                        // onClick={() => navigate("/add-vendor")}
                        className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
                    >
                        <CachedIcon />
                    </button>
                </div>
            </div>
            <div className="mt-md-3  mt-4">
                <p className=" f2 primary1 mb-0">
                    Your Hourglass Essentials Pvt. Ltd. database contains products that
                    you or your co-workers have added directly into commonsku. These
                    products will often be Hourglass Essentials Pvt. Ltd.'s go-to
                    products, preferred supplier products, or local decorators that may
                    not be on commonsku otherwise.
                </p>
                <p className=" f2 primary1 mb-0">
                    Enter some criteria to search for products.
                </p>
            </div>
        </div>
    );
};

export default ProductLibraryComp;
