import React, { useState } from 'react'
import { animatedImg, product } from '../../assets/img'
import { useNavigate } from 'react-router-dom'

const ProductListLibraryComp = ({ productList, setParoductList }) => {
    // const [productDetail, setProductDetail] = useState(false)
    const navigate = useNavigate()
    console.log(productList)
    return (
        <div className="w-100 d-flex ac-js mt-5 flex-wrap">
            {(productList == 'product' || productList == 'both') && <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
                onClick={() => navigate('/product-library-details', { state: { type: 'productList' } })}
            >
                <div className="prod-list-box">
                    <div className="cust-btn d-flex ac-jc rounded-3">
                        <img src={animatedImg} alt="" className="custom-w-h rounded-3" />
                    </div>
                    <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">5SK Suppliers</p>
                    <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">$10.00 (INR)</p>
                    <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">#5SK 001</p>
                </div>
            </div>}
            {(productList == 'product' || productList == 'both') &&
                <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
                    onClick={() => navigate('/product-library-details', { state: { type: productList } })}
                >
                    <div className="prod-list-box">
                        <div className="cust-btn d-flex ac-jc rounded-3">
                            <img src={animatedImg} alt="" className="custom-w-h rounded-3" />
                        </div>
                        <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">5SK Suppliers</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">$10.00 (INR)</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">#5SK 001</p>
                    </div>
                </div>
            }
            {(productList == 'decorative' || productList == 'both') &&
                <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
                    onClick={() => { navigate('/product-library-details', { state: { type: productList } }); setParoductList('decorative') }}
                >
                    <div className="prod-list-box">
                        <div className="cust-btn d-flex ac-jc rounded-3">
                            <img src={product} alt="" className="custom-w-h rounded-3" />
                        </div>
                        <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">5SK Suppliers</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">$10.00 (INR)</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">#5SK 001</p>
                    </div>
                </div>
            }
            {(productList == 'product' || productList == 'both') &&
                <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
                    onClick={() => navigate('/product-library-details', { state: { type: productList } })}
                >
                    <div className="prod-list-box">
                        <div className="cust-btn d-flex ac-jc rounded-3">
                            <img src={animatedImg} alt="" className="custom-w-h rounded-3" />
                        </div>
                        <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">5SK Suppliers</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">$10.00 (INR)</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">#5SK 001</p>
                    </div>
                </div>
            }
            {(productList == 'decorative' || productList == 'both') &&
                <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
                    onClick={() => { navigate('/product-library-details', { state: { type: productList } }); setParoductList('decorative') }}
                >
                    <div className="prod-list-box">
                        <div className="cust-btn d-flex ac-jc rounded-3">
                            <img src={product} alt="" className="custom-w-h rounded-3" />
                        </div>
                        <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">5SK Suppliers</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">$10.00 (INR)</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">#5SK 001</p>
                    </div>
                </div>
            }
            {(productList == 'decorative' || productList == 'both') &&
                <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2" decorative
                    onClick={() => { navigate('/product-library-details', { state: { type: productList } }); setParoductList('decorative') }}
                >
                    <div className="prod-list-box">
                        <div className="cust-btn d-flex ac-jc rounded-3">
                            <img src={product} alt="" className="custom-w-h rounded-3" />
                        </div>
                        <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">5SK Suppliers</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">$10.00 (INR)</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">#5SK 001</p>
                    </div>
                </div>
            }
            {(productList == 'decorative' || productList == 'both') &&
                <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
                    onClick={() => { navigate('/product-library-details', { state: { type: productList } }); setParoductList('decorative') }}
                >
                    <div className="prod-list-box">
                        <div className="cust-btn d-flex ac-jc rounded-3">
                            <img src={product} alt="" className="custom-w-h rounded-3" />
                        </div>
                        <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">5SK Suppliers</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">$10.00 (INR)</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">#5SK 001</p>
                    </div>
                </div>
            }
            {(productList == 'decorative' || productList == 'both') &&
                <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
                    onClick={() => { navigate('/product-library-details', { state: { type: productList } }); setParoductList('decorative') }}
                >
                    <div className="prod-list-box">
                        <div className="cust-btn d-flex ac-jc rounded-3">
                            <img src={product} alt="" className="custom-w-h rounded-3" />
                        </div>
                        <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">5SK Suppliers</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">$10.00 (INR)</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">#5SK 001</p>
                    </div>
                </div>
            }
            {(productList == 'decorative' || productList == 'both') &&
                <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
                    onClick={() => { navigate('/product-library-details', { state: { type: productList } }); setParoductList('decorative') }}
                >
                    <div className="prod-list-box">
                        <div className="cust-btn d-flex ac-jc rounded-3">
                            <img src={product} alt="" className="custom-w-h rounded-3" />
                        </div>
                        <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">5SK Suppliers</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">$10.00 (INR)</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">#5SK 001</p>
                    </div>
                </div>
            }
            {
                (productList == 'product' || productList == 'both') && <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
                    onClick={() => navigate('/product-library-details', { state: { type: productList } })}
                >
                    <div className="prod-list-box">
                        <div className="cust-btn d-flex ac-jc rounded-3">
                            <img src={animatedImg} alt="" className="custom-w-h rounded-3" />
                        </div>
                        <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">5SK Suppliers</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">$10.00 (INR)</p>
                        <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">#5SK 001</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default ProductListLibraryComp