import React, { useState } from 'react'
import { product } from '../../assets/img'

const LibraryVendorPricingComp = () => {
    const [customer, setCustomer] = useState(false)
    const [reseller, setReseller] = useState(false)

    const [marginShow, SetMarginShow] = useState(false)
    return (
        <div className="h-auto mt-4">
            <p className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">Vendor Pricing</p>
            <div className=''>
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-3">
                    5SK Supplier
                </p>
                <div className='overflow-scroll'>
                    <table className='w-100'>
                        <tr className='mt-4'>
                            <th className='primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>Qty</th>
                            <div className='my-3'>
                                <td>
                                    <div className="d-flex w-100">
                                        <div className="mx-2">
                                            <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                type="button"
                                                value='25'
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                type="button"
                                                value='50'
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                type="button"
                                                value='100'
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                type="button"
                                                value='150'
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                type="button"
                                                value='250'
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                type="button"
                                                value='500'
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                type="button"
                                                value='1000'
                                            />
                                        </div>
                                    </div>
                                </td>
                            </div>
                        </tr>
                        <tr>
                            <th className='primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>Net</th>
                            <div className='mt-2'>
                                <td>
                                    <div className="d-flex w-100">
                                        <div className="mx-2">
                                            <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                type="number"
                                                placeholder="$100.00"
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                placeholder="$90.00"
                                                type="number"
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                placeholder="$85.00"
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                placeholder="$80.00"
                                                type="number"
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                placeholder="$70.00"
                                                type="number"
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                placeholder="$70.00"
                                                type="number"
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                placeholder="$70.00"
                                                type="number"
                                            />
                                        </div>
                                    </div>
                                </td>
                            </div>
                        </tr>
                    </table>
                </div>
            </div>
            <div className=''>
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-3">
                    Charles Supplier
                </p>
                <div className='overflow-scroll'>
                    <table className='w-100'>
                        <tr className='mt-4'>
                            <th className='primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>Qty</th>
                            <div className='my-3'>
                                <td>
                                    <div className="d-flex w-100">
                                        <div className="mx-2">
                                            <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                type="button"
                                                value='25'
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                type="button"
                                                value='50'
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                type="button"
                                                value='100'
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                type="button"
                                                value='150'
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                type="button"
                                                value='250'
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                type="button"
                                                value='500'
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                type="button"
                                                value='1000'
                                            />
                                        </div>
                                    </div>
                                </td>
                            </div>
                        </tr>
                        <tr>
                            <th className='primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>Net</th>
                            <div className='mt-2'>
                                <td>
                                    <div className="d-flex w-100">
                                        <div className="mx-2">
                                            <input className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                type="number"
                                                placeholder="$90.00"
                                                maxlength={3}
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                placeholder="$85.00"
                                                type="number"
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                placeholder="$85.00"
                                                type="number"
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                placeholder="$75.00"
                                                type="number"
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                placeholder="$75.00"
                                                type="number"
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                placeholder="$75.00"
                                                type="number"
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <input className="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                placeholder="$75.00"
                                                type="number"
                                            />
                                        </div>
                                    </div>
                                </td>
                            </div>
                        </tr>
                    </table>
                </div>
            </div>
            <div className=''>
                <button className="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mt-4" onClick={() => SetMarginShow(true)}>View Margin</button>
            </div>
            {marginShow &&
                <div>
                    <p className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary mt-4">Admin Pricing</p>
                    <div className='w-100 d-flex res-flex as-jb gap-5 py-4 px-4 bg-lt-blue2 rounded-4 mt-3'>
                        <div className='w-100'>

                            <div>
                                <div className='w-100 d-flex ac-js'>
                                    <div className='w-100'>
                                        <p className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">Show to Customer</p>
                                    </div>
                                    <button className='100 cust-btn' onClick={() => setCustomer(!customer)}>
                                        <div className="button r button-1">
                                            <input type="checkbox" className="checkbox" />
                                            <div className="yes"></div>
                                            <div className="no"></div>
                                        </div>
                                    </button>
                                </div>
                                {customer &&
                                    <div>
                                        <div className=''>
                                            <div className='overflow-scroll'>
                                                <table className='w-100'>
                                                    <tr className='mt-4'>
                                                        <th className='primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>Qty</th>
                                                        <div className='my-3'>
                                                            <td>
                                                                <div className="d-flex w-100">
                                                                    <div className="mx-2">
                                                                        <input className="num-cls-btn2 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                            type="button"
                                                                            value='25'
                                                                        />
                                                                    </div>
                                                                    <div className="mx-2">
                                                                        <input className="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                            type="button"
                                                                            value='50'
                                                                        />
                                                                    </div>
                                                                    <div className="mx-2">
                                                                        <input className="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                            type="button"
                                                                            value='100'
                                                                        />
                                                                    </div>
                                                                    <div className="mx-2">
                                                                        <input className="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                            type="button"
                                                                            value='150'
                                                                        />
                                                                    </div>
                                                                    <div className="mx-2">
                                                                        <input className="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                            type="button"
                                                                            value='250'
                                                                        />
                                                                    </div>
                                                                    <div className="mx-2">
                                                                        <input className="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                            type="button"
                                                                            value='500'
                                                                        />
                                                                    </div>
                                                                    <div className="mx-2">
                                                                        <input className="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                            type="button"
                                                                            value='1000'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </div>
                                                    </tr>
                                                    <tr>
                                                        <th className='primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>Net</th>
                                                        <div className='mt-2'>
                                                            <td>
                                                                <div className="d-flex w-100">
                                                                    <div className="mx-2">
                                                                        <input className="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                            type="number"
                                                                            placeholder="$90.00"
                                                                            maxlength={3}
                                                                        />
                                                                    </div>
                                                                    <div className="mx-2">
                                                                        <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                            placeholder="$85.00"
                                                                            type="number"
                                                                        />
                                                                    </div>
                                                                    <div className="mx-2">
                                                                        <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                            placeholder="$85.00"
                                                                            type="number"
                                                                        />
                                                                    </div>
                                                                    <div className="mx-2">
                                                                        <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                            placeholder="$75.00"
                                                                            type="number"
                                                                        />
                                                                    </div>
                                                                    <div className="mx-2">
                                                                        <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                            placeholder="$75.00"
                                                                            type="number"
                                                                        />
                                                                    </div>
                                                                    <div className="mx-2">
                                                                        <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                            placeholder="$75.00"
                                                                            type="number"
                                                                        />
                                                                    </div>
                                                                    <div className="mx-2">
                                                                        <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                            placeholder="$75.00"
                                                                            type="number"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </div>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='w-100 d-flex ac-js mt-4'>
                                            <p className='primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>MSP :</p>
                                            <div className="mx-2 place-holde">
                                                <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                    placeholder="5%"
                                                    type="number"
                                                />
                                            </div>
                                        </div>
                                        <div className='overflow-scroll'>
                                            <table className='w-100 '>
                                                <tr className='mt-4'>
                                                    <th className='primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>Net</th>
                                                    <div className='my-3'>
                                                        <td>
                                                            <div className="d-flex w-100">
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$75.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$75.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$75.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$75.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$75.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$75.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$75.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </div>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className='w-100 d-flex ac-js mt-4'>
                                            <p className='primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>SCP :</p>
                                            <div className="mx-2 place-holde">
                                                <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                    placeholder="5%"
                                                    type="number"
                                                />
                                            </div>
                                        </div>
                                        <div className='overflow-scroll'>
                                            <table className='w-100 '>
                                                <tr className='mt-4'>
                                                    <th className='primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>Net</th>
                                                    <div className='my-3'>
                                                        <td>
                                                            <div className="d-flex w-100">
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$75.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$75.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$75.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$75.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$75.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$75.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$75.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </div>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                        <div className='w-50'>
                            <div className='w-100 d-flex ac-jc'>
                                <div className='w-100'>
                                    <p className="primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">Show to Reseller</p>
                                </div>
                                <button className='100 cust-btn' onClick={() => setReseller(!reseller)}>
                                    <div className="button r button-1">
                                        <input type="checkbox" className="checkbox" />
                                        <div className="yes"></div>
                                        <div className="no"></div>
                                    </div>
                                </button>
                            </div>
                            {reseller &&
                                <div>
                                    <div className=''>
                                        <div className='overflow-scroll'>
                                            <table className='w-100'>
                                                <tr className='mt-4'>
                                                    <th className='primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>Qty</th>
                                                    <div className='my-3'>
                                                        <td>
                                                            <div className="d-flex w-100">
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        type="button"
                                                                        value='250'
                                                                    />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        type="button"
                                                                        value='500'
                                                                    />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        type="button"
                                                                        value='1000'
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </div>
                                                </tr>
                                                <tr>
                                                    <th className='primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>Net</th>
                                                    <div className='mt-2'>
                                                        <td>
                                                            <div className="d-flex w-100">


                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$85.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$75.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                                <div className="mx-2">
                                                                    <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                        placeholder="$75.00"
                                                                        type="number"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </div>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className=' d-flex ac-js mt-4'>
                                        <p className='primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>MSP</p>
                                        <div className="mx-2 place-holde">
                                            <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                placeholder="5%"
                                                type="number"
                                            />
                                        </div>
                                    </div>
                                    <div className='overflow-scroll'>
                                        <table className='w-100'>
                                            <tr className='mt-4'>
                                                <th className='primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>Net</th>
                                                <div className='my-3'>
                                                    <td>
                                                        <div className="d-flex w-100">
                                                            <div className="mx-2">
                                                                <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                    placeholder="$75.00"
                                                                    type="number"
                                                                />
                                                            </div>
                                                            <div className="mx-2">
                                                                <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                    placeholder="$75.00"
                                                                    type="number"
                                                                />
                                                            </div>
                                                            <div className="mx-2">
                                                                <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                    placeholder="$75.00"
                                                                    type="number"
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </div>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className=' d-flex ac-js mt-4'>
                                        <p className='primary f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>SCP</p>
                                        <div className="mx-2 place-holde">
                                            <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                placeholder="5%"
                                                type="number"
                                            />
                                        </div>
                                    </div>
                                    <div className='overflow-scroll'>
                                        <table className='w-100'>
                                            <tr className='mt-4'>
                                                <th className='primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15'>Net</th>
                                                <div className='my-3'>
                                                    <td>
                                                        <div className="d-flex w-100">
                                                            <div className="mx-2">
                                                                <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                    placeholder="$75.00"
                                                                    type="number"
                                                                />
                                                            </div>
                                                            <div className="mx-2">
                                                                <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                    placeholder="$75.00"
                                                                    type="number"
                                                                />
                                                            </div>
                                                            <div className="mx-2">
                                                                <input className="num-cls-btn2  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                                                                    placeholder="$75.00"
                                                                    type="number"
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </div>
                                            </tr>
                                        </table>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>}
            <div className="w-100 mt-5">
                <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                    Last updated on August 28, 2023 at 03:10 AM
                </p>
            </div>
        </div>
    )
}

export default LibraryVendorPricingComp