import { createSlice } from "@reduxjs/toolkit";

export const vendarnavi = createSlice({
    name: 'navigateSlice',
    initialState: 'navi',
    reducers: {
        saveText: (state, action) => {
            return action.payload
        },
        deleteText: (state) => {
            state = ''
        },
    }
})


export const { deleteText, saveText } = vendarnavi.actions

export default vendarnavi.reducer