import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./routes/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-datepicker/dist/react-datepicker.css";

import "../src/assets/sass/default/style.scss";
import "../src/assets/sass/default/responsive.scss";
import "../src/assets/sass/constatnt/custome.scss";
import "../src/assets/sass/constatnt/width.scss";
import AdminPanel from "./routes/AdminPanel";
import AddProfile from "./routes/AddProfile";
import ViewProfile from "./routes/ViewProfile";
import EditProfile from "./routes/EditProfile";
import Layout from "./components/Layout/Layout";
import RoleProfileList from "./routes/RoleProfileList";
import RoleAddProfile from "./routes/RoleAddProfile";
import RoleViewProfile from "./routes/RoleViewProfile";
import RoleEditProfile from "./routes/RoleEditProfile";
import SignInScreen from "./routes/SignInScreen";
import ForgotPassword from "./routes/ForgotPassword";
import ResetPasswordScreen from "./routes/ResetPasswordScreen";
import VendorDashboard from "./routes/VendorDashboard";
import AddVendor from "./routes/AddVendor";
import ProductFeed from "./routes/ProductFeed";
import ProductLibrary from "./routes/ProductLibrary";
import VendorManageScreen from "./routes/VendorManageScreen";
import ProductScreen from "./routes/ProductScreen";
import MapProduct from "./routes/MapProduct";
import VendorDetailsPage from "./routes/VendorDetailsPage";
import ProductDetailScreen from "./routes/ProductDetailScreen";
import ViewThread from "./components/VendorDetails/ViewThread";
import ViewCustomer from "./routes/ViewCustomer";
import CustomerList from "./routes/CustomerList";
import CategorySettingScreen from "./routes/CategorySettingScreen";
import ViewLibraryList from "./routes/ViewLibraryList";
import OrderList from "./routes/OrderList";
import CustomerRequestViewDetails from "./routes/CustomerRequestViewDetails";
import CustomerViewDetailsScreen from "./routes/CustomerViewDetailsScreen";
import OrderViewDetails from "./routes/OrderViewDetails";
import ProductLibraryDetailsScreen from "./routes/ProductLibraryDetailsScreen";
import OrderedViewDetails from "./routes/OrderedViewDetails";
import ECommerceList from "./routes/ECommerceList";
import EcommerceViewDetails from "./components/Ecommerce/EcommerceViewDetails";
import CreateNewRequestScreen from "./routes/CreateNewRequestScreen";
import CreateReqestDetailScreen from "./routes/CreateReqestDetailScreen";
import DecorativeVendorScreen from "./routes/DecorativeVendorScreen";
import AddDecorativeVendor from "./routes/AddDecorativeVendor";
import DecorativeDetaileScreen from "./routes/DecorativeDetaileScreen";
import ViewCollaborateDetails from "./routes/ViewCollaborateDetails";
import DecoraativeViewCollaborateDetails from "./routes/DecoraativeViewCollaborateDetails";
import MyProductDetailScreen from "./routes/MyProductDetailScreen";
import ProductVendPoRejectViewScreen from "./components/VendorDetails/ProductVendorPO/ProductVendPoRejectViewScreen";
import VendorDetailsPages from "./routes/VendorDetailsPages";
import AddDetails from "./routes/AddDetails";
import PrivateRoutes from "./redux/store/privateRoutes";

const ReactRoutes = () => {
  const token = localStorage.getItem("token");
  const [imageShow, setImageShow] = useState(false);
  const imageShoweClick = () => {
    setImageShow(!imageShow);
  };
  return (
    <Routes>
      <Route path="/register" element={<AddDetails />} />

      <Route path="/" element={<SignInScreen />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPasswordScreen />} />
      <Route element={<PrivateRoutes token={token} />}>
        <Route element={<Layout />}>
          <Route path="/home" index element={<Dashboard />} />
          <Route path="/profile" element={<AdminPanel />} />
          <Route path="/add-profile" element={<AddProfile />} />
          <Route path="/view-profile" element={<ViewProfile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/role-profile" element={<RoleProfileList />} />
          <Route path="/add-role-profile" element={<RoleAddProfile />} />
          <Route path="/view-role-profile" element={<RoleViewProfile />} />
          <Route path="/edit-role-profile" element={<RoleEditProfile />} />
          <Route path="/vendor-home" element={<VendorDashboard />} />
          <Route path="/add-vendor" element={<AddVendor />} />
          <Route path="/product-feed" element={<ProductFeed />} />
          <Route path="/product-library" element={<ProductLibrary />} />
          <Route path="/vendor-management" element={<VendorManageScreen />} />
          <Route path="/products" element={<ProductScreen />} />
          <Route path="/map-product" element={<MapProduct />} />
          <Route path="/decorative-product" element={<AddDecorativeVendor />} />
          <Route path="/vendor-details" element={<VendorDetailsPage />} />
          <Route path="/both-vendor" element={<VendorDetailsPages />} />
          {/* <Route
          path="/map-product-vendor-details"
          element={<VendorDetailsPage />}
        /> */}
          {/* <Route path="/request-details-list" element={<VendorDetailsPage />} /> */}
          <Route path="/add-new-product/products" element={<ProductScreen />} />
          <Route
            path="/decorative-vendor"
            element={<DecorativeVendorScreen />}
          />
          <Route
            path="/add-new-product/products/product-detail"
            element={<ProductDetailScreen />}
          />
          <Route
            path="/create-new-request"
            element={<CreateNewRequestScreen />}
          />
          <Route
            path="/product-library-details"
            element={<ProductLibraryDetailsScreen />}
          />
          <Route path="/reqest-detail" element={<CreateReqestDetailScreen />} />
          <Route path="/myproduct-detail" element={<MyProductDetailScreen />} />

          <Route path="/view-thread" element={<ViewThread />} />
          <Route path="/category-setting" element={<CategorySettingScreen />} />
          <Route path="/customer-list" element={<CustomerList />} />
          <Route path="/view-customer" element={<ViewCustomer />} />
          <Route path="/customer-list" element={<CustomerList />} />
          <Route path="/view-customer" element={<ViewCustomer />} />
          <Route
            path="/view-request-details-customer"
            element={<CustomerRequestViewDetails />}
          />
          <Route path="/category-setting" element={<CategorySettingScreen />} />
          <Route path="/customer-request-list" element={<ViewCustomer />} />
          <Route path="/view-library-list" element={<ViewLibraryList />} />
          <Route path="/order-list" element={<OrderList />} />
          <Route
            path="/view-customer-details"
            element={<CustomerViewDetailsScreen />}
          />
          <Route
            path="/decorative-detail"
            element={<DecorativeDetaileScreen />}
          />
          <Route path="/view-order-details" element={<OrderViewDetails />} />
          <Route
            path="/ordered-view-details"
            element={<OrderedViewDetails />}
          />
          <Route path="/ecommerce-list" element={<ECommerceList />} />
          <Route
            path="/ecommerce-view-details"
            element={<EcommerceViewDetails />}
          />
          <Route
            path="/view-collaborate-details"
            element={<ViewCollaborateDetails />}
          />
          <Route
            path="/decorative-view-collaborate-details"
            element={<DecoraativeViewCollaborateDetails />}
          />
          <Route
            path="/product-vendor-poreject-view"
            element={<ProductVendPoRejectViewScreen />}
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default ReactRoutes;
