import { Search } from "@mui/icons-material";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import ProfileList from "../components/AddRole/ProfileList";
import ProfileRolesList from "../components/AddRole/ProfileRolesList";
import VendorList from "../components/AddRole/VendorList";
import ProductAdminList from "../components/AddRole/ProductAdminList";
import ClientList from "../components/AddRole/ClientList";
import { useNavigate } from "react-router-dom";

const RoleAddProfile = () => {
  const navigate = useNavigate();
  return (
    <div className="dashRightView overflow-scroll">
      <div className="d-flex w-95 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, number or PO# to search" />
        <img src={manImage} className="searchManImg" />
      </div>
      <p className="f4 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5">
        Add Profile Roles
      </p>
      <div className="w-95 ac-jb d-flex flex-md-row flex-column mt-4 position-relative">
        <div className="w-100">
          <img
            src={manImage}
            className="searchManImgMan rounded-4 position-absolute"
          />
          <input
            placeholder="Create Role"
            className="dashTotalDes ps-5 w-lg-40 w-xl-40 py-2 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 rounded-3"
          />
        </div>
      </div>
      <div className="d-flex flex-md-row flex-column">
        <ProfileList type="add" />
        <ProfileRolesList type="add" />
        <VendorList type="add" />
      </div>
      <div className="d-flex flex-md-row flex-column w-65">
        <ProductAdminList type="add" />
        <ClientList type="add" />
      </div>
      <div className="w-80 mt-5 d-flex flex-md-row flex-column ac-jb">
        <p className="f2 primary1 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17">
          Once you have updated the Admin roles, kindly press the
          <span className="f2 text-dark ms-md-2 ms-lg-2 ms-xl-2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17">
            "Create role"
          </span>{" "}
          button.
        </p>
      </div>

      <div className="w-80 mt-1 d-flex flex-md-row flex-column ac-jb">
        <button
          onClick={() => navigate("/role-profile")}
          className="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
        >
          Back
        </button>
        <button className="cust-btn mt-2 addbtn ms-1 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
          Create Roles
        </button>
      </div>
    </div>
  );
};

export default RoleAddProfile;
