import ReactDatePicker from "react-datepicker";
import { product, profilePic, viewIcon } from "../../assets/img";
import { collaborateList, posBills } from "../../redux/api/DummyJson";
import { useState } from "react";
import { useNavigate } from "react-router";
import ViewAllImgPopup from "../Popup/ViewAllImgPopup";

const CollaborateList = () => {
  const navigate = useNavigate();
  const [viewImg, setViewImg] = useState(false);
  const imgVewClick = () => {
    setViewImg(!viewImg);
  };
  return (
    <div className="w-100 mt-5">
      <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
        Collaborate
      </p>
      {/* <div className="overflow-scroll mt-4 w-100">
        <div className="wholeDesignDash d-flex mt-4 border-bottom pb-3">
          <p className="w-5 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black d-flex as-jc">
            Sl.No
          </p>
          <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black d-flex as-jc">
            Vendor ID
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black d-flex as-jc">
            Vendor Name
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black d-flex as-jc">
            Vendor Rep.
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black d-flex as-jc">
            Description
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black d-flex as-jc">
            Product File
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black d-flex as-jc">
           Sent By
          </p>
          <p className=" w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black d-flex as-jc">
            Created By
          </p>
          <p className=" w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black d-flex as-jc">
            Options
          </p>
        </div>
        {collaborateList?.map((item, index) => {
          return (
            <div className="wholeDesignDash d-flex mt-4 border-bottom pb-3">
              <div className="w-5">
                <p className=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                  {index + 1}
                </p>
              </div>
              <div className="w-10">
                <p className=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                  {item?.vendorId}
                </p>
              </div>
              <div className="w-15">
                <p className=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                  {item?.VendorName}
                </p>
              </div>
              <div className="w-15">
                <p className=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                  {item?.name}
                </p>
              </div>
              <div className="w-15">
                <p className=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                  {item?.description}
                </p>
              </div>
              <div className="w-15 d-flex ac-jc">
                <img src={item?.file} className="imgDashboard" />
              </div>

              <div className="w-15">
                <p className=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                  {item?.sendBy}
                </p>
              </div>
              <div className="w-15">
                <p className=" f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                  {item?.time}
                </p>
              </div>
              <div className="w-10 d-flex ac-jc primary1">
                <div className="viewBoxDes pointerView p-2 bg-white rounded-2">
                  <img src={viewIcon} className="viewDes" />
                </div>
              </div>
            </div>
          );
        })}
      </div> */}
      <div className="d-flex justify-content-end w-100">
        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
          Total Count : 1
        </p>
      </div>
      <div className="w-100 overflow-scroll mt-5">
        <table className="w-100">
          <tr className="mt-4 border_cust ">
            <div className="">
              <td>
                <div className="d-flex w-100">
                  <div className="mx-2">
                    <p
                      class=" pb-3 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                      style={{ width: "50px" }}
                    >
                      Sl.No
                    </p>
                  </div>
                  {/* <div className="mx-2" >
                    <p class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                      style={{ width: '100px' }}
                    > Vendor ID</p>
                  </div> */}
                  <div className="mx-2">
                    <p
                      class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                      style={{ width: "150px" }}
                    >
                      {" "}
                      Collaboration ID
                    </p>
                  </div>
                  <div className="mx-2">
                    <p
                      class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                      style={{ width: "150px" }}
                    >
                      Status
                    </p>
                  </div>
                  {/* <div className="mx-2" >
                    <p class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                      style={{ width: '150px' }}
                    >Vendor Name</p>
                  </div> */}
                  <div className="mx-2">
                    <p
                      class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                      style={{ width: "150px" }}
                    >
                      {" "}
                      Vendor Rep
                    </p>
                  </div>
                  <div className="mx-2">
                    <p
                      class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                      style={{ width: "150px" }}
                    >
                      {" "}
                      Product
                    </p>
                  </div>
                  <div className="mx-2">
                    <p
                      class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                      style={{ width: "150px" }}
                    >
                      {" "}
                      Description
                    </p>
                  </div>
                  <div className="mx-2">
                    <p
                      class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                      style={{ width: "100px" }}
                    >
                      {" "}
                      Sent By
                    </p>
                  </div>
                  <div className="mx-2">
                    <p
                      class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                      style={{ width: "200px" }}
                    >
                      {" "}
                      Date & Time
                    </p>
                  </div>
                  <div className="mx-2">
                    <p
                      class=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                      style={{ width: "150px" }}
                    >
                      {" "}
                      Options
                    </p>
                  </div>
                </div>
              </td>
            </div>
          </tr>
          {collaborateList?.map((item, index) => {
            return (
              <tr className="mt-4">
                <div className="">
                  <td>
                    <div className="d-flex w-100">
                      <div
                        className="mx-2 d-flex ac-jc"
                        style={{ width: "50px" }}
                      >
                        <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 pointerView primary1">
                          {index + 1}
                        </p>
                      </div>
                      {/* <div className="mx-2 d-flex ac-jc" style={{ width: '100px' }}>
                        <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 pointerView primary1">
                          {item?.vendorId}
                        </p>
                      </div> */}
                      <div
                        className="mx-2 d-flex ac-jc"
                        style={{ width: "150px" }}
                      >
                        <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1">
                          {item?.collaborat_id}
                        </p>
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc"
                        style={{ width: "150px" }}
                      >
                        <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1">
                          {item?.status}
                        </p>
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc"
                        style={{ width: "150px" }}
                      >
                        <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 pointerView primary1">
                          {item?.name}
                        </p>
                      </div>
                      {/* <button
                        className="mx-2 d-flex ac-jc cust-btn"
                        style={{ width: "150px" }}
                      >
                        <img
                          src={product}
                          className="object-fit-contain img_size"
                        />
                      </button> */}
                      <div
                        className="mx-2 d-flex ac-jc"
                        style={{ width: "150px" }}
                      >
                        <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 pointerView primary1">
                          Shirt
                        </p>
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc"
                        style={{ width: "150px" }}
                      >
                        <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 pointerView primary1">
                          {item?.description}
                        </p>
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc"
                        style={{ width: "100px" }}
                      >
                        <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 pointerView primary1">
                          {item?.sendBy}
                        </p>
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc"
                        style={{ width: "200px" }}
                      >
                        <p className="f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 pointerView primary1">
                          {item?.time}
                        </p>
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc"
                        style={{ width: "150px" }}
                      >
                        <div
                          onClick={() =>
                            navigate("/view-collaborate-details", {
                              state: { data: item },
                            })
                          }
                          className="viewBoxDes pointerView p-2 bg-white rounded-2"
                        >
                          <img src={viewIcon} className="viewDes" />
                        </div>
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
            );
          })}
        </table>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </div>
  );
};

export default CollaborateList;
