import { animatedImg, emptyImg } from "../assets/img";
import { Search } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { dummyDashboardData } from "../redux/api/DummyJson";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ProductVendor from "../components/Vendor/ProductVendor";
import { useNavigate } from "react-router-dom";
import ServiceVendor from "../components/Vendor/ServiceVendor";

const VendorDashboard = () => {
  const [prodVendor, setProdVendor] = useState(false);
  const [serVendor, setSerVendor] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setProdVendor(true);
  }, []);

  return (
    <div className="dashRightView p-5">
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, number or PO# to search" />
        <img src={animatedImg} className="searchManImg" />
      </div>
      <div className="d-flex flex-md-row flex-column mt-5 w-100">
        <div className="w-lg-50 w-md-100 w-sm-100 w-xs-100 dailyStatus mt-4 rounded-3">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Vendor Dashboard
          </p>
          <div className="totalSiteDesWid flex-xs-column flex-wrap d-flex mt-4 w-100">
            <div className="dashTotalDesTotalSites">
              <div className="d-flex">
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Total Sites
                </p>
                <div className="bg-green1 p-1 mt-2 rounded-3 dotColor" />
              </div>
              <p className="ms-lg-2 ms-sm-2 ms-2 ms-xs-2 f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                08
              </p>
            </div>
            <div className="dashTotalDesTotalSites ms-sm-2 ms-md-3 ms-lg-4">
              <div className="d-flex">
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Total Sites
                </p>
                <div className="bg-red rounded-3 mt-2 p-1 dotColor" />
              </div>
              <p className="ms-lg-2 ms-sm-2 ms-2 ms-xs-2 f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                08
              </p>
            </div>
            <div className="dashTotalDesTotalSites ms-sm-2 ms-md-3 ms-lg-4">
              <div className="d-flex">
                <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15 me-1">
                  Total Sites
                </p>
                <div className="bg-yellow rounded-3 mt-2 p-1 dotColor" />
              </div>
              <p className="ms-lg-2 ms-sm-2 ms-2 ms-xs-2 f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                08
              </p>
            </div>
          </div>
        </div>
        <div className="w-lg-50 mt-4 w-md-100 w-sm-100 w-xs-100 dailyStatus ms-lg-4 rounded-3">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Vendor Dashboard
          </p>
          <div className="totalSiteDesWid flex-xs-column flex-wrap d-flex mt-4">
            <div className="dashTotalDesTotalSites1">
              <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15">
                Total Sites
              </p>
              <p className="ms-lg-2 ms-sm-2 ms-2 ms-xs-2 f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                08
              </p>
            </div>
            <div className="dashTotalDesTotalSites1 ms-sm-2 ms-md-3 ms-lg-4">
              <p className="f2 dark-gray fs-xs-10 fs-sm-10 fs-md-10 fs-lg-13 fs-xl-15">
                Total Sites
              </p>
              <p className="ms-lg-2 ms-sm-2 ms-2 ms-xs-2 f7 font-blue fs-xs-18 fs-sm-20 fs-md-28 fs-lg-38 fs-xl-41">
                08
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row flex-column ac-jb mt-4">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Vendor Management
        </p>
        <div className="d-flex ac-jc">
          <div className="d-flex w-xs-100 mt-xs-5 position-relative">
            <input
              className="cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
            <Search className="gray f1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-19 fs-xl-19 fs-xxl-20 mt-1 searchVendor" />
          </div>
          <div
            onClick={() => navigate("/vendor-management")}
            className="d-flex ms-3 pointerView"
          >
            <p className="f3 fs-xs-10 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 primary">
              View Details
            </p>
            <ArrowCircleRightOutlinedIcon className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary ms-1" />
          </div>
        </div>
      </div>
      <div className="w-100 d-flex mt-2 ac-jb flex-wrap">
        <div className="d-flex mt-3">
          <div
            onClick={() => {
              setProdVendor(true);
              setSerVendor(false);
            }}
            className={`${prodVendor
              ? "pointerView border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
              : ""
              } `}
          >
            <p
              className={`${prodVendor ? "black" : "gray"
                } pointerView f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Product Vendor
            </p>
          </div>
          <div
            onClick={() => {
              setSerVendor(true);
              setProdVendor(false);
            }}
            className={`${serVendor
              ? "pointerView border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
              : ""
              } ms-4`}
          >
            <p
              className={`${serVendor ? "black" : "gray"
                } pointerView f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Service Vendor
            </p>
          </div>
        </div>
        {/* <div className="w-xs-100 d-flex ac-jc">
          <button
            onClick={() => navigate("/add-vendor")}
            className="cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add Vendor
          </button>
        </div> */}
      </div>
      {prodVendor && <ProductVendor />}
      {serVendor && <ServiceVendor />}
    </div>
  );
};
export default VendorDashboard;
