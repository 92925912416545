import { Search } from "@mui/icons-material";
import React from "react";
import CachedIcon from "@mui/icons-material/Cached";

const DropdownTap = ({ path, vendor }) => {
  console.log(vendor, path);
  return (
    <div className="mt-5 banner-left">
      <div className="d-flex ac-jb flex-column flex-md-row">
        <div className="d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
          <input
            type="text"
            className="get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4 px-3 w-auto f3 fs-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-auto"
            placeholder={
              path === "/decorative-vendor" || path === "/decorative-vendor"
                ? "Search Decoration Name"
                : "Search Product Name"
            }
          />
        </div>
        <div className="d-flex ac-jc gap-3">
          <button
            // onClick={() => navigate("/add-vendor")}
            className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
          >
            <Search />
          </button>
          <button
            // onClick={() => navigate("/add-vendor")}
            className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
          >
            <CachedIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DropdownTap;
