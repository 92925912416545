import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { productTableData } from "../redux/api/DummyJson";

const CustomerViewDetailsScreen = () => {
  const location = useLocation();
  const [data, setData] = useState("");
  const [action, setAction] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setData(location?.state?.data);
  }, [location]);

  console.log(data);

  return (
    <div className="dashRightView p-5">
      <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5">
        Customer Product Details
      </p>
      <div className="w-100 searchField mt-5 bg-white ">
        <div className="w-100 d-flex flex-xxl-row flex-xl-row flex-lg-row flex-column">
          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Customer Name:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.name}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Customer ID:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.CustomerId}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Customer Email:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.email}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Customer Phone Number:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.phoneNo}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Organization:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.organization}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Products Request Count:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.reqCount}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Customer Type:
              </p>
              <div className="w-70 ms-3">
                <select
                  placeholder=""
                  className="w-50 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                  name=""
                  id=""
                >
                  <option>E-Commerce</option>
                  <option>Enterprises</option>
                </select>
              </div>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Contact details:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.ContactDetails}
              </p>
            </div>
          </div>
          <div className="w-xxl-15 w-xl-15 w-lg-15 position-relative">
            <button
              onClick={() => setAction(!action)}
              className="cust-btn addbtn mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Actions
            </button>
            {action && (
              <div
                onClick={() => navigate("/order-list")}
                className="w-xxl-100 w-xl-100 w-lg-100 w-20 actionDetailPopup searchField pointerView py-2 ac-jc rounded-3"
              >
                <h6 className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14">
                  Order List
                </h6>
              </div>
            )}
          </div>
        </div>
        <div className="w-90 ac-jb flex-md-row flex-column d-flex mt-5">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Back
          </button>
          {location?.state?.type === "edit" && (
            <button className=" cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              Update Details
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerViewDetailsScreen;
