import ProductListComp from "../ProductComp/MyProductListComp";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DropdownTap from "../ProductComp/DropdownTap";
import { Search } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import { type } from "@testing-library/user-event/dist/type";
import DecorativeList from "./DecorativeList";
import UploadIcon from "@mui/icons-material/Upload";

const DecorativeDetails = ({ place, path, vendor, name }) => {
  const navigate = useNavigate();
  const [product, setProduct] = useState(true);
  const [decorative, setDecorative] = useState(false);
  const [productList, setParoductList] = useState("");
  const location = useLocation();
  console.log(path, "path");

  useEffect(() => {
    if (path === "/vendor-details") {
      setDecorative(true);
      setProduct(false);
    }
  }, []);

  return (
    <div className="w-100">
      <div className="d-flex ac-jb flex-column flex-md-row mt-5">
        <div className="d-flex ac-jc flex-wrap">
          <button
            onClick={() =>
              navigate("/decorative-product", {
                state: { form: "decorative", form: vendor },
              })
            }
            className="cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add Decoration
          </button>
        </div>
        <div className="d-flex ac-jc gap-3">
          <button className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
            <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            Export
          </button>
        </div>
      </div>
      {/* <DropdownTap path={path} vendor={vendor} /> */}
      <DecorativeList path={path} name={name} />
    </div>
  );
};

export default DecorativeDetails;
