import React, { useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import { uploadIcon } from "../assets/img";
import FormComp from "../components/DecorativeVendor/FormComp";
import ImageUplodeComp from "../components/DecorativeVendor/ImageUplodeComp";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const AddDecorativeVendor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const vendor = location?.state?.type;
  const vendor2 = location?.state?.path;
  const vendor3 = location?.state?.form;
  // const ven = location?.state?.product
  console.log(vendor);
  return (
    <div className="dashRightView overflow-scroll w-100">
      <ProductSearchHeader />
      <p
        className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> Add New Decoration
      </p>
      <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
        <FormComp vendor={vendor} />
        <ImageUplodeComp vendor={vendor} vendor2={vendor2} />
      </div>
    </div>
  );
};

export default AddDecorativeVendor;
