import DoneIcon from "@mui/icons-material/Done";
import { useEffect, useState } from "react";

const VendorList = ({ type }) => {
  const [listChecked, setListChecked] = useState(false);
  const [addChecked, setAddChecked] = useState(false);
  const [viewChecked, setViewChecked] = useState(false);
  const [editChecked, setEditChecked] = useState(false);
  const [deleteChecked, setDeleteChecked] = useState(false);
  const [statusChecked, setStatusChecked] = useState(false);

  useEffect(() => {
    if (type === "view" || type === "edit") {
      setViewChecked(true);
      setStatusChecked(true);
    }
  }, []);

  return (
    <div className="w-95 mt-4">
      <p
        className={`f3 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-18 fs-xxl-19 mt-1`}
      >
        Vendor
      </p>
      <div className="w-100 mt-4 ms-1">
        <div className="d-flex">
          <DoneIcon
            onClick={() => {
              if (type === "add" || type === "edit") {
                setListChecked(!listChecked);
              }
            }}
            className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
              listChecked ? "checkIcon" : "checkIcon1"
            }`}
          />
          <p
            className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
              listChecked ? "black" : "primary1"
            }`}
          >
            LIST-USER
          </p>
        </div>
        <div className="d-flex mt-3">
          <DoneIcon
            onClick={() => {
              if (type === "add" || type === "edit") {
                setAddChecked(!addChecked);
              }
            }}
            className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
              addChecked ? "checkIcon" : "checkIcon1"
            }`}
          />
          <p
            className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
              addChecked ? "black" : "primary1"
            }`}
          >
            ADD-USER
          </p>
        </div>
        <div className="d-flex mt-3">
          <DoneIcon
            onClick={() => {
              if (type === "add" || type === "edit") {
                setViewChecked(!viewChecked);
              }
            }}
            className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
              viewChecked ? "checkIcon" : "checkIcon1"
            }`}
          />
          <p
            className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
              viewChecked ? "black" : "primary1"
            }`}
          >
            VIEW-USER
          </p>
        </div>
        <div className="d-flex mt-3">
          <DoneIcon
            onClick={() => {
              if (type === "add" || type === "edit") {
                setEditChecked(!editChecked);
              }
            }}
            className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
              editChecked ? "checkIcon" : "checkIcon1"
            }`}
          />
          <p
            className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
              editChecked ? "black" : "primary1"
            }`}
          >
            {" "}
            EDIT-USER
          </p>
        </div>
        <div className="d-flex mt-3">
          <DoneIcon
            onClick={() => {
              if (type === "add" || type === "edit") {
                setDeleteChecked(!deleteChecked);
              }
            }}
            className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
              deleteChecked ? "checkIcon" : "checkIcon1"
            }`}
          />
          <p
            className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
              deleteChecked ? "black" : "primary1"
            }`}
          >
            {" "}
            DELETE-USER
          </p>
        </div>
        <div className="d-flex mt-3">
          <DoneIcon
            onClick={() => {
              if (type === "add" || type === "edit") {
                setStatusChecked(!statusChecked);
              }
            }}
            className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
              statusChecked ? "checkIcon" : "checkIcon1"
            }`}
          />
          <p
            className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
              statusChecked ? "black" : "primary1"
            }`}
          >
            {" "}
            STATUS-USER
          </p>
        </div>
      </div>
    </div>
  );
};

export default VendorList;
