import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { productVendorData } from "../../redux/api/DummyJson";
import {
  editIcon,
  emptyImg,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../../assets/img";
import { useNavigate } from "react-router-dom";

const ProductVendor = () => {
  const navigate = useNavigate();

  return (
    <div className="overflow-scroll mt-4">
      {productVendorData?.length > 0 && (
        <div className="wholeDesignDash1 d-flex mt-4 border-bottom pb-3">
          <p className="w-5 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            S.no
          </p>
          <p className="f2 w-15 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Vendor ID
          </p>
          <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Legal Entity Name
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Primary Name
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Vendor Offers
          </p>
          <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Phone Number
          </p>
          <p className="w-5 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Status
          </p>
          <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Options
          </p>
        </div>
      )}
      {productVendorData?.length > 0 &&
        productVendorData?.map((item) => {
          return (
            <div className="wholeDesignDash1 mb-3 d-flex mt-5">
              <p className="w-5 primary1 ps-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc">
                {item?.slNo}
              </p>
              <p className="pointerView w-15 primary1 ps-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc">
                {item?.vendorId}
              </p>
              <p className="w-20 primary1 ps-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc">
                {item?.entityName}
              </p>
              <p className="w-15 primary1 ps-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc">
                {item?.primaryName}
              </p>
              <p className="w-15 primary1 ps-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc">
                {item?.offer}
              </p>
              <p className="w-15 primary1 ps-1 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc">
                {item?.mobileNo}
              </p>
              <div className="pointerView w-5 d-flex ac-jc">
                {item?.status === true && (
                  <div>
                    <img src={toggleOn} className="toggleOnDes" />
                  </div>
                )}
                {item?.status === false && (
                  <div>
                    <img src={toggleOff} className="toggleOnDes" />
                  </div>
                )}
              </div>
              {item?.primaryName == "Charles" && (
                <div
                  onClick={() => {
                    navigate("/vendor-details", {
                      state: { data: item, type: "Charles" },
                    });
                  }}
                  className="w-10 d-flex ac-jc"
                >
                  <div className="pointerView viewBoxDes p-2 mb-2 bg-white rounded-2">
                    <img src={viewIcon} className="viewDes" />
                  </div>
                  {/* <div className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2">
                  <img src={editIcon} className="viewDes" />
                </div> */}
                </div>
              )}
              {item?.primaryName == "John Rolph" && (
                <div
                  onClick={() => {
                    navigate("/vendor-details", {
                      state: { data: item, type: "John Rolph" },
                    });
                  }}
                  className="w-10 d-flex ac-jc"
                >
                  <div className="pointerView viewBoxDes p-2 mb-2 bg-white rounded-2">
                    <img src={viewIcon} className="viewDes" />
                  </div>
                  {/* <div className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2">
                  <img src={editIcon} className="viewDes" />
                </div> */}
                </div>
              )}
              {item?.primaryName == "Jake Adams" && (
                <div
                  onClick={() => {
                    navigate("/vendor-details", {
                      state: { data: item, type: "Jake Adams" },
                    });
                  }}
                  className="w-10 d-flex ac-jc"
                >
                  <div className="pointerView viewBoxDes p-2 mb-2 bg-white rounded-2">
                    <img src={viewIcon} className="viewDes" />
                  </div>
                  {/* <div className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2">
                  <img src={editIcon} className="viewDes" />
                </div> */}
                </div>
              )}
            </div>
          );
        })}
      {productVendorData?.length < 0 && (
        <div className="w-100 d-grid mt-5">
          <div className="d-flex ac-jc">
            <img src={emptyImg} className="noDataImg mt-5" />
          </div>
          <div className="d-flex ac-jc">
            <p className="f3 fs-xs-10 mt-4 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 gray">
              There are no vendors to be shown.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductVendor;
