import ProfileNavBar from "../components/ProfileNavBar";
import { Search } from "@mui/icons-material";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import ImageCroper from "../components/Popup/ImageCroper";
import { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ErrorIcon from "@mui/icons-material/Error";
import {
  useAddProfileMutation,
  useEditProfileMutation,
  useLazyProfile_viewQuery,
  useRoleListMutation,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;

  //RTK
  const [createAdmin] = useAddProfileMutation();
  const [updateAdmin] = useEditProfileMutation();
  const [viewApi] = useLazyProfile_viewQuery();
  const [roleapi] = useRoleListMutation();

  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [Email, setEmail] = useState("");
  const [Role, setRole] = useState("");
  const [rolelist, setRoleList] = useState([]);
  const [Password, setPassword] = useState("");
  const [CPassword, setCPassword] = useState("");

  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [croperCol, setCroperCol] = useState(false);
  const [logo, setLogo] = useState();
  const [banner, setBanner] = useState();

  const [first_nameErr, setFirst_nameErr] = useState(false);
  const [last_nameErr, setLast_nameErr] = useState(false);
  const [EmailErr, setEmailErr] = useState(false);
  const [RoleErr, setRoleErr] = useState(false);
  const [PasswordErr, setPasswordErr] = useState(false);
  const [CPasswordErr, setCPasswordErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getview = () => {
    viewApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        console.log(res);
        const data = res?.vendor_user;
        setFirst_name(data?.first_name);
        setLast_name(data?.last_name);
        setEmail(data?.email);
        setRole(data?.role?.id);
        setBanner(data?.image_url);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (location?.state?.data?.id) {
      getview();
    }
  }, []);
  const getrole = () => {
    const formdata = new URLSearchParams();
    formdata.append("type", "2");
    roleapi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          console.log(res, "res");
          setRoleList(res?.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    getrole();
  }, []);

  const submitHandler = () => {
    if (
      first_name?.length == 0 ||
      last_name?.length == 0 ||
      Email?.length == 0 ||
      Role?.length == 0 ||
      (type == "add" && Password?.length == 0) ||
      (type == "add" && Password !== CPassword)
    ) {
      setEmailErr(true);
      setFirst_nameErr(true);
      setLast_nameErr(true);
      setRoleErr(true);
      type == "add" && setPasswordErr(true);
      type == "add" && setCPasswordErr(true);
    } else if (errorMessage?.length == 0) {
      const id = JSON.parse(localStorage.getItem("user"));
      let formdata = new FormData();
      formdata.append("vendor_id", id?.vendor?.id);
      formdata.append("first_name", first_name);
      formdata.append("last_name", last_name);
      formdata.append("email", Email);
      formdata.append("role_id", Role);
      if (banner?.name) {
        formdata.append("image", banner);
      }
      if (Password && CPassword) {
        formdata.append("password", Password);
        formdata.append("password_confirmation", CPassword);
      }
      setBtn(true);
      dispatch(saveLoader(true));
      if (type == "edit") {
        formdata.append("vendor_user_id", location?.state?.data?.id);
        // formdata.append("_method", "PUT");
        updateAdmin(formdata)
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            if (res?.status == "success") {
              toast.success(res?.data?.message || res?.message);
              navigate("/profile");
              setBtn(false);
            }
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            console.log("err", err);
            setBtn(false);
          });
      } else {
        createAdmin(formdata)
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            if (res?.status == "success") {
              toast.success(res?.data?.message || res?.message);
              navigate("/profile");
              setBtn(false);
            }
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            console.log("err", err);
            setBtn(false);
          });
      }
    }
  };

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setPassword(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      var special = /[*@!#%&()^~{}]+/g;
      if (!new_pass.match(lowerCase)) {
        setErrorMessage("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setErrorMessage("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setErrorMessage("Password should contains numbers also!");
      } else if (new_pass.length < 8) {
        setErrorMessage("Password length should be more than 8.");
      } else if (!new_pass.match(special)) {
        setErrorMessage("Password should contains special character also!");
      } else {
        // setErrorMessage("Password is strong!");
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
      setPassword(new_pass);
    }
  };

  const dualToggele = (type) => {
    if (type == "logo") {
      setCroperCol(false);
      toggleImagePopup();
    } else {
      setCroperCol(true);
      toggleImagePopup();
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  useEffect(() => {
    if (croperImage) {
      fetch(croperImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          setBanner(file);
        });
      setCropImage("");
      // } else if (!croperCol && croperImage) {
      //   setLogo(croperImage);
      //   setCropImage("");
    }
  }, [croperImage]);

  return (
    <div className="dashRightView overflow-scroll">
      <div className="d-flex w-90 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, number or PO# to search"
        />
        <img src={manImage} className="searchManImg" />
      </div>
      <p
        className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon />{" "}
        {location?.state?.type == "edit" ? "Edit" : "Add"} Profile
      </p>
      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            First Name
          </p>
          <input
            value={first_name}
            placeholder="First Name"
            onChange={(e) => setFirst_name(e.target.value)}
            className=" editBtn mt-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            // disabled={type == "view" ? true : false}
          />
          {first_name?.length == 0 && first_nameErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter First Name</p>
            </div>
          )}
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Last Name
          </p>
          <input
            placeholder="Last Name"
            className=" editBtn mt-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => setLast_name(e.target.value)}
            value={last_name}
          />
          {last_name?.length == 0 && last_nameErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Last Name</p>
            </div>
          )}
        </div>
      </div>
      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Email
          </p>
          <input
            placeholder="Email"
            disabled={location?.state?.data?.id ? true : false}
            className=" editBtn mt-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => setEmail(e.target.value)}
            value={Email}
          />
          {validEmail.test(Email) == false && EmailErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Email</p>
            </div>
          )}
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Department/ Roles
          </p>
          <select
            placeholder=""
            className="w-90 mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3"
            name=""
            id=""
            onChange={(e) => setRole(e.target.value)}
            value={Role}
          >
            <option value="" hidden selected>
              Select Role
            </option>
            {rolelist?.map((item, ind) => (
              <option value={item.id}>{item?.role}</option>
            ))}
          </select>
          {Role?.length == 0 && RoleErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Select Role</p>
            </div>
          )}
        </div>
      </div>
      <div className="w-90 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Password
          </p>
          <input
            placeholder="Password"
            className=" editBtn mt-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => handlePassword(e)}
            value={Password}
          />
          {Password?.length == 0 && PasswordErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Password</p>
            </div>
          )}
          {errorMessage && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">{errorMessage}</p>
            </div>
          )}
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Confirm Password
          </p>
          <input
            placeholder="Confirm Password"
            className=" editBtn mt-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            onChange={(e) => setCPassword(e.target.value)}
            value={CPassword}
          />
          {CPassword?.length == 0 && CPasswordErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Confirm Password</p>
            </div>
          )}
          {CPassword?.length > 0 && Password !== CPassword && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">
                Password And Confirm Password are Not Same
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="w-90 my-3 mt-lg-5 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div
          className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100"
          onClick={() => dualToggele("ban")}
        >
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Profile Image
          </p>
          <input
            style={{ cursor: "pointer" }}
            value={
              banner
                ? "Profile Image Uploaded Successfully"
                : "Browse or Drag a file"
            }
            className=" editBtn mt-2 rounded-3 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
      </div>
      <div className="w-90 my-3 mt-lg-5 d-flex  flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <img
          src={banner?.name ? URL.createObjectURL(banner) : banner}
          style={{ width: "200px" }}
        />
      </div>
      <div className="w-90 d-flex flex-md-row flex-column ac-jb">
        <button
          onClick={() => navigate("/profile")}
          className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
        >
          Back
        </button>

        <button
          className={`cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded${
            btn ? "opacity:0" : "opacity:100"
          }`}
          onClick={submitHandler}
          disabled={btn}
        >
          {type == "edit" ? "Update" : "Create"}
          Admin
        </button>
        {/* )} */}
        {croper && (
          <ImageCroper
            toggleImagePopup={toggleImagePopup}
            setCropImage={setCropImage}
          />
        )}
      </div>
    </div>
  );
};

export default EditProfile;
