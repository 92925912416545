import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { vendor_category } from "../../redux/api/DummyJson";


const AddVendorDetails2 = ({ setVendorCat, vendorCat }) => {
const [vendorCatShow, setVendorCatShow] = useState(false);
  const [vendorCatogory, setVendorCatogory] = useState("");
  const onClickVendorCat = (e) => {
    if (vendorCatogory.includes(e)) {
      const listtemp = vendorCatogory.indexOf(e);
      const list = [...vendorCatogory];
      list.splice(listtemp, 1);
      setVendorCatogory(list);
    } else {
      setVendorCatogory([...vendorCatogory, e])
    }
  }
  return (
    <>
      <div className="w-90 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Primary Name*
          </p>
          <input
            placeholder=""
            value="Roger"
            className="editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Primary Email*
          </p>
          <input
            placeholder=""
            value="john@gmail.com"
            className=" editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
      </div>
      <div className="w-90 mt-lg-4 d-flex ac-jb flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Primary Phone Number*
          </p>
          <input
            placeholder=""
            value="9876543210"
            className=" editBtn rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Vendor Category*
            </p>
            <div className="position-relative w-90"
            >
              <input
                placeholder="Sellect Category"
                type='text'
                value={vendorCatogory}
                onClick={() =>
                  setVendorCatShow(!vendorCatShow)
                }
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
              <button
                className="drop_down cust-btn"
                onClick={() =>
                  setVendorCatShow(!vendorCatShow)
                }
              >
                <KeyboardArrowDownIcon />
              </button>
              {vendorCatShow && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setVendorCatShow(false)}
                />
              )}
              <div
                className={`${vendorCatShow && "submenu_1 h-auto"
                  } submenu_cont_1 overflow-scroll z-3`}
              >
                {vendor_category ?.map((item, ind) => {
                  return (
                    <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onClickVendorCat(item?.list)
                      }}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                      >
                        {item?.list}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                      >
                        {vendorCatogory.includes(item?.list) ? item?.check : item.uncheck}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
      </div>
    </>
  );
};

export default AddVendorDetails2;
