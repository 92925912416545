import React, { useState } from "react";
import { CommentOutlined, Edit } from "@mui/icons-material";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { event_category } from "../../../redux/api/DummyJson";
import { useLocation } from "react-router-dom";

const PoReject = ({ toggleShowPopup, onShowPopupHander }) => {
  const location = useLocation();
  const methodType = location?.state?.type;
  const [productqty, setProductQty] = useState(500);
  const [edit, setEdit] = useState(true);
  const [statust, setStatust] = useState("");

  const data = [
    {
      id: 1,
      pro_id: "Pro-001",
      company: "Ninos Enterprises",
      sales_id: "SO-001 Ninos",
      sku_code: "SKU-001",
      product: "T-Shirt",
      attributes: "XL/Black",
      pro_qty: "600",
      final_sku: "Ninos-SKU001",
      amount: "30000",
      mathod: "Screen Pending",
    },
    {
      id: 2,
      pro_id: "Pro-001",
      company: "Ninos Enterprises",
      sales_id: "SO-001 Ninos",
      sku_code: "SKU-002",
      product: "Bottle",
      attributes: "-",
      pro_qty: "2000",
      final_sku: "ECPHASIS-SKU001",
      amount: "100000",
      mathod: "Laser Pending",
    },
    {
      id: 3,
      pro_id: "Pro-003",
      company: "Krishna Brand",
      sales_id: "SO-003 Krishna",
      sku_code: "SKU-001",
      product: "T-Shirt",
      attributes: "XL/Black",
      pro_qty: "100",
      final_sku: "ECPHASIS-SKU001",
      amount: "20000",
      mathod: "Laser Pending",
    },
  ];

  return (
    <>
      <div className="w-100 overflow-scroll mt-4">
        <table className="w-100">
          <tr>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                S.No
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Project ID and Name
              </p>
            </th>{" "}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Sales Order Id
              </p>
            </th>{" "}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                SKU Code
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Product Name
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Attributes
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Product Quantity
              </p>
            </th>
            {/* <th>
    <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
      Status Of Production
    </p>
  </th> */}
            {/* <th>
    <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
      Issue Detail
    </p>
  </th> */}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Shipping
              </p>
            </th>
            {/* <th>
    <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
      Actual Date Of Delivery
    </p>
  </th> */}
            {/* <th>
    <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
      Date Of Payment
    </p>
  </th> */}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Final SKU
              </p>
            </th>
            {methodType == "decorative" && (
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Decorative Method
                </p>
              </th>
            )}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Amount
              </p>
            </th>
            {/* <th>
    <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
      Payment Status
    </p>
  </th> */}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Proof
              </p>
            </th>
          </tr>
          {data?.map((item, ind) => {
            return (
              <tr className="my-3">
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {ind + 1}
                  </p>
                </th>
                <th>
                  <div className="d-flex flex-column">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 ">
                      {item?.pro_id}
                    </p>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.company}
                    </p>
                  </div>
                </th>{" "}
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.sales_id}
                  </p>
                </th>{" "}
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.sku_code}
                  </p>
                </th>
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.product}
                  </p>
                </th>
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.attributes}
                  </p>
                </th>
                <th>
                  <div className="w-100 text-center bg-transparent border-1 pb-4">
                    <input
                      placeholder=""
                      disabled={edit}
                      value={item?.pro_qty}
                      onChange={(e) => setProductQty(e.target.value)}
                      className="datepicker-input w-50 viewBoxDes ms-2 p-2 bg-white rounded-2 border-0 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 me-2"
                    />
                    {/* <button
                      className="border-0 bg-transparent "
                      onClick={() => setEdit(!edit)}
                    >
                      <DriveFileRenameOutlineOutlinedIcon />
                    </button> */}
                  </div>
                </th>
                {/* <th>
        <button className="w-100 text-center bg-transparent border-0">
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
            Based on GRN
          </p>
        </button>
      </th> */}
                {/* <th>
        <button className="w-100 text-center bg-transparent border-0">
          <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 pb-4">
            <CommentOutlined />
          </p>
        </button>
      </th> */}
                <th>
                  <button className="w-100 text-center bg-transparent border-0">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 pb-4">
                      <ShoppingCartCheckoutOutlinedIcon />
                    </p>
                  </button>
                </th>
                {/* <th>
        <div className="w-100 text-center bg-transparent border-1 pb-4">
          <input
            type="date"
            class="datepicker-input viewBoxDes ms-2 p-2 bg-white rounded-2 border-0 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 me-2"
          />
          <CalendarTodayOutlinedIcon className="f3 fs-13 fs-lg-17 fs-xl-18 fs-xxl-19 text-dark" />
        </div>
      </th> */}
                {/* <th>
        <div className="w-100 text-center bg-transparent border-1 pb-4">
          <input
            type="date"
            class="datepicker-input viewBoxDes ms-2 p-2 bg-white rounded-2 border-0 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 me-2"
          />
          <CalendarTodayOutlinedIcon className="f3 fs-13 fs-lg-17 fs-xl-18 fs-xxl-19 text-dark" />
        </div>
      </th> */}
                <th>
                  <button className="w-100 text-center bg-transparent border-0">
                    <p className=" f2 fs-xs-11 text-nowrap fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.final_sku}
                    </p>
                  </button>
                </th>
                {/* <th>
            <button className="w-100 text-center bg-transparent border-0">
              <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                -
              </p>
            </button>
          </th> */}
                <th>
                  <button className="w-100 text-center bg-transparent border-0">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.amount}
                    </p>
                  </button>
                </th>
                {methodType == "decorative" && (
                  <th>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.mathod}
                    </p>
                  </th>
                )}
                {/* <th>
        <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
          <select
            placeholder=""
            className="w-100 editBtnSelect f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3 mt-2"
            name=""
            id="">
            <option>Amount Yet Received</option>
            <option>Amount Yet Received</option>
            <option>Individual pack</option>
            <option>Ecommerce</option>
          </select>
        </p>
      </th> */}
                <th>
                  <button className="w-100 text-center bg-transparent border-0">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 pb-4 d-flex gap-1">
                      <p role={"button"} onClick={toggleShowPopup}>
                        {" "}
                        <AttachFileOutlinedIcon />
                      </p>
                      <p role={"button"} onClick={onShowPopupHander}>
                        <CommentOutlined />
                      </p>
                    </p>
                  </button>
                </th>
              </tr>
            );
          })}
        </table>
      </div>
      <div className="d-flex ac-jb w-100 mt-3">
        <button class="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
          Previous
        </button>
        <button class="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
          Next
        </button>
      </div>
    </>
  );
};

export default PoReject;
