import { PointOfSale } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import NearMeIcon from '@mui/icons-material/NearMe';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';

const PoNotesViewPopup = ({ onShowPopupHander }) => {
  return (
    <div className="modal-popup">
      <div onClick={onShowPopupHander} className="back-close" />
      <div className="center-content-contact2 w-90 w-md-55">
        <div className="w-100 d-flex ac-jb mt-3 px-md-5 px-3">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Notes
          </p>
          <button onClick={onShowPopupHander} className="viewBoxDes cust-btn p-2 bg-white rounded-5 d-flex ac-jc gap-2" >
            <CloseIcon
              className="primary" />
          </button>
        </div>
        <div className="w-100 addScrollPop">
          <div className="w-100 mt-lg-4 d-flex ac-jc flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-column flex-sm-column flex-md-column">
            <div className="overflow-scroll px-md-4 px-0 w-80 mt-3" style={{ height: '65vh' }}>
              <div className="d-flex as-jc w-100 my-2">
                <HubOutlinedIcon className="primary f3 me-3 fs-16" />
                <p className=" f2 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark ">
                  A product detail page is the lifeblood of eCommerce. Read our blog post to learn the best ways to create high-converting PDPs and get a free 25-step design template.
                </p>
              </div>
              <div className="d-flex as-jc w-100 my-3 ">
                <HubOutlinedIcon className="primary f3 me-3 fs-16" />
                <p className=" f2 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark ">
                  A product detail page of eCommerce. Read our blog post to learn the best ways to create high-converting PDPs and get a free 25-step design template.
                </p>
              </div>
              <div className="d-flex as-jc w-100 my-3 ">
                <HubOutlinedIcon className="primary f3 me-3 fs-16" />
                <p className=" f2 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark ">
                  A product detail page of eCommerce. Read our blog post to learn the best ways to create high-converting PDPs and get a free 25-step design template.
                </p>
              </div>
              <div className="d-flex as-jc w-100 my-3 ">
                <HubOutlinedIcon className="primary f3 me-3 fs-16" />
                <p className=" f2 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark ">
                  A product detail page of eCommerce. Read our blog post to learn the best ways to create high-converting PDPs and get a free 25-step design template.
                </p>
              </div>
              <div className="d-flex as-jc w-100 my-3 ">
                <HubOutlinedIcon className="primary f3 me-3 fs-16" />
                <p className=" f2 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark ">
                  A product detail page of eCommerce. Read our blog post to learn the best ways to create high-converting PDPs and get a free 25-step design template.
                </p>
              </div>
              <div className="d-flex as-jc w-100 my-3 ">
                <HubOutlinedIcon className="primary f3 me-3 fs-16" />
                <p className=" f2 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark ">
                  A product detail page of eCommerce. Read our blog post to learn the best ways to create high-converting PDPs and get a free 25-step design template.
                </p>
              </div>
              <div className="d-flex as-jc w-100 my-3 ">
                <HubOutlinedIcon className="primary f3 me-3 fs-16" />
                <p className=" f2 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark ">
                  A product detail page of eCommerce. Read our blog post to learn the best ways to create high-converting PDPs and get a free 25-step design template.
                </p>
              </div>
              <div className="d-flex as-jc w-100 my-3 ">
                <HubOutlinedIcon className="primary f3 me-3 fs-16" />
                <p className=" f2 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark ">
                  A product detail page of eCommerce. Read our blog post to learn the best ways to create high-converting PDPs and get a free 25-step design template.
                </p>
              </div>
              <div className="d-flex as-jc w-100 my-3 ">
                <HubOutlinedIcon className="primary f3 me-3 fs-16" />
                <p className=" f2 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark ">
                  A product detail page of eCommerce. Read our blog post to learn the best ways to create high-converting PDPs and get a free 25-step design template.
                </p>
              </div>
              <div className="d-flex as-jc w-100 my-3 ">
                <HubOutlinedIcon className="primary f3 me-3 fs-16" />
                <p className=" f2 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark ">
                  A product detail page of eCommerce. Read our blog post to learn the best ways to create high-converting PDPs and get a free 25-step design template.
                </p>
              </div>
              <div className="d-flex as-jc w-100 my-3 ">
                <HubOutlinedIcon className="primary f3 me-3 fs-16" />
                <p className=" f2 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark ">
                  A product detail page of eCommerce. Read our blog post to learn the best ways to create high-converting PDPs and get a free 25-step design template.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoNotesViewPopup;
