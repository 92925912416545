import React, { useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import { uploadIcon } from "../assets/img";
import FormComp from "../components/AddNewProductComps/FormComp";
import ImageUplodeComp from "../components/AddNewProductComps/ImageUplodeComp";
import { useLocation, useNavigate } from "react-router-dom";
import VendorPricingComp from "../components/MyProductComp/PricingBoxComp";

const MapProduct = () => {
  const navigat = useNavigate();
  const [colorImg, setColorImg] = useState(false);
  return (
    <div className="dashRightView overflow-scroll w-100">
      <ProductSearchHeader />
      <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5">
        Map Product
      </p>
      <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
        <FormComp setColorImg={setColorImg} colorImg={colorImg} />
        <ImageUplodeComp colorImg={colorImg} />
      </div>
      <div>
        <VendorPricingComp type={"map-product"} />
      </div>
      <div className="d-flex ac-js w-100">
        <button
          className="cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() => navigat("/vendor-details")}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default MapProduct;
