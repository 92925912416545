import { Outlet } from "react-router-dom";
import ProfileNavBar from "../ProfileNavBar";

const Layout = () => {
  return (
    <div className="dashboard">
      <ProfileNavBar />
      <Outlet />
    </div>
  );
};

export default Layout;
