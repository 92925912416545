import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  sub_menu,
  Product_Name,
  Hsn_type,
  Hsn_type_Child2,
  Hsn_type_Child3,
  Hsn_type_Child4,
  catogory_setting,
  counting,
  colors,
} from "../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  DeleteForever,
} from "@mui/icons-material";
const FormComp = ({ setColorImg, colorImg }) => {
  const navigat = useNavigate();

  // Toggle List
  const [catogoryShow, setCatogoryShow] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(false);
  const [catogoryShow3, setCatogoryShow3] = useState(false);
  const [productNameShow, setProductNameShow] = useState(false);
  const [childMenu, setChildMenu] = useState(false);
  const [PackingSizeShow, setPackingSizeShow] = useState(false);
  const [countryShow, setCountryShow] = useState(false);
  const [subcatogory, setSubcatogory] = useState(false);
  const [subcatogory2, setSubcatogory2] = useState(false);
  const [subcatogory3, setSubcatogory3] = useState(false);
  const [hsnTypeShow, setHsnTypeShow] = useState(false);
  const [childMenu2, setChildMenu2] = useState(false);
  const [Dropdown, setDrop] = useState(0);
  const [colorsShow, setColorsShow] = useState(false);
  const [check, setCheck] = useState(undefined);
  const [checkBox, setCheckBox] = useState(false);

  // input box
  const [catogory, setCatogory] = useState("");
  const [catogory2, setCatogory2] = useState("");
  const [catogory3, setCatogory3] = useState("");
  const [productName, setProductName] = useState("");
  const [productSuplierCode, setProductSuplierCode] = useState("");
  const [packingSize, setPackingSize] = useState("");
  const [country, setCountry] = useState("");
  const [hsnType, sethsnType] = useState("");
  const [colorsName, setColorsName] = useState("");
  const [sizeOfDim, setSizeOfDim] = useState("");
  const [weight, setWeight] = useState("");
  const [designFeatures, setDesignFeatures] = useState("");
  const [meterialUse, setMeterialUse] = useState("");
  const [productDisc, setProductDisc] = useState("");
  const [decorativeMethod, setDecorativeMethod] = useState("");
  const [meterial, setMeterial] = useState("");
  const [style, setStyle] = useState("");
  const [subsku, setSubSku] = useState("CODE1003_X");
  const [sku, setSku] = useState("");
  const [vendorProductCode, setVendorProductCode] = useState("");

  const onCatogory = (e) => {
    setCatogory(e.target.value);
    if (catogory.length >= 2) {
      setCatogoryShow(true);
      setProductNameShow(false);
    } else {
      if (catogory.length >= 0) setCatogoryShow(false);
    }
  };
  const onCatogory2 = (e) => {
    setCatogory2(e.target.value);
    if (catogory2.length >= 2) {
      setCatogoryShow2(true);
    } else {
      if (catogory2.length >= 0) setCatogoryShow2(false);
    }
  };
  const onCatogory3 = (e) => {
    setCatogory3(e.target.value);
    if (catogory3.length >= 2) {
      setCatogoryShow3(true);
    } else {
      if (catogory2.length >= 0) setCatogoryShow2(false);
    }
  };
  const onProduct = (e) => {
    // setProductName(e.target.value)
    if (productName.length >= 2) {
      setProductNameShow(true);
      // setProductSuplierCode('code1003');
    } else {
      if (productName.length >= 0) setProductNameShow(false);
      setProductSuplierCode("");
    }
    setCatogoryShow(false);
  };
  const onPackingSize = (e) => {
    setPackingSize(e.target.value);
    if (packingSize.length >= 2) {
      setPackingSizeShow(true);
    } else {
      if (packingSize.length >= 0) setPackingSizeShow(false);
    }
  };
  const onCountry = (e) => {
    setCountry(e.target.value);
    if (country.length >= 2) {
      setCountryShow(true);
    } else {
      if (country.length >= 0) setCountryShow(false);
    }
  };
  const onHsnTyoe = (e) => {
    sethsnType(e.target.value);
    if (hsnType.length >= 2) {
      setHsnTypeShow(true);
    } else {
      if (hsnType.length >= 0) setHsnTypeShow(false);
    }
  };
  const onColors = (e) => {
    setColorsName(e.target.value);
    if (colorsName.length >= 2) {
      setColorsShow(true);
    } else {
      if (colorsName.length >= 0) setColorsShow(false);
    }
  };

  useEffect(() => {
    if (colorsName.length !== 0) {
      setColorImg(true);
    }
  });
  return (
    <div className="w-100">
      <div className="d-flex flex-wrap ac-jb flex-m-r">
        <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
          <p className="text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Category
          </p>
          <div className="position-relative z-3">
            <input
              type="text"
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value={catogory}
              onChange={onCatogory}
            />
            <button
              className="drop_down cust-btn"
              onClick={() => {
                setCatogoryShow(!catogoryShow);
                setChildMenu(false);
                setCatogoryShow2(false);
                setCatogoryShow3(false);
                setProductNameShow(false);
                setPackingSizeShow(false);
                setCountryShow(false);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
          </div>
          {catogoryShow && (
            <div
              className="invisible-cont2 z-0"
              onClick={() => setCatogoryShow(!catogoryShow)}
            />
          )}
          <div
            className={`${catogoryShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
          >
            {sub_menu?.map((item) => {
              return (
                <div className="d-flex hover-cust">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      setCatogoryShow(false);
                      setCatogory(item?.list);
                      setChildMenu(false);
                      setSubcatogory(true);
                    }}
                  >
                    {item?.list}
                  </button>
                </div>
              );
            })}
          </div>
          <div className={childMenu ? "submenu_child_1" : "submenu_child_2"}>
            <div
              className={`${catogoryShow && "submenu_2"
                } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    {item.list !== "Game consoles" && (
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setCatogoryShow(false);
                          setCatogory(item?.list);
                          setChildMenu(false);
                        }}
                      >
                        {item?.list}
                      </button>
                    )}
                    {item.list === "Game consoles" && (
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => setChildMenu(!childMenu)}
                      >
                        {item?.list}
                      </button>
                    )}
                    {item.list === "Game consoles" && (
                      <button
                        className="cust-btn position-relative"
                        onClick={() => {
                          setChildMenu(!childMenu);
                          setProductNameShow(false);
                          setCountryShow(false);
                          setPackingSizeShow(false);
                        }}
                      >
                        {childMenu ? (
                          <KeyboardArrowRightIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {subcatogory && (
          <>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Sub Category 1
              </p>
              <div className="position-relative">
                <input
                  type="text"
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  value={catogory2}
                  onChange={onCatogory2}
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setCatogoryShow2(!catogoryShow2);
                    setCatogoryShow(false);
                    setCatogoryShow3(false);
                    setProductNameShow(false);
                    setPackingSizeShow(false);
                    setCountryShow(false);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              </div>
              {catogoryShow2 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setCatogoryShow2(!catogoryShow2)}
                />
              )}
              <div
                className={`${catogoryShow2 && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-3`}
              >
                {sub_menu?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setCatogoryShow2(false);
                          setCatogory2(item?.list);
                          setSubcatogory2(true);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Sub Category Code
              </p>
              <input
                type="text"
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={sku}
                onChange={(e) => {
                  setSku(e.target.value);
                }}
                disabled
              />
            </div>
          </>
        )}

        {subcatogory2 && (
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Sub Category 2
            </p>
            <div className="position-relative">
              <input
                type="text"
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={catogory3}
                onChange={onCatogory3}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setCatogoryShow3(!catogoryShow3);
                  setChildMenu(false);
                  setCatogoryShow(false);
                  setCatogoryShow2(false);
                  setProductNameShow(false);
                  setPackingSizeShow(false);
                  setCountryShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {catogoryShow3 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setCatogoryShow3(!catogoryShow3)}
              />
            )}
            <div
              className={`${catogoryShow3 && "submenu_1"
                } submenu_cont_1 overflow-scroll z-3`}
            >
              {sub_menu?.map((item) => {
                return (
                  <div className="d-flex hover-cust">
                    <button
                      className="px-2 cust-btn text-start py-1 w-100"
                      onClick={() => {
                        setCatogoryShow3(false);
                        setCatogory3(item?.list);
                        setChildMenu(false);
                        setSubcatogory3(true);
                      }}
                    >
                      {item?.list}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Product Name
          </p>
          <div className="position-relative">
            <input
              type="text"
              placeholder="Required Field"
              className="p-cust  cp editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              value={productName}
              onChange={onProduct}
              onClick={() => {
                setProductNameShow(!productNameShow);
                setCatogoryShow(false);
                setCatogoryShow2(false);
                setCatogoryShow3(false);
                setPackingSizeShow(false);
                setCountryShow(false);
              }}
            />
            <button
              className="drop_down cust-btn"
              onClick={() => {
                setProductNameShow(!productNameShow);
                // setProductSuplierCode('code1003');
                setCatogoryShow(false);
                setCatogoryShow2(false);
                setCatogoryShow3(false);
                setPackingSizeShow(false);
                setCountryShow(false);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
          </div>
          {productNameShow && (
            <div
              className="invisible-cont2"
              onClick={() => setProductNameShow(!productNameShow)}
            />
          )}
          <div
            className={`${productNameShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
          >
            {sub_menu?.map((item) => {
              return (
                <div className="d-flex hover-cust">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      setProductNameShow(false);
                      setProductName(item?.list);
                      setProductSuplierCode("CODE1003");
                      setSizeOfDim("XL");
                      setWeight("100Kg");
                      setDesignFeatures("Dummy Content");
                      setMeterialUse("Dummy Content");
                      setCountry("India");
                      setPackingSize("150");
                      setColorsName("Red, blue, Purple");
                      sethsnType("200");
                      setDecorativeMethod(
                        "Game consoles, Game consoles, Game consoles, Game consoles, Game consoles, Game consoles"
                      );
                      setProductDisc(
                        "If you arent satisfied with the build tool and configuration choices, you can eject at any time.This command will remove the single build dependency from your project."
                      );
                      setMeterial("meterial cont");
                      setStyle("Gold");
                      setVendorProductCode("SM10001");
                      setSku("SKU0001");
                    }}
                  >
                    {item?.list}
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            SKU Code
          </p>
          <input
            type="text"
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={sku}
            onChange={(e) => {
              setSku(e.target.value);
            }}
            disabled
          />
        </div>
        {/* <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        Product Supplier Code
                    </p>
                    <input
                        type='text'
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        value={productSuplierCode}
                        onChange={(e) => { setProductSuplierCode(e.target.value) }}
                        disabled
                    />
                </div> */}
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Vendor Product Code
          </p>
          <input
            type="text"
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={vendorProductCode}
            // onChange={(e) => { setVendorProductCode(e.target.value) }}
            disabled
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Decoration Method
          </p>
          <textarea
            type="text"
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={decorativeMethod}
            // onChange={(e) => { setDecorativeMethod(e.target.value) }}
            disabled
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
            Country of Origin
          </p>
          <div className="position-relative">
            <input
              type="text"
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value={country}
              disabled
            // onChange={onCountry}
            />
            {/* <button className='drop_down cust-btn' onClick={() => {
                            setCountryShow(!countryShow);
                            setCatogoryShow(false);
                            setCatogoryShow2(false);
                            setCatogoryShow3(false);
                            setProductNameShow(false);
                            setPackingSizeShow(false);
                        }}>
                            <KeyboardArrowDownIcon />
                        </button> */}
          </div>
          {countryShow && (
            <div
              className="invisible-cont2"
              onClick={() => setCountryShow(!countryShow)}
            />
          )}
          <div
            className={`${countryShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
          >
            {sub_menu?.map((item) => {
              return (
                <div className="d-flex hover-cust">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      setCountryShow(false);
                      setCountry(item?.list);
                    }}
                  >
                    {item?.list}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Product Packaging Size
          </p>
          <div className="position-relative">
            <input
              type="text"
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value={packingSize}
              disabled
            // onChange={onPackingSize}
            />
            {/* <button className='drop_down cust-btn' onClick={() => {
                            setPackingSizeShow(!PackingSizeShow);
                            setCatogoryShow(false);
                            setCatogoryShow2(false);
                            setCatogoryShow3(false);
                            setProductNameShow(false);
                            setCountryShow(false);
                        }}>
                            <KeyboardArrowDownIcon />
                        </button> */}
          </div>
          {PackingSizeShow && (
            <div
              className="invisible-cont2"
              onClick={() => setPackingSizeShow(!PackingSizeShow)}
            />
          )}
          <div
            className={`${PackingSizeShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
          >
            {sub_menu?.map((item) => {
              return (
                <div className="d-flex hover-cust">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      setPackingSizeShow(false);
                      setPackingSize(item?.list);
                    }}
                  >
                    {item?.list}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            HSN Code
          </p>
          <div className="position-relative">
            <input
              type="text"
              disabled
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value={hsnType}
              onChange={onHsnTyoe}
            />
            {/* <button
                            className="drop_down cust-btn"
                            onClick={() => {
                                setHsnTypeShow(!hsnTypeShow);
                                setCatogoryShow(false);
                                setCatogoryShow2(false);
                                setCatogoryShow3(false);
                                setProductNameShow(false);
                                setPackingSizeShow(false);
                                setCountryShow(false);
                            }}
                        >
                            <KeyboardArrowDownIcon />
                        </button> */}
          </div>
          {hsnTypeShow && (
            <div
              className="invisible-cont2"
              onClick={() => setHsnTypeShow(!hsnTypeShow)}
            />
          )}
          <div
            className={`${hsnTypeShow && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
          >
            {Hsn_type?.map((item, ind) => {
              return (
                <div className="d-flex hover-cust">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      Dropdown === ind
                        ? setChildMenu2(!childMenu2)
                        : setDrop(ind);
                    }}
                  >
                    {item?.list}
                  </button>

                  {/* {ind === childM/enu3 && */}
                  <button
                    className="cust-btn position-relative"
                    onClick={() => {
                      Dropdown === ind
                        ? setChildMenu2(!childMenu2) && setDrop(ind)
                        : setDrop(ind);
                    }}
                  >
                    {/* {childMenu2 && Dropdown === ind ? */}
                    <KeyboardArrowRightIcon />
                  </button>
                </div>
              );
            })}
          </div>
          {Dropdown === 0 && (
            <div className={childMenu2 ? "submenu_child_1" : "submenu_child_2"}>
              <div
                className={`${hsnTypeShow && "submenu_2 h-auto sub-2"
                  } submenu_cont_1 overflow-scroll z-3`}
              >
                {Hsn_type_Child2?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setHsnTypeShow(false);
                          sethsnType(item?.list);
                          setChildMenu2(false);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {Dropdown === 1 && (
            <div className={childMenu2 ? "submenu_child_1" : "submenu_child_2"}>
              <div
                className={`${hsnTypeShow && "submenu_2 h-auto sub-2"
                  } submenu_cont_1 overflow-scroll z-3`}
              >
                {Hsn_type_Child3?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setHsnTypeShow(false);
                          sethsnType(item?.list);
                          setChildMenu2(false);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {Dropdown === 2 && (
            <div className={childMenu2 ? "submenu_child_1" : "submenu_child_2"}>
              <div
                className={`${hsnTypeShow && "submenu_2 h-auto sub-2"
                  } submenu_cont_1 overflow-scroll z-3`}
              >
                {Hsn_type_Child4?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setHsnTypeShow(false);
                          sethsnType(item?.list);
                          setChildMenu2(false);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className="w-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
            Product Description
          </p>
          <textarea
            type="text"
            value={productDisc}
            disabled
            style={{ height: "100px" }}
            placeholder="Autogenerate"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Size
          </p>
          <input
            type="text"
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={sizeOfDim}
            disabled
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Weight
          </p>
          <input
            type="text"
            disabled
            value={weight}
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Dimension
          </p>
          <input
            type="text"
            disabled
            value={designFeatures}
            placeholder="h × w × l"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Material
          </p>
          <input
            type="text"
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={meterial}
            disabled
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Style
          </p>
          <input
            type="text"
            placeholder="Required Field"
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={style}
            disabled
          />
        </div>
        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Color
          </p>
          <div className="position-relative">
            <input
              type="text"
              placeholder="Required Field"
              disabled
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value={colorsName}
            // onChange={onColors}
            />
            <br />
            {/* <button className='drop_down cust-btn' onClick={() => {
                            setColorsShow(!colorsShow);
                            setCatogoryShow(false);
                            setCatogoryShow2(false);
                            setCatogoryShow3(false);
                            setProductNameShow(false);
                            setCountryShow(false);
                            setPackingSizeShow(false)
                        }}>
                            <KeyboardArrowDownIcon />
                        </button> */}
          </div>
          {colorsShow && (
            <div
              className="invisible-cont2"
              onClick={() => setColorsShow(!colorsShow)}
            />
          )}
          <div
            className={`${colorsShow && "submenu_1"
              } submenu_cont_1 overflow-scroll z-3`}
          >
            {colors?.map((item) => {
              return (
                <div className="d-flex hover-cust">
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      setColorsShow(false);
                      setColorsName(item?.color);
                    }}
                  >
                    {item?.color}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        {colorImg && (
          <div className="w-100 overflow-scroll mt-4">
            <table className="w-100">
              <tr className="mt-4">
                <div className="">
                  <td>
                    <div className="d-flex w-100">
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 cp fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "50px" }}
                        >
                          {check === 1 ? (
                            <CheckBoxOutlined
                              onClick={() => setCheck(undefined)}
                            />
                          ) : (
                            <CheckBoxOutlineBlank onClick={() => setCheck(1)} />
                          )}
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-start primary f3"
                          style={{ width: "200px" }}
                        >
                          Variant
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          Available
                        </p>
                      </div>
                      <div className="mx-2">
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          style={{ width: "100px" }}
                        >
                          Sub_SKU
                        </p>
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
              <tr className="mt-2">
                <div className="my-3">
                  <td>
                    <div className="d-flex w-100">
                      <div
                        className="d-flex ac-jc mx-2"
                        style={{ width: "50px" }}
                      >
                        <button className="mx-2 d-flex ac-jc cust-btn">
                          {checkBox || check == 1 ? (
                            <CheckBoxOutlined
                              className="primary cp"
                              onClick={() => setCheckBox(false)}
                            />
                          ) : (
                            <CheckBoxOutlineBlank
                              className="primary cp"
                              onClick={() => setCheckBox(true)}
                            />
                          )}
                        </button>
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          type="text"
                          className="num-cls-btn px-2 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark"
                          value={"XL/Blue/Gold"}
                          placeholder="CODE1003_X"
                          style={{ width: "200px" }}
                        // onChange={(e) => setSubSku(e.target.value)}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          type="text"
                          className="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          placeholder="100"
                          style={{ width: "100px" }}
                          value={"100"}
                        />
                      </div>
                      <div className="mx-2">
                        <input
                          disabled
                          type="text"
                          className="num-cls-btn px-2 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                          value={subsku}
                          placeholder="CODE1003_X"
                          style={{ width: "100px" }}
                        // onChange={(e) => setSubSku(e.target.value)}
                        />
                      </div>
                      <button className="mx-2 d-flex ac-jc cp cust-btn">
                        <DeleteForever className="primary" />
                      </button>
                    </div>
                  </td>
                </div>
              </tr>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormComp;
