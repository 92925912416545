import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { productTableData } from "../redux/api/DummyJson";
import SendIcon from "@mui/icons-material/Send";
import { product, profilePic, uploadIcon } from "../assets/img";


const ViewCollaborateDetails = () => {
  const location = useLocation();
  const [data, setData] = useState("");
  const [action, setAction] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setData(location?.state?.data);
  }, [location]);

  console.log(data);



  const [textShow, setTextShow] = useState(true)
  const [file, setFile] = useState('');
  const [smallimage, setSmallImage] = useState('')
  const imageUplode = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    console.log(file, 'windfine')
    // setTextShow(false)
  }

  const imgclick = (e) => {
    setSmallImage(URL.createObjectURL(e.target.files[0]));
    console.log(smallimage, 'windfine')
    setTextShow(false)
  }

  return (
    <div className="dashRightView p-5">
      <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5">
        Collaborate Details
      </p>
      <div className="w-100 mt-4 searchField bg-white ">
        <div className="w-100 d-flex ac-je">
          {/* <button
            className="cust-btn addbtn mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            Ticket Close
          </button> */}
        </div>
        <div className="w-100 d-flex flex-lg-row flex-column px-3">
          <div className="w-100">
            {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Vendor Name:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.VendorName}
              </p>
            </div> */}
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Vendor ID:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.vendorId}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Vendor Rep:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.name}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                File:
              </p>
              <img src={product} className="imgDashboard ms-2 object-fit-contain" />
            </div>
            {/* <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Description:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.description}
              </p>
            </div> */}
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Sent By:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.sendBy}
              </p>
            </div>
            <div className="w-100 d-flex ac-js mt-3">
              <p className="w-30 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                Time:
              </p>
              <p className="w-70 f3 ms-3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black">
                {data?.time}
              </p>
            </div>
          </div>

          {/* <div className="w-xxl-15 w-xl-15 w-lg-15 position-relative">
            <button
              onClick={() => setAction(!action)}
              className="cust-btn addbtn mt-4 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Actions
            </button>
            {action && (
              <div
                onClick={() => navigate("/order-list")}
                className="w-xxl-100 w-xl-100 w-lg-100 w-20 actionDetailPopup searchField pointerView py-2 ac-jc rounded-3"
              >
                <h6 className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14">
                  Order List
                </h6>
              </div>
            )}
          </div> */}
          <div className="w-100">
            <div className="d-flex ac-je mt-5">
              <textarea
                className="editBtn rounded-3 p-2 w-100"
                placeholder="Type here..."
              />
              <div className="mt-4 ms-1">
                <SendIcon className="pointerView primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
              </div>
            </div>
            <div className="mt-5 w-100">
              <div className='d-flex ac-jc flex-column'>
                <label className='img_up_cont2 d-flex ac-jc cp'>
                  <div className='d-flex ac-jc flex-column'>
                    <img
                      // className={logo?.name ? "img_up_cont object-fit-contain" : "placeholder_icon object-fit-contain"}
                      // src={logo?.name ? window.URL.createObjectURL(logo) : uploadIcon}
                      // alt="logo"
                      className={smallimage.length !== 0 ? "img_up_cont2 object-fit-contain" : "placeholder_icon object-fit-contain"}
                      src={smallimage.length === 0 ? uploadIcon : smallimage}
                    />
                    {textShow ? <p className='f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 px-5 primary'> Click here to upload</p> : null}
                  </div>
                  <input
                    // onChange={(e) => { setLogo(e.target.files[0]); setTextShow(false) }}
                    onChange={imgclick}
                    className="d-none"
                    type="file"
                    accept="image/png, image/gif, image/jpeg" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 mt-5 px-2">
          <p className=" f3 fs-15 fs-xs-11 mb-3 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
            Description:
          </p>
          <div
            className="editBtn rounded-3 cust_dis_h p-2 w-100 text"
          >
            A good example of a paragraph contains a topic sentence, details and a conclusion. There are many different kinds of animals that live in China. Tigers and leopards are animals that live in Chinas forests in the north. In the jungles, monkeys swing in the trees and elephants walk through the brush.
            A good example of a paragraph contains a topic sentence, details and a conclusion. There are many different kinds of animals that live in China. Tigers and leopards are animals that live in Chinas forests in the north. In the jungles, monkeys swing in the trees and elephants walk through the brush.
            A good example of a paragraph contains a topic sentence, details and a conclusion. There are many different kinds of animals that live in China. Tigers and leopards are animals that live in Chinas forests in the north. In the jungles, monkeys swing in the trees and elephants walk through the brush.
            A good example of a paragraph contains a topic sentence, details and a conclusion. There are many different kinds of animals that live in China. Tigers and leopards are animals that live in Chinas forests in the north. In the jungles, monkeys swing in the trees and elephants walk through the brush.
            A good example of a paragraph contains a topic sentence, details and a conclusion. There are many different kinds of animals that live in China. Tigers and leopards are animals that live in Chinas forests in the north. In the jungles, monkeys swing in the trees and elephants walk through the brush.
          </div>
        </div>
        <div className="w-100 pb-4 flex-md-row flex-column d-flex mt-5">
          <button
            onClick={() => {
              navigate("/decorative-vendor", {
                state: { type: "collaborate" },
              });
            }}
            className="cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Back
          </button>
          {location?.state?.type === "edit" && (
            <button className=" cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              Update Details
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewCollaborateDetails;
