import CloseIcon from "@mui/icons-material/Close";
import { mailSendImg } from "../../assets/img";
import { useNavigate } from "react-router-dom";

const AddDetPopup = ({ showPopupHander, setPopup, setSuccessPop }) => {
  const navigate = useNavigate();
  return (
    <div className="modal-popup">
      <div onClick={showPopupHander} className="back-close" />
      <div className="center-content">
        <div className="d-flex ac-jc">
          <img src={mailSendImg} className="mailImg" />
        </div>
        <div className="d-flex p-4 ac-jc">
          <p className="f2 fs-xs-12 fs-sm-15 fs-md-16 fs-lg-17 fs-xl-18 fs-xxl-19">
            Mail Sent Successfuly
          </p>
        </div>
        <div className="d-flex ac-jc">
          <button
            onClick={() => navigate("/")}
            className="cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddDetPopup;
