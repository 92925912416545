import React from 'react'
import FormComp from '../AddNewProductComps/FormComp'
import ImageUplodeComp from '../AddNewProductComps/ImageUplodeComp'
import LeftComp from './LeftComp'
import RightComp from './RightComp'
import ImageRightComp from './ImageRightComp'
import { useNavigate } from 'react-router-dom'

const OverViewComp = ({ edit, types }) => {
    const navigate = useNavigate()
    return (
        <div className='d-flex as-jb w-100 mt-5 res-flex gap-5'>
            <LeftComp edit={edit} types={types} />
            <RightComp types={types} />
            {/* <ImageRightComp /> */}
        </div>
    )
}

export default OverViewComp