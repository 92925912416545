import {
  animatedImg,
  editIcon,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../assets/img";
import { Search } from "@mui/icons-material";
import { customerList } from "../redux/api/DummyJson";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import RegisteredCustomerList from "../components/Customers/RegisteredCustomerList";
import NonRegisteredCustomerList from "../components/Customers/NonregisteredCustomerList";

const CustomerList = () => {
  const navigate = useNavigate();
  const [regList, setRegList] = useState(false);
  const [nonRegList, setNonRegList] = useState(false);

  useEffect(() => {
    setRegList(true);
  }, []);
  return (
    <div className="dashRightView p-5">
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, number or PO# to search" />
        <img src={animatedImg} className="searchManImg" />
      </div>
      <div className="d-flex mt-2 ac-jb flex-wrap">
        <div className="d-flex mt-3 rk">
          <button
            onClick={() => {
              setRegList(true);
              setNonRegList(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${regList
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
              }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${regList ? "text-light" : "primary"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Registered Customer List
            </p>
          </button>
          <button
            onClick={() => {
              setRegList(false);
              setNonRegList(true);
            }}
            style={{ cursor: "pointer" }}
            className={`${nonRegList
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
              }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${nonRegList ? "text-light" : "primary"
                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Non-Registered Customer List
            </p>
          </button>
        </div>
      </div>
      {regList && <RegisteredCustomerList />}
      {nonRegList && <NonRegisteredCustomerList />}
    </div>
  );
};

export default CustomerList;
