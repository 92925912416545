import React, { useEffect, useState } from "react";
import { Country, Vendor_offer_type, currency_list, line_business, payment_Method, payment_terms, type_business, vendor_category } from "../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageCroper from "../Popup/ImageCroper";


const AddVendorDetails1 = ({ setVendorCat, vendorCat }) => {

  // Drop Down State
  const [dropDownToggle, setDropDownToggle] = useState(undefined);
  const [vendorCatShow, setVendorCatShow] = useState(false);
  const [paymentMethodShow, setPaymentMethodShow] = useState(false);
  const [lineBusinessShow, setLineBusinessShow] = useState(false);



  // Form State
  const [vendorOfferType, setVendorOfferType] = useState('');
  const [vendorCatogory, setVendorCatogory] = useState("")
  const [lineBusiness, setLineBusiness] = useState('');
  const [paymentTerms, setPaymentTerms] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [GstID, setGstID] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [currency, setCurrency] = useState('');
  const [description, setDescription] = useState('');


  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [croperCol, setCroperCol] = useState(false);
  const [logo, setLogo] = useState();
  const [banner, setBanner] = useState();



  const dualToggele = (type) => {
    if (type == "logo") {
      setCroperCol(false);
      toggleImagePopup();
    } else {
      setCroperCol(true);
      toggleImagePopup();
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  useEffect(() => {
    if (croperCol && croperImage) {
      setBanner(croperImage);
      setCropImage("");
    } else if (!croperCol && croperImage) {
      setLogo(croperImage);
      setCropImage("");
    }
  }, [croperImage]);

  // Function
  const onClickVendorCat = (e) => {
    if (vendorCatogory.includes(e)) {
      const listtemp = vendorCatogory.indexOf(e);
      const list = [...vendorCatogory];
      list.splice(listtemp, 1);
      setVendorCatogory(list);
    } else {
      setVendorCatogory([...vendorCatogory, e])
    }
  }
  const onClickLineBusiness = (e) => {
    if (lineBusiness.includes(e)) {
      const listtemp = lineBusiness.indexOf(e);
      const list = [...lineBusiness];
      list.splice(listtemp, 1);
      setLineBusiness(list);
    } else {
      setLineBusiness([...lineBusiness, e])
    }
  }
  const onClickPaymentMethod = (e) => {
    if (paymentMethod.includes(e)) {
      const listtemp = paymentMethod.indexOf(e);
      const list = [...paymentMethod];
      list.splice(listtemp, 1);
      setPaymentMethod(list);
    } else {
      setPaymentMethod([...paymentMethod, e])
    }
  }
  return (
    <div className='d-flex as-jb w-100 mt-5 res-flex gap-5'>
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <div className='w-100'>
        <div className='d-flex flex-wrap ac-jb flex-m-r px-md-4'>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Legal Entity Name*
            </p>
            <input
              placeholder="Required Field"
              value="Ninos"
              className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Trade Name
            </p>
            <input
              placeholder="Required Field"
              value="Roger"
              className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Vendor Category*
            </p>
            <div className="position-relative"
            >
              <input
                placeholder="Sellect Category"
                type='text'
                value={vendorCatogory}
                onClick={() =>
                  setVendorCatShow(!vendorCatShow)
                }
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
              <button
                className="drop_down cust-btn"
                onClick={() =>
                  setVendorCatShow(!vendorCatShow)
                }
              >
                <KeyboardArrowDownIcon />
              </button>
              {vendorCatShow && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setVendorCatShow(false)}
                />
              )}
              <div
                className={`${vendorCatShow && "submenu_1 h-auto"
                  } submenu_cont_1 overflow-scroll z-3`}
              >
                {vendor_category?.map((item, ind) => {
                  return (
                    <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onClickVendorCat(item?.list)
                      }}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                      >
                        {item?.list}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                      >
                        {vendorCatogory.includes(item?.list) ? item?.check : item.uncheck}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          {/* <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Line of Business*
            </p>
            <div className="position-relative"
            >
              <input
                placeholder="Sellect Category"
                type='text'
                value={lineBusiness}
                onClick={() => {
                  setLineBusinessShow(!lineBusinessShow);
                  setVendorCatShow(false);
                  setDropDownToggle(undefined);
                  setPaymentMethodShow(false)
                }
                }
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setLineBusinessShow(!lineBusinessShow);
                  setVendorCatShow(false);
                  setDropDownToggle(undefined);
                  setPaymentMethodShow(false)
                }
                }
              >
                <KeyboardArrowDownIcon />
              </button>
              {lineBusinessShow && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setLineBusinessShow(false)}
                />
              )}
              <div
                className={`${lineBusinessShow && "submenu_1 h-auto py-2"
                  } submenu_cont_1 overflow-scroll z-3`}
              >
                {line_business?.map((item, ind) => {
                  return (
                    <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onClickLineBusiness(item?.list)
                      }}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                      >
                        {item?.list}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                      >
                        {lineBusiness.includes(item?.list) ? item?.check : item.uncheck}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </div> */}
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Type of Business*
            </p>
            <div className="position-relative ">
              <input
                placeholder="
Required Field

"
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={vendorCat}
                onChange={(e) => {
                  console.log(e.target.value);
                  setVendorCat(e.target.value);
                }}
                onClick={() => {
                  setDropDownToggle(1)
                  setVendorCatShow(false);
                  setPaymentMethodShow(false)
                  setLineBusinessShow(false)
                }}
              />
              <button
                className="drop_down cust-btn"
                // onPointerEnter={setVendorCat('others')}
                onClick={() => {
                  setDropDownToggle(1)
                  setVendorCatShow(false);
                  setPaymentMethodShow(false)
                  setLineBusinessShow(false)
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDownToggle === 1 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDownToggle(undefined)}
                />
              )}
              <div
                className={`${dropDownToggle === 1 && "submenu_1 h-auto"
                  } submenu_cont_1 overflow-scroll z-3`}>
                {type_business?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setDropDownToggle(undefined);
                          setVendorCat(item?.list);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            {vendorCat === "others" && (
              <input
                placeholder="Required Field"
                className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
            )}
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Vendor Offering Type*
            </p>
            <div className="position-relative ">
              <input
                placeholder="Required Field"
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={vendorOfferType}
                onChange={(e) => {
                  console.log(e.target.value);
                  setVendorOfferType(e.target.value);
                }}
                onClick={() => {
                  setDropDownToggle(2);
                  setVendorCatShow(false);
                  setPaymentMethodShow(false)
                  setLineBusinessShow(false)
                }}
              />
              <button
                className="drop_down cust-btn"
                // onPointerEnter={setVendorCat('others')}
                onClick={() => {
                  setDropDownToggle(2);
                  setVendorCatShow(false);
                  setPaymentMethodShow(false)
                  setLineBusinessShow(false)
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDownToggle === 2 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDownToggle(undefined)}
                />
              )}
              <div
                className={`${dropDownToggle === 2 && "submenu_1 h-auto"
                  } submenu_cont_1 overflow-scroll z-3`}>
                {Vendor_offer_type?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setDropDownToggle(undefined);
                          setVendorOfferType(item?.list);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Vendor Offerings*
            </p>
            <select
              placeholder="Required Field"
              className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              name=""
              id=""
            >
              <option>MHQ</option>
            </select>
          </div> */}
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Payment Terms*
            </p>
            <div className="position-relative ">
              <input
                placeholder="
Required Field

"
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={paymentTerms}
                onChange={(e) => {
                  console.log(e.target.value);
                  setPaymentTerms(e.target.value);
                }}
                onClick={() => {
                  setDropDownToggle(4)
                  setVendorCatShow(false);
                  setPaymentMethodShow(false)
                  setLineBusinessShow(false)
                }}
              />
              <button
                className="drop_down cust-btn"
                // onPointerEnter={setVendorCat('others')}
                onClick={() => {
                  setDropDownToggle(4)
                  setVendorCatShow(false);
                  setPaymentMethodShow(false)
                  setLineBusinessShow(false)
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDownToggle === 4 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDownToggle(undefined)}
                />
              )}
              <div
                className={`${dropDownToggle === 4 && "submenu_1 h-auto"
                  } submenu_cont_1 overflow-scroll z-3`}>
                {payment_terms?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setDropDownToggle(undefined);
                          setPaymentTerms(item?.list);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Payment Method*
            </p>
            <div className="position-relative"
            >
              <input
                placeholder="Sellect Category"
                type='text'
                value={paymentMethod}
                onClick={() => {
                  setPaymentMethodShow(!paymentMethodShow)
                  setVendorCatShow(false);
                  setDropDownToggle(undefined);
                  setLineBusinessShow(false)
                }

                }
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setPaymentMethodShow(!paymentMethodShow)
                  setVendorCatShow(false);
                  setDropDownToggle(undefined);
                  setLineBusinessShow(false)
                }
                }
              >
                <KeyboardArrowDownIcon />
              </button>
              {paymentMethodShow && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setPaymentMethodShow(false)}
                />
              )}
              <div
                className={`${paymentMethodShow && "submenu_1 h-auto py-2"
                  } submenu_cont_1 overflow-scroll z-3`}
              >
                {payment_Method?.map((item, ind) => {
                  return (
                    <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onClickPaymentMethod(item?.list)
                      }}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                      >
                        {item?.list}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                      >
                        {paymentMethod.includes(item?.list) ? item?.check : item.uncheck}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              GST Number*
            </p>
            <input
              placeholder="Required Field"
              value={GstID}
              onChange={(e) => setGstID(e.target.value)}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            />
          </div>

          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <div className="w-100 ac-jb d-flex">
              <div className="w-100">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Address Line 1*
                </p>
                <input
                  placeholder="Required Field"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                />
              </div>
              {/* <div className="w-35">
              <div className="w-100">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Address Type
                </p>
                <select
                  placeholder="Required Field"
                  className="w-100 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
                  name=""
                  id=""
                >
                  <option>Shipping</option>
                  <option>Billing</option>
                  <option>Both</option>
                </select>
              </div>
            </div> */}
            </div>
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Address Line 2
            </p>
            <input
              placeholder="Required Field"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
            {/* <div className="w-35">
                <div className="w-100">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Address Type
                  </p>
                  <select
                    placeholder="Required Field"
                    className="w-100 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
                    name=""
                    id=""
                  >
                    <option>Shipping</option>
                    <option>Billing</option>
                    <option>Both</option>
                  </select>
                </div>
              </div> */}
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Registered Address Country*
            </p>
            <div className="position-relative ">
              <input
                placeholder="Required Field"
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={country}
                onChange={(e) => {
                  console.log(e.target.value);
                  setCountry(e.target.value);
                }}
                onClick={() => {
                  setDropDownToggle(3);
                  setVendorCatShow(false);
                  setPaymentMethodShow(false)
                  setLineBusinessShow(false)

                }}
              />
              <button
                className="drop_down cust-btn"
                // onPointerEnter={setVendorCat('others')}
                onClick={() => {
                  setDropDownToggle(3);
                  setVendorCatShow(false);
                  setPaymentMethodShow(false)
                  setLineBusinessShow(false)
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDownToggle === 3 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDownToggle(undefined)}
                />
              )}
              <div
                className={`${dropDownToggle === 3 && "submenu_1 h-auto"
                  } submenu_cont_1 overflow-scroll z-3`}>
                {Country?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setDropDownToggle(undefined);
                          setCountry(item?.list);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Registered Address State*
            </p>
            <div className="position-relative ">
              <input
                placeholder="Required Field"
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={state}
                onChange={(e) => {
                  console.log(e.target.value);
                  setState(e.target.value);
                }}
                onClick={() => {
                  setDropDownToggle(5);
                  setVendorCatShow(false);
                  setPaymentMethodShow(false)
                  setLineBusinessShow(false)

                }}
              />
              <button
                className="drop_down cust-btn"
                // onPointerEnter={setVendorCat('others')}
                onClick={() => {
                  setDropDownToggle(5);
                  setVendorCatShow(false);
                  setPaymentMethodShow(false)
                  setLineBusinessShow(false)
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDownToggle === 5 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDownToggle(undefined)}
                />
              )}
              <div
                className={`${dropDownToggle === 5 && "submenu_1 h-auto"
                  } submenu_cont_1 overflow-scroll z-3`}>
                {Country?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setDropDownToggle(undefined);
                          setState(item?.list);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Registered Address City*
            </p>
            <div className="position-relative ">
              <input
                placeholder="Required Field"
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={city}
                onChange={(e) => {
                  console.log(e.target.value);
                  setCity(e.target.value);
                }}
                onClick={() => {
                  setDropDownToggle(6);
                  setVendorCatShow(false);
                  setPaymentMethodShow(false)
                  setLineBusinessShow(false)

                }}
              />
              <button
                className="drop_down cust-btn"
                // onPointerEnter={setVendorCat('others')}
                onClick={() => {
                  setDropDownToggle(6);
                  setVendorCatShow(false);
                  setPaymentMethodShow(false)
                  setLineBusinessShow(false)
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDownToggle === 6 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDownToggle(undefined)}
                />
              )}
              <div
                className={`${dropDownToggle === 6 && "submenu_1 h-auto"
                  } submenu_cont_1 overflow-scroll z-3`}>
                {Country?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setDropDownToggle(undefined);
                          setCity(item?.list);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Registered Pincode*
            </p>
            <input
              placeholder="Required Field"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>

          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Currency
            </p>
            <div className="position-relative ">
              <input
                placeholder="Enter currency"
                className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={currency}
                onChange={(e) => {
                  console.log(e.target.value);
                  setCurrency(e.target.value);
                }}
                onClick={() => {
                  setDropDownToggle(10)
                  setVendorCatShow(false);
                  setPaymentMethodShow(false)
                  setLineBusinessShow(false)
                }}
              />
              <button
                className="drop_down cust-btn"
                // onPointerEnter={setVendorCat('others')}
                onClick={() => {
                  setDropDownToggle(10)
                  setVendorCatShow(false);
                  setPaymentMethodShow(false)
                  setLineBusinessShow(false)
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDownToggle === 10 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDownToggle(undefined)}
                />
              )}
              <div
                className={`${dropDownToggle === 10 && "submenu_1 h-auto"
                  } submenu_cont_1 overflow-scroll z-3`}>
                {currency_list?.map((item) => {
                  return (
                    <div className="d-flex hover-cust">
                      <button
                        className="px-2 cust-btn text-start py-1 w-100"
                        onClick={() => {
                          setDropDownToggle(undefined);
                          setCurrency(item?.list);
                        }}
                      >
                        {item?.list}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Tags
            </p>
            <input
              placeholder="Required Field"
              value=""
              className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div> */}
          {/* <div className="d-flex flex-wrap w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 mt-4 ac-jc">
            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 d-flex ac-jc ">
              <div className="w-100">
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-10">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Logo Image
                  </p>
                  <label className="w-100 mt-2 mb-3" onClick={() => dualToggele()}>
                    <div className="py-1 w-100">
                      <div className="d-flex ac-jb w-100">
                        <p className="editBtn rounded-3 cp border-2 text-nowrap py-1 px-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">{croperImage?.length === 0 ? 'Browse or Drag a file' : 'Image Uploded'}</p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 d-flex ac-jc ">
              <div className="w-100">
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Banner Image
                  </p>
                  <label className="w-100 mt-2 mb-3" onClick={() => dualToggele('banner')}>
                    <div className="py-1 w-100">
                      <div className="d-flex ac-jb w-100">
                        <p className="editBtn rounded-3 cp border-2 text-nowrap py-1 px-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">{croperImage?.length === 0 ? 'Browse or Drag a file' : 'Image Uploded'}</p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          {croper && (
            <ImageCroper
              toggleImagePopup={toggleImagePopup}
              setCropImage={setCropImage}
            />
          )} */}

          {/* <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Logo Image
            </p>
            <input
              type="file"
              onChange={(e) => {
                console.log(e.target.files[0]);
              }}
              className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Banner Image
            </p>
            <input
              type="file"
              onChange={(e) => {
                console.log(e.target.files[0]);
              }}
              className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div> */}

          <div
            className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100"
            onClick={() => dualToggele("ban")}
          >
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Banner Image
            </p>
            <input
              style={{ cursor: "pointer" }}
              value={banner ? "Banner Uploaded Successfully" : "Browse or Drag a file"}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>

          <div
            className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100"
            onClick={() => dualToggele("logo")}
          >
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Logo Image
            </p>
            <input
              style={{ cursor: "pointer" }}
              value={logo ? "Logo Uploaded Successfully" : "Browse or Drag a file"}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Profile Description
            </p>
            <textarea
              value={description}
              placeholder="Required Field"
              onChange={(e) => setDescription(e.target.value)}
              className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>

          {/* <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Profile Description
            </p>
            <textarea
              value="Description"
              className="p-cust  editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div> */}

        </div>
      </div>
    </div>
  );
};

export default AddVendorDetails1;
