import React, { useState } from "react";
import { product } from "../../assets/img";
import CachedIcon from "@mui/icons-material/Cached";
import { Search } from "@mui/icons-material";
import UploadIcon from "@mui/icons-material/Upload";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import GetAppIcon from "@mui/icons-material/GetApp";

const NewProductSuggestion = ({ place, handleUploadHandler }) => {
  return (
    <div>
      <div className="mt-5 banner-left">
        <div className="d-flex ac-jb flex-column flex-md-row">
          <div className="d-flex gap-3">
            <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
              <option hidden>Select Categories</option>
              <option>Mobile Phone</option>
              <option>Water Bottle</option>
            </select>
            <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
              <option hidden>Select Status</option>
              <option>Active</option>
              <option>Deactive</option>
            </select>
          </div>
          <div className="d-flex ac-jc place-holde justify-content-md-end gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
            <button className="cust-btn addbtn2   gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
              Export
            </button>
            <input
              type="text"
              className="get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4 px-3 w-auto f3 fs-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-auto"
              placeholder={"Search Product Name"}
            />
            <div className="d-flex ac-jc gap-3">
              <button
                // onClick={() => navigate("/add-vendor")}
                className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
              >
                <Search />
              </button>
              <button
                // onClick={() => navigate("/add-vendor")}
                className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
              >
                <CachedIcon />
              </button>
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
            Total Count : 04
          </p>
        </div>
      </div>

      <div className="w-100">
        {/* <p style={{ color: "#787B7F", marginTop: "10px", fontSize: "16px" }}>
          Please Upload each side of your products
        </p> */}
        <div className=" d-flex ac-js flex-wrap mt-5">
          <div
            className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 cp position-relative"
            onClick={() => handleUploadHandler("view")}
          >
            <div className="prod-list-box">
              <div className="cust-btn d-flex ac-jc rounded-3">
                <img src={product} alt="" className="custom-w-h rounded-3" />
              </div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark mb-2 text-custom-res">
                {place}
              </p>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res">
                Mobile Phone
              </p>
              <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Inprocess-text">
                In Process
              </p>
            </div>
          </div>
          <div
            className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 cp position-relative"
            onClick={() => handleUploadHandler("view")}
          >
            <div className="prod-list-box">
              <div className="cust-btn d-flex ac-jc rounded-3">
                <img src={product} alt="" className="custom-w-h rounded-3" />
              </div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark mb-2 text-custom-res">
                {place}
              </p>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res">
                Mobile Phone
              </p>
              <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 request-text">
                Request
              </p>
            </div>
          </div>
          <div
            className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 cp position-relative"
            onClick={() => handleUploadHandler("view")}
          >
            <div className="prod-list-box">
              <div className="cust-btn d-flex ac-jc rounded-3">
                <img src={product} alt="" className="custom-w-h rounded-3" />
              </div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark mb-2 text-custom-res">
                {place}
              </p>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res">
                Mobile Phone
              </p>
              <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Accept-text">
                Accepted
              </p>
            </div>
          </div>
          <div
            className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 cp position-relative"
            onClick={() => handleUploadHandler("view")}
          >
            <div className="prod-list-box">
              <div className="cust-btn d-flex ac-jc rounded-3">
                <img src={product} alt="" className="custom-w-h rounded-3" />
              </div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark mb-2 text-custom-res">
                {place}
              </p>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res">
                Mobile Phone
              </p>
              <div className="d-flex">
                <p className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 mt-2 parag mb-0 Regected-text">
                  Rejected
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        Product Rejected reason will show here
                      </Tooltip>
                    )}
                    placement="bottom"
                  >
                    <InfoOutlinedIcon
                      className="fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-1 parag"
                      style={{ color: "#06679c" }}
                    />
                  </OverlayTrigger>
                </p>
              </div>
            </div>
            <div className="d-flex mt-4 ms-1 position-absolute top-0 end-0 px-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProductSuggestion;
