import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

const AddNotes = ({ setNote, type, setOpenNotes }) => {
  const [reminder, setReminder] = useState(false);
  const [date, setDate] = useState(new Date());
  const [dateTime, setDateTime] = useState(new Date());
  const [dateTime2, setDateTime2] = useState(new Date());
  const fileRef = useRef();
  const [imgList, setImgList] = useState("");
  const [thread, setThread] = useState("");
  console.log(thread);
  const navigate = useNavigate();
  return (
    <div className="w-100 mt-3">
      <textarea
        placeholder="Add a note, a call or a meeting..."
        className="rounded-3 editBtn w-100 p-2"
      />
      <div className="w-100">
        {type === "thread" ||
          (type === "profile" && (
            <div className="d-flex ac-jb w-55">
              <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Type
              </p>
              <div className="d-flex w-75 ms-3">
                <select
                  onChange={(e) => setThread(e.target.value)}
                  className="cust-btn w-100 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 black"
                >
                  <option value="Note">Note</option>
                  <option value="Call">Call</option>
                  <option value="Meeting">Meeting</option>
                </select>
              </div>
            </div>
          ))}
        <div className="w-100 mt-3 d-flex ac-jb">
          <div className="d-flex ac-jb w-55">
            <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Contacts
            </p>
            <div className="d-flex w-75 ms-3">
              <select className="cust-btn w-100 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 black">
                <option value="10">None</option>
                <option value="10">Sakthi Exe</option>
              </select>
            </div>
          </div>

          <div className="d-flex ac-jc">
            <div
              onClick={() => setReminder(!reminder)}
              className="d-flex ac-jc pointerView"
            >
              <AccessAlarmIcon className="primary f2 fs-xs-19 fs-sm-20 fs-md-21 fs-lg-22 fs-xl-23 fs-xxl-24" />
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Reminder
              </p>
            </div>
            <div
              onClick={() => fileRef.current.click()}
              className="d-flex ac-jc pointerView"
            >
              <NoteAddIcon className="primary ms-2 f2 fs-xs-19 fs-sm-20 fs-md-21 fs-lg-22 fs-xl-23 fs-xxl-24" />
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                File
              </p>
              <input
                type="file"
                onChange={(e) => {
                  setImgList(e.target.files[0]);
                }}
                className="d-none"
                ref={fileRef}
              />
            </div>
          </div>
        </div>
        {(type === "note" || type === "call" || type === "meet") &&
          reminder && (
            <div className="d-flex mt-3 ac-jb w-55">
              <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Remind
              </p>
              <div className="d-flex w-75 ms-3">
                <select className="cust-btn w-100 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 black">
                  <option value="10">None</option>
                  <option value="10">Roger Daniel</option>
                </select>
              </div>
            </div>
          )}
        {(type === "note" || type === "call" || type === "meet") &&
          reminder && (
            <div className="d-flex ac-jb w-55">
              <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Reminder
              </p>
              <div className="d-flex w-75 mt-2 ms-3">
                <ReactDatePicker
                  className="editBtn rounded-3 w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14"
                  onChange={(date1) => setDateTime(date1)}
                  showTimeSelect
                  selected={dateTime}
                  dateFormat="Pp"
                />
              </div>
            </div>
          )}
        {type === "thread" ||
          (type === "profile" && (
            <div className="d-flex ac-jb w-55">
              <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Reminder
              </p>
              <div className="d-flex w-75 mt-2 ms-3">
                <ReactDatePicker
                  className="editBtn rounded-3 w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14"
                  onChange={(date1) => setDate(date1)}
                  selected={date}
                />
              </div>
            </div>
          ))}
        {type === "call" && reminder && (
          <div className="d-flex ac-jb w-55">
            <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Call Date
            </p>
            <div className="d-flex w-75 mt-2 ms-3">
              <ReactDatePicker
                className="editBtn rounded-3 w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14"
                onChange={(date1) => setDateTime2(date1)}
                showTimeSelect
                selected={dateTime2}
                dateFormat="Pp"
              />
            </div>
          </div>
        )}
        {(type === "thread" || type === "profile") && thread === "Call" && (
          <div className="d-flex ac-jb w-55">
            <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Call Date
            </p>
            <div className="d-flex w-75 mt-2 ms-3">
              <ReactDatePicker
                className="editBtn rounded-3 w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14"
                onChange={(date1) => setDateTime(date1)}
                showTimeSelect
                selected={dateTime}
                dateFormat="Pp"
              />
            </div>
          </div>
        )}
        {type === "meet" && reminder && (
          <div className="d-flex ac-jb w-55">
            <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Meeting Date
            </p>
            <div className="d-flex w-75 mt-2 ms-3">
              <ReactDatePicker
                className="editBtn rounded-3 w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14"
                onChange={(date1) => setDateTime2(date1)}
                showTimeSelect
                selected={dateTime2}
                dateFormat="Pp"
              />
            </div>
          </div>
        )}
        {(type === "thread" || type === "profile") && thread === "Meeting" && (
          <div className="d-flex ac-jb w-55">
            <p className="w-35 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              Meeting Date
            </p>
            <div className="d-flex w-75 mt-2 ms-3">
              <ReactDatePicker
                className="editBtn rounded-3 w-100 f2 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14"
                onChange={(date1) => setDateTime(date1)}
                showTimeSelect
                selected={dateTime}
                dateFormat="Pp"
              />
            </div>
          </div>
        )}
        {imgList?.length !== "" && (
          <div className="d-flex ac-jb mt-2 w-100">
            <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
              {imgList?.name}
            </p>
          </div>
        )}
      </div>

      <div className="w-100 d-flex ac-jb">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
          Post
        </button>
        {type !== "thread" && type !== "profile" && (
          <button
            onClick={() => setNote(false)}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Cancel
          </button>
        )}
        {type === "thread" && (
          <button
            onClick={() => navigate(-1)}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Back
          </button>
        )}
        {type === "profile" && (
          <button
            onClick={() => setOpenNotes(false)}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  );
};

export default AddNotes;
