import React, { createRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";

const ImageCroper = ({ toggleImagePopup, setCropImage }) => {
  const [images, setImages] = useState("");
  const [cropData, setCropData] = useState("");
  const cropperRef = createRef();
  const onChange = (e) => {
    e.preventDefault();
    let files;
    // console.log(e);
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImages(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    }
  };

  const handleSubmit = () => {
    setCropImage(cropData);
    toggleImagePopup();
  };
  return (
    <div className="image-popup">
      <div>
        <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
          Image Crop
          <span>
            <button
              onClick={() => {
                toggleImagePopup();
                setCropImage("");
              }}
              className="cust-btn"
            >
              <HighlightOffIcon />
            </button>
          </span>
        </p>
        <div style={{ overflow: "scroll", height: "70vh" }}>
          <div className="mt-3">
            <div className="w-100">
              <input
                type="file"
                onChange={onChange}
                className="mb-3"
                accept="image/png, image/jpeg"
              />
              <Cropper
                ref={cropperRef}
                style={
                  images ? { height: "80%", width: "80%" } : { display: "none" }
                }
                className=""
                zoomTo={0.5}
                preview=".img-preview"
                src={images}
                initialAspectRatio={9 / 9}
                guides={false}
                viewMode={1}
                minCropBoxHeight={100}
                minCropBoxWidth={100}
                responsive={true}
                autoCropArea={1}
                aspectRatio={4 / 4}
                checkOrientation={false}
              />
            </div>
            <div>
              <div className="mt-3">
                {images && (
                  <p className="d-flex justify-content-end mx-3">
                    <button
                      className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                      style={{ width: "20%" }}
                      onClick={getCropData}
                    >
                      Crop Image
                    </button>
                  </p>
                )}
                {cropData && (
                  <img
                    style={{ width: "250px" }}
                    src={cropData}
                    alt="cropped"
                  />
                )}
              </div>
            </div>
          </div>
          {/* <button className="cust-btn btn-style bg-sec">Save</button> */}
        </div>
        <div className="d-flex mt-3 justify-content-end m-2 ">
          <button
            onClick={handleSubmit}
            className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCroper;
