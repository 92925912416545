import { useNavigate } from "react-router-dom";
import { editIcon, toggleOff, toggleOn, viewIcon } from "../../assets/img";
import { customerDecoList } from "../../redux/api/DummyJson";

const OrderListComp = () => {
  const navigate = useNavigate();

  return (
    <div className="overflow-scroll mt-4">
      <div className="wholeDesignCustomerDeco d-flex mt-4 border-bottom">
        <p className="w-5 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          S.no
        </p>
        <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Customer ID
        </p>
        <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Order ID
        </p>
        <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Order Type
        </p>
        <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Number of Items
        </p>
        <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Total Price
        </p>
        <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Order Status
        </p>
        <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Status
        </p>
        <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Options
        </p>
        {/* <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Product quantity and pack size
          </p>
          <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Number of logos
          </p>
          <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Price
          </p>
          <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Reference logo & count
          </p>
          <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
            Contact details
          </p> */}
      </div>
      {customerDecoList?.map((item) => {
        return (
          <div className="wholeDesignCustomerDeco d-flex mt-5 pb-2">
            <p className="w-5 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
              {item?.slNo}
            </p>
            <p className="pointerView w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
              {item?.orderId}
            </p>
            <p className="pointerView w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
              {item?.orderId}
            </p>
            <div className="w-15 pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
              <select
                placeholder=""
                className="w-90 editBtnSelect f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3 mt-2"
                name=""
                id=""
              >
                <option>Preset</option>
                <option>Bulk</option>
                <option>Individual pack</option>
              </select>
            </div>
            <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
              5
            </p>
            <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
              100
            </p>
            <div className="w-15 pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
              <select
                placeholder=""
                className="w-90 primary1 editBtnSelect f3 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 rounded-3"
                name=""
                id=""
              >
                <option>Paid</option>
                <option>Operation</option>
                <option>Shipping Ready</option>
                <option>Shipment</option>
                <option>Delivered</option>
              </select>
            </div>
            <div className="w-10 d-flex ac-jc">
              {item?.status === true && (
                <div>
                  <img src={toggleOn} className="toggleOnDes" />
                </div>
              )}
              {item?.status === false && (
                <div>
                  <img src={toggleOff} className="toggleOnDes" />
                </div>
              )}
            </div>
            <div className="w-15 d-flex ac-jc primary1">
              <div
                onClick={() =>
                  navigate("/view-order-details", {
                    state: { data: item },
                  })
                }
                className="viewBoxDes pointerView p-2 bg-white rounded-2"
              >
                <img src={viewIcon} className="viewDes" />
              </div>
              <div
                onClick={() =>
                  navigate("/view-order-details", {
                    state: { type: "edit", data: item },
                  })
                }
                className="viewBoxDes pointerView ms-2 p-2 bg-white rounded-2"
              >
                <img src={editIcon} className="viewDes" />
              </div>
            </div>
            {/* <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                {item?.qtySize}
              </p>
              <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                {item?.NoOfLog}
              </p>
              <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                {item?.price}
              </p>
              <div className="w-10 d-flex ac-jc">
                <img src={item?.img} className="imgDashboard" />
              </div>
              <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 d-flex ac-jc">
                {item?.ContactDetails}
              </p> */}
          </div>
        );
      })}
    </div>
  );
};

export default OrderListComp;
