import MyProductListComp from "../ProductComp/MyProductListComp";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DropdownTap from "../ProductComp/DropdownTap";
import UploadIcon from "@mui/icons-material/Upload";
import MapProductListComp from "../ProductComp/MapProductListComp";
import RequestListComp from "../ProductComp/RequestListComp";
import NewProductSuggestion from "../ProductComp/NewProductSuggestion";
import UploadImages from "../ProductComp/UploadImages";
import GetAppIcon from "@mui/icons-material/GetApp";

const ProductDetails = ({ place, path, name }) => {
  const navigate = useNavigate();

  const [product, setProduct] = useState(false);
  const [decorative, setDecorative] = useState(false);
  const [newProductsSuggestions, SetNewProductsSuggestions] = useState(false);
  const [myProducts, setMyProducts] = useState(true);
  const [productList, setParoductList] = useState("");
  const [uploadPopUp, setUploadPopUp] = useState(false);
  const [types, setTypes] = useState("");

  // console.log(path, 'path')

  // useEffect(() => {
  //   if (path === '/vendor-details') {
  //     setDecorative(true)
  //     setProduct(false)
  //     setMyProducts(false)
  //   }
  // }, []);

  //   popUp upload function
  const handleUploadHandler = (view) => {
    setUploadPopUp(!uploadPopUp);
    setTypes(view);
  };

  return (
    <div className="w-100">
      <div className="d-flex mt-5 ac-jb flex-wrap">
        <div className="d-flex mt-3 rk">
          <button
            onClick={() => {
              setMyProducts(true);
              setDecorative(false);
              setProduct(false);
              SetNewProductsSuggestions(false);
              setParoductList("myProducts");
            }}
            style={{ cursor: "pointer" }}
            className={`${
              myProducts
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                myProducts ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Approved Products
            </p>
          </button>
          <button
            onClick={() => {
              setProduct(true);
              setDecorative(false);
              SetNewProductsSuggestions(false);
              setParoductList("product");
              setMyProducts(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              product
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                product ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Map Products
            </p>
          </button>
          <button
            onClick={() => {
              setProduct(false);
              setDecorative(true);
              setMyProducts(false);
              SetNewProductsSuggestions(false);
              setParoductList("decorative");
            }}
            style={{ cursor: "pointer" }}
            className={`${
              decorative
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                decorative ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              Add New Products
            </p>
          </button>
          <button
            onClick={() => {
              setProduct(false);
              setDecorative(false);
              SetNewProductsSuggestions(true);
              setMyProducts(false);
              setParoductList("decorative");
            }}
            style={{ cursor: "pointer" }}
            className={`${
              newProductsSuggestions
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar cust-tab-style"
                : ""
            }  pointerView cust-btn px-4 py-2 tranc`}
          >
            <p
              className={`${
                newProductsSuggestions ? "text-light" : "primary"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
            >
              New Products Suggestions
            </p>
          </button>
        </div>
        <div>
          {((path === "/vendor-details" && product) || product) && (
            <button className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              <GetAppIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
              Export
            </button>
          )}
          {/* {(path === '/map-product-vendor-details' && product || product) &&
            <button
              className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
              Import
            </button>
          } */}
          {((path === "/vendor-details" && product) || product) && (
            <button
              onClick={() => navigate("/map-product")}
              className="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Map Product
            </button>
          )}

          {/* {(path === '/vendor-details' && decorative || decorative) && <button
            className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            Sample Download
          </button>}
          {(path === '/vendor-details' && decorative || decorative) && <button
            className="cust-btn addbtn2 mt-sm-3 mx-3 mt-2 gray f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            <UploadIcon className="primary f1 fs-xs-14 fs-sm-17 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 me-2" />
            Import
          </button>} */}

          {((path === "/vendor-details" && decorative) || decorative) && (
            <button
              onClick={() => navigate("/create-new-request")}
              className="cust-btn addbtn mt-sm-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Create New Request
            </button>
          )}

          {((path === "/vendor-details" && newProductsSuggestions) ||
            newProductsSuggestions) && (
            <button
              onClick={() => handleUploadHandler()}
              className="cust-btn addbtn mt-sm-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
            >
              Upload Image
            </button>
          )}
        </div>
      </div>
      {/* {!myProducts && <DropdownTap path={path} />} */}

      {myProducts && <MyProductListComp name={name} />}
      {product && <MapProductListComp name={name} />}
      {decorative && <RequestListComp name={name} />}
      {newProductsSuggestions && (
        <NewProductSuggestion
          place={name}
          handleUploadHandler={handleUploadHandler}
        />
      )}
      {/* <RequestListComp place={place} path={path} setProduct={product} setDecorative={decorative} myProducts={myProducts} /> */}
      {uploadPopUp && (
        <UploadImages
          onUploadHandler={handleUploadHandler}
          types={types}
          setTypes={setTypes}
        />
      )}
    </div>
  );
};

export default ProductDetails;
