import React, { useState } from "react";
import { CommentOutlined, Edit } from "@mui/icons-material";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { event_category } from "../../../redux/api/DummyJson";
import { useLocation } from "react-router-dom";

const PoAccept = ({ onShowPopupHander, toggleShowPopup }) => {
  const location = useLocation();
  const methodType = location?.state?.type;
  const [productqty, setProductQty] = useState(500);
  const [edit, setEdit] = useState(true);
  const [statust, setStatust] = useState("");

  const data = [
    {
      id: 1,
      pro_id: "Pro_001",
      company: "Ninos Enterprises",
      sales_id: "SO-001 Ninos",
      sku_code: "SKU-001",
      project: "T-Shirt",
      attributes: "XL/BLACK",
      pro_qty: "600",
      final_sku: "Ninos-SKU001",
      amount: "30000",
      reci_qty: "500",
      status: "Based on GRN",
      actual_date: "2024-12-12",
      date_pay: "2024-12-12",
      payment_status: 1,
      mathod: "Screen Pending",
    },
    {
      id: 2,
      pro_id: "Pro_001",
      company: "Ninos Enterprises",
      sales_id: "SO-001 Ninos",
      sku_code: "SKU-002",
      project: "Bottle",
      attributes: "-",
      pro_qty: "2000",
      final_sku: "ECPHASIS-SKU002",
      amount: "100000",
      reci_qty: "",
      status: "-",
      actual_date: "2024-12-12",
      date_pay: "2024-12-12",
      payment_status: 1,
      mathod: "Screen Pending",
    },
    {
      id: 3,
      pro_id: "Pro_003",
      company: "Krishna Brand",
      sales_id: "SO-003 Krishna",
      sku_code: "SKU-001",
      project: "T-Shirt",
      attributes: "L/BLACK",
      pro_qty: "100",
      final_sku: "ECPHASIS-SKU002",
      amount: "20000",
      reci_qty: "",
      status: "-",
      actual_date: "2024-12-12",
      date_pay: "2024-12-12",
      payment_status: 1,
      mathod: "Laser Pending",
    },
    {
      id: 4,
      pro_id: "Pro_004",
      company: "5SK",
      sales_id: "SO-004 Sakthi",
      sku_code: "SKU-001",
      project: "T-Shirt",
      attributes: "M/BLACK",
      pro_qty: "200",
      final_sku: "Ninos-SKU001",
      amount: "15000",
      reci_qty: "",
      status: "-",
      actual_date: "2024-12-12",
      date_pay: "2024-12-12",
      payment_status: 1,
      mathod: "Laser Pending",
    },
  ];

  return (
    <>
      <div className="w-100 overflow-scroll mt-4">
        <table className="w-100">
          <tr>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                S.No
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Project ID and Name
              </p>
            </th>{" "}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Sales Order Id
              </p>
            </th>{" "}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                SKU Code
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Product Name
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Attributes
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Total req Quantity
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Received Qty
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Balance Qty
              </p>
            </th>
            {/* <th>
          <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
            Total
          </p>
        </th> */}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Status Of Production
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Issue Detail
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Shipping
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Actual Date Of Delivery
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Date Of Payment
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Final SKU
              </p>
            </th>
            {methodType == "decorative" && (
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Decorative Method
                </p>
              </th>
            )}
            {/* <th>
          <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
            Branded Qty
          </p>
        </th> */}
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Amount
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Payment Status
              </p>
            </th>
            <th>
              <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                Proof
              </p>
            </th>
          </tr>
          {data?.map((item, ind) => {
            return (
              <tr className="my-3">
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {ind + 1}
                  </p>
                </th>
                <th>
                  <div className="d-flex flex-column">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 ">
                      {item?.pro_id}
                    </p>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.company}
                    </p>
                  </div>
                </th>{" "}
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.sales_id}
                  </p>
                </th>{" "}
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.sku_code}
                  </p>
                </th>
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.project}
                  </p>
                </th>
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    {item?.attributes}
                  </p>
                </th>
                <th>
                  <div className="w-100 text-center bg-transparent border-1 pb-4">
                    <input
                      placeholder=""
                      value={item?.pro_qty}
                      className="datepicker-input w-50 viewBoxDes ms-2 p-2 bg-white rounded-2 border-0 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 me-2"
                    />
                  </div>
                </th>
                <th>
                  <div className="d-flex ac-jc">
                    <input
                      placeholder=""
                      value={item?.reci_qty}
                      className="datepicker-input w-50 viewBoxDes ms-2 p-2 bg-white rounded-2 border-0 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 me-2"
                    />
                  </div>
                </th>
                <th>
                  <input
                    placeholder=""
                    value={""}
                    className="datepicker-input w-100 viewBoxDes ms-2 p-2 bg-white rounded-2 border-0 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 me-2"
                  />
                </th>
                <th>
                  <button className="w-100 text-center bg-transparent border-0">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.status}
                    </p>
                  </button>
                </th>
                <th>
                  <button className="w-100 text-center bg-transparent border-0">
                    <p
                      className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 pb-4"
                      onClick={() => onShowPopupHander()}
                    >
                      <CommentOutlined />
                    </p>
                  </button>
                </th>
                <th>
                  <button className="w-100 text-center bg-transparent border-0">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 pb-4">
                      <ShoppingCartCheckoutOutlinedIcon />
                    </p>
                  </button>
                </th>
                <th>
                  <div className="w-100 text-center bg-transparent border-1 pb-4 d-flex align-items-center flex-nowrap">
                    <input
                      type="date"
                      value={item?.actual_date}
                      class="datepicker-input viewBoxDes ms-2 p-2 bg-white rounded-2 border-0 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 me-2"
                    />
                    {/* <CalendarTodayOutlinedIcon className="f3 fs-13 fs-lg-17 fs-xl-18 fs-xxl-19 text-dark" /> */}
                  </div>
                </th>
                <th>
                  <div className="w-100 text-center bg-transparent border-1 pb-4 d-flex flex-nowrap align-items-center">
                    <input
                      type="date"
                      value={item?.date_pay}
                      class="datepicker-input viewBoxDes ms-2 p-2 bg-white rounded-2 border-0 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 me-2"
                    />
                    {/* <CalendarTodayOutlinedIcon className="f3 fs-13 fs-lg-17 fs-xl-18 fs-xxl-19 text-dark" /> */}
                  </div>
                </th>
                <th>
                  <button className="w-100 text-center bg-transparent border-0">
                    <p className=" f2 fs-xs-11 text-nowrap fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.final_sku}
                    </p>
                  </button>
                </th>
                {methodType == "decorative" && (
                  <th>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.mathod}
                    </p>
                  </th>
                )}
                {/* <th>
              <button className="w-100 text-center bg-transparent border-0">
                <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                  -
                </p>
              </button>
            </th> */}
                <th>
                  <button className="w-100 text-center bg-transparent border-0">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.amount}
                    </p>
                  </button>
                </th>
                <th>
                  <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                    <select
                      placeholder=""
                      className="w-100 editBtnSelect f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3 mt-2"
                      name=""
                      id=""
                      value={item?.payment_status}
                    >
                      <option value={1}>Amount Yet Received</option>
                      <option>Amount Yet Received</option>
                      <option>Individual pack</option>
                      <option>Ecommerce</option>
                    </select>
                  </p>
                </th>
                <th>
                  <button className="w-100 text-center bg-transparent border-0">
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 pb-4 d-flex gap-1">
                      <p role={"button"} onClick={toggleShowPopup}>
                        {" "}
                        <AttachFileOutlinedIcon />
                      </p>
                      <p role={"button"} onClick={onShowPopupHander}>
                        <CommentOutlined />
                      </p>
                    </p>
                  </button>
                </th>
              </tr>
            );
          })}
        </table>
      </div>
      <div className="d-flex ac-jb w-100 mt-3">
        <button class="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
          Previous
        </button>
        <button class="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
          Next
        </button>
      </div>
    </>
  );
};

export default PoAccept;
