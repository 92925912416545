import { configureStore } from "@reduxjs/toolkit";
import { rtkQueryErrorLogger } from "../slice/errorLogger";
import { api } from "../api/api";
import alertMessage from "../slice/alertMessage";
import loginHeader from "../slice/loginHeader";
import vendarnaviReducer from "../slice/NavigationSlice/vendarSlice";
import loaderNoti from "../slice/loaderNoti";
//

const store = configureStore({
  reducer: {
    alertMessage: alertMessage,
    loginHeader: loginHeader,
    loaderNoti: loaderNoti,
    [api.reducerPath]: api.reducer,
    navigateSlice: vendarnaviReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, rtkQueryErrorLogger),
});

export default store;
