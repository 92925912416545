import React, { useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { toggleOn } from "../assets/img";
import OverViewComp from "../components/ProductDetailComp/OverViewComp";
import VendorPricingComp from "../components/ProductDetailComp/VendorPricingComp";
import { useLocation, useNavigate } from "react-router-dom";
import PricingBoxComp from "../components/MyProductComp/PricingBoxComp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const ProductDetailScreen = () => {
  const navigat = useNavigate();
  const [priceBook, setpriceBook] = useState(true);
  const [previousOrder, setPreviousOrder] = useState(false);
  const [tagged, setTagged] = useState(false);
  const [edit, setEdit] = useState(false);

  const location = useLocation();
  console.log(location?.state?.type);
  const types = location?.state?.type;
  const name = location?.state?.name;
  console.log(name);

  return (
    <div className="dashRightView overflow-scroll">
      <ProductSearchHeader />
      <div className="w-100 top_head_cust py-3">
        <h5 className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary">
          {name}
        </h5>
      </div>
      <div className="d-flex mt-2 ac-jb flex-column flex-md-row w-100">
        <div className="d-flex mt-3 ac-jc">
          <div
            onClick={() => {
              setpriceBook(true);
              setTagged(false);
              setPreviousOrder(false);
            }}
            style={{ cursor: "pointer" }}
            className={`${
              priceBook
                ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                : ""
            } ms-4`}
          >
            <p
              className={`${
                priceBook ? "black" : "gray"
              } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
              role={"button"}
              onClick={() => navigat(-1)}
            >
              <KeyboardBackspaceIcon /> Overview
            </p>
          </div>
          {/* <div
                        onClick={() => {
                            setPreviousOrder(true);
                            setpriceBook(false);
                            setTagged(false)
                        }}
                        style={{ cursor: 'pointer' }}
                        className={`${previousOrder
                            ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                            : ""
                            } ms-4`}
                    >
                        <p
                            className={`${previousOrder ? "black" : "gray"
                                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
                        >
                            Pricing
                        </p>
                    </div> */}
          {/* <div
                        onClick={() => {
                            setTagged(true);
                            setpriceBook(false);
                            setPreviousOrder(false)
                        }}
                        style={{ cursor: 'pointer' }}
                        className={`${tagged
                            ? "border-bottom-1 border-top-0 border-start-0 border-end-0 border-primar"
                            : ""
                            } ms-4`}
                    >
                        <p
                            className={`${tagged ? "black" : "gray"
                                } f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16`}
                        >
                            Details
                        </p>
                    </div> */}
          {previousOrder && (
            <div className="ms-5">
              <button
                className={
                  edit
                    ? "cust_two_E cust-btn f3 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18"
                    : "cust_two_S cust-btn f3 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18"
                }
                onClick={() => setEdit(!edit)}
              >
                {edit ? "Submit" : "Edit"}
              </button>
            </div>
          )}
        </div>
        <div className="mt-md-4 mt-3">
          <h5 className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
            Currency :
            <span className="primary f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25">
              INR
            </span>
          </h5>
        </div>
      </div>
      {priceBook && <OverViewComp types={types} />}
      {/* {previousOrder && */}
      {/* <VendorPricingComp
        types={types}
        edit={edit}
        setpriceBook={setpriceBook}
        setPreviousOrder={setPreviousOrder}
      /> */}

      <PricingBoxComp
        types={types}
        edit={edit}
        setEdit={setEdit}
        setpriceBook={setpriceBook}
        setPreviousOrder={setPreviousOrder}
      />

      <div className="d-flex ac-jb w-10">
        <button
          className="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() => navigat(-1)}
        >
          Back
        </button>

        <button
          className="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
          onClick={() => navigat(-1)}
        >
          Submit
        </button>
      </div>

      {/* } */}
      <div className="w-100 mt-5 d-flex ae-js">
        <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
          Last updated on August 28, 2023 at 03:10 AM{" "}
        </p>
      </div>
    </div>
  );
};

export default ProductDetailScreen;
