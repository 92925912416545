import React from "react";

const LogoutPopup = ({ onConfirmClick, onCancelClick }) => {
  return (
    <div onClick={onCancelClick} className="popup-wrap_new">
      <div className="popup-innerbox_new">
        <p>Are you sure you want to logout?</p>
        <div>
          <button onClick={onConfirmClick}>Confirm</button>
          <button onClick={onCancelClick}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default LogoutPopup;
