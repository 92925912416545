import React, { useState } from "react";
import { animatedImg, product, toggleOff, toggleOn } from "../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import CachedIcon from "@mui/icons-material/Cached";
import { Search } from "@mui/icons-material";

const DecorativeList = ({ loc, setDecorative, setProduct, place, path }) => {
  const navigate = useNavigate();
  console.log(loc, "lshfidjbghj");
  console.log(place, "sankari");
  const location = useLocation();
  console.log(location?.pathname, "/reqest-detail");
  const path_line = location?.pathname;
  // < div className = 'btn-w-cust-cont mt-4' >
  //     <button className="btn-w-cust cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded w-100" onClick={() => { navigate('/decorative-vendor', { state: { form: vendor3 } }) }}>Save</button>
  //         </div >

  return (
    <>
      <div className="mt-5 banner-left">
        <div className="d-flex ac-jb flex-column flex-md-row">
          <div className="d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
            <input
              type="text"
              className="get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4 px-3 w-auto f3 fs-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-auto"
              placeholder={"Search Decoration Name"}
            />
          </div>
          <div className="d-flex ac-jc gap-3">
            <button
              // onClick={() => navigate("/add-vendor")}
              className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
            >
              <Search />
            </button>
            <button
              // onClick={() => navigate("/add-vendor")}
              className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
            >
              <CachedIcon />
            </button>
          </div>
        </div>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4 ">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex justify-content-end w-100">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
            Total Count : 4
          </p>
        </div>
      </div>
      <div className="w-100 d-flex ac-js flex-wrap mt-5 ">
        <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() =>
            navigate("/decorative-detail", { state: { type: "decorative" } })
          }
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={animatedImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              5SK Suppliers
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Game consoles
            </p>
            <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 mt-2">
              Status
              <span>
                <img src={toggleOn} className="toggleOnDes" alt="icon" />
              </span>
            </p>
            {/* <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">#5SK 001</p> */}
          </div>
        </div>
        <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() =>
            navigate("/decorative-detail", { state: { type: "decorative" } })
          }
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={animatedImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              5SK Suppliers
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Game consoles
            </p>
            <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 mt-2">
              Status
              <span>
                <img src={toggleOff} className="toggleOnDes" alt="icon" />
              </span>
            </p>
            {/* <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">#5SK 001</p> */}
          </div>
        </div>
        <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() =>
            navigate("/decorative-detail", { state: { type: "decorative" } })
          }
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={animatedImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              5SK Suppliers
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Game consoles
            </p>
            <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 mt-2">
              Status
              <span>
                <img src={toggleOn} className="toggleOnDes" alt="icon" />
              </span>
            </p>
            {/* <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">#5SK 001</p> */}
          </div>
        </div>
        <div
          className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2"
          onClick={() =>
            navigate("/decorative-detail", { state: { type: "decorative" } })
          }
        >
          <div className="prod-list-box">
            <div className="cust-btn d-flex ac-jc rounded-3">
              <img src={animatedImg} alt="" className="custom-w-h rounded-3" />
            </div>
            <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-dark mt-3 mb-2">
              5SK Suppliers
            </p>
            <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">
              Game consoles
            </p>
            <p className="d-flex ac-jb f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0 mt-2">
              Status
              <span>
                <img src={toggleOff} className="toggleOnDes" alt="icon" />
              </span>
            </p>
            {/* <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">#5SK 001</p> */}
          </div>
        </div>
      </div>{" "}
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </>
  );
};

export default DecorativeList;
