import React, { useState } from "react";

const ChartBar = ({ height, type }) => {
  const [height1, setHeight1] = useState(height);

  return (
    <div className="">
      <div className="bgColorView1">
        {type !== "static" && (
          <div
            className={`${height1 < 40 ? "bgColorView3" : "bgColorView2"}`}
            style={{ height: height1 }}
          />
        )}
        {type === "static" && (
          <div
            className={`${height1 < 40 ? "bgColorView3" : "bgColorView3"}`}
            style={{ height: height1 }}
          />
        )}
      </div>
    </div>
  );
};

export default ChartBar;
