import DeleteIcon from "@mui/icons-material/Delete";
import EventNoteIcon from "@mui/icons-material/EventNote";
import CallIcon from "@mui/icons-material/Call";
import GroupsIcon from "@mui/icons-material/Groups";
import { animatedImg, empty_profile } from "../../assets/img";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import LinkIcon from "@mui/icons-material/Link";
import {
  line_business,
  payment_Method,
  replyData,
  sub_menu,
  vendor_category,
} from "../../redux/api/DummyJson";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import AddContacts from "./AddContacts";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AddNotes from "./AddNotes";
import { useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageCroper from "../Popup/ImageCroper";

const ProfileDetails = ({
  setEditProfile,
  setProfile,
  setAddressPopup,
  setContactPopup,
  setHistory,
  path,
  name,
  setPositionPopup,
}) => {
  const [reply, setReply] = useState(false);
  const [note, setNote] = useState(false);
  const [call, setCall] = useState(false);
  const [meeting, setMeeting] = useState(false);
  const [repIndex, setRepIndex] = useState("");
  const [openNotes, setOpenNotes] = useState(false);
  const [noteIndex, setNoteIndex] = useState("");
  const [vendorCatogory, setVendorCatogory] = useState("");
  const [lineBusiness, setLineBusiness] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const [croper, setCroper] = useState(false);
  const [croperImage, setCropImage] = useState();
  const [croperCol, setCroperCol] = useState(false);
  const [logo, setLogo] = useState();
  const [banner, setBanner] = useState();

  const dualToggele = (type) => {
    if (type == "logo") {
      setCroperCol(false);
      toggleImagePopup();
    } else {
      setCroperCol(true);
      toggleImagePopup();
    }
  };

  const toggleImagePopup = () => {
    setCroper(!croper);
  };

  useEffect(() => {
    if (croperCol && croperImage) {
      setBanner(croperImage);
      setCropImage("");
    } else if (!croperCol && croperImage) {
      setLogo(croperImage);
      setCropImage("");
    }
  }, [croperImage]);

  const navigate = useNavigate();

  const [addContact1, setAddContact1] = useState(false);
  const [addContact2, setAddContact2] = useState(false);
  const [addContact3, setAddContact3] = useState(false);
  const [addContact4, setAddContact4] = useState(false);
  const [addContact5, setAddContact5] = useState(false);
  const [addContact6, setAddContact6] = useState(false);
  const [addContact7, setAddContact7] = useState(false);
  const [addContact8, setAddContact8] = useState(false);
  const [addContact9, setAddContact9] = useState(false);
  const [addContact10, setAddContact10] = useState(false);
  const [addContact11, setAddContact11] = useState(false);
  const [vendorCatShow, setVendorCatShow] = useState(false);
  const [lineBusinessShow, setLineBusinessShow] = useState(false);
  const [paymentMethodShow, setPaymentMethodShow] = useState(false);

  const handleReply = (ind) => {
    if (repIndex === ind) {
      setReply(!reply);
    }
  };

  const handleNotes = (ind) => {
    if (noteIndex === ind) {
      setOpenNotes(!openNotes);
    }
  };

  const onClickVendorCat = (e) => {
    if (vendorCatogory.includes(e)) {
      const listtemp = vendorCatogory.indexOf(e);
      const list = [...vendorCatogory];
      list.splice(listtemp, 1);
      setVendorCatogory(list);
    } else {
      setVendorCatogory([...vendorCatogory, e]);
    }
  };
  const onClickLineBusiness = (e) => {
    if (lineBusiness.includes(e)) {
      const listtemp = lineBusiness.indexOf(e);
      const list = [...lineBusiness];
      list.splice(listtemp, 1);
      setLineBusiness(list);
    } else {
      setLineBusiness([...lineBusiness, e]);
    }
  };
  const onClickPaymentMethod = (e) => {
    if (paymentMethod.includes(e)) {
      const listtemp = paymentMethod.indexOf(e);
      const list = [...paymentMethod];
      list.splice(listtemp, 1);
      setPaymentMethod(list);
    } else {
      setPaymentMethod([...paymentMethod, e]);
    }
  };
  console.log(path);
  console.log(name);
  return (
    <div className="d-flex flex-md-row px-lg-4 flex-column flex-wrap w-100 mt-4">
      {croper && (
        <ImageCroper
          toggleImagePopup={toggleImagePopup}
          setCropImage={setCropImage}
        />
      )}
      <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100">
        <div className="w-100 mt-1 ac-jb d-flex">
          <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Vendor Details
          </p>

          {/* <button
            onClick={() => {
              setEditProfile(true);
              setProfile(false);
            }}
            className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Edit
          </button> */}
        </div>
        <div className="d-flex flex-wrap as-jb flex-m-r">
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Legal Entity Name*
            </p>
            <input
              placeholder=""
              type="text"
              disabled
              value="Ninos"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            />
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Trade Name
            </p>
            <input
              type="text"
              disabled
              placeholder=""
              value={name}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            />
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Vendor ID
            </p>
            <input
              placeholder=""
              value="VMHQ001"
              type="text"
              disabled
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            />
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Vendor Offering Type*
            </p>
            <input
              placeholder=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value={
                (path === "/vendor-details" && "Product") ||
                (path === "/decorative-vendor" && "Service") ||
                (name === "Jake Adams" && "both")
              }
              type="text"
              disabled
            />
          </div>

          {/* path === '/decorative-vendor' */}
          {/* {(path === '/vendor-details' || name === 'Jake Adams') && <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Vendor Category*
            </p>
            <div className="position-relative"
            >
              <input
                placeholder="Sellect Category"
                type='text'
                disabled
                value='Mobile, Bags,Bottle '
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              {vendorCatShow && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setVendorCatShow(false)}
                />
              )}
              <div
                className={`${vendorCatShow && "submenu_1 h-auto"
                  } submenu_cont_1 overflow-scroll z-3`}
              >
                {vendor_category?.map((item, ind) => {
                  return (
                    <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onClickVendorCat(item?.list)
                      }}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                      >
                        {item?.list}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                      >
                        {vendorCatogory.includes(item?.list) ? item?.check : item.uncheck}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>}
          {(path === '/decorative-vendor' || name === 'Jake Adams') && <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Decoration Method
            </p>
            <div className="position-relative"
            >
              <textarea
                placeholder="Sellect Category"
                type='text'
                disabled
                value='Household furniture,Mobile phones'
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              {vendorCatShow && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setVendorCatShow(false)}
                />
              )}
              <div
                className={`${vendorCatShow && "submenu_1 h-auto"
                  } submenu_cont_1 overflow-scroll z-3`}
              >
                {vendor_category?.map((item, ind) => {
                  return (
                    <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onClickVendorCat(item?.list)
                      }}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                      >
                        {item?.list}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                      >
                        {vendorCatogory.includes(item?.list) ? item?.check : item.uncheck}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>} */}
          {/* <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Line of Business*
            </p>
            <div className="position-relative"
            >
              <input
                placeholder="Sellect Category"
                type='text'
                value='Enterprise, MHQ, Market Place'
                disabled
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
            </div>
          </div> */}

          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Type of Business*
            </p>
            <input
              placeholder=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              name=""
              id=""
              type="text"
              disabled
              value="Proprietorship"
            />
          </div>
          {/* <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Tags
            </p>
            <input
              placeholder=""
              value=""
              disabled
              type="text"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            />
          </div> */}

          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Payment Terms
            </p>
            <input
              placeholder=""
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value="7 days"
              type="text"
            />
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Payment Method
            </p>
            <div className="position-relative">
              <input
                placeholder="Sellect Category"
                type="text"
                value="Bank Transfer, Card, UPI, Others"
                disabled
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
            </div>
          </div>

          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              GST Number
            </p>
            <input
              placeholder="Sellect Category"
              type="text"
              value="GST001"
              disabled
              className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            />
          </div>
          <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Currency*
            </p>
            <input
              placeholder=""
              type="text"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              value="INR"
              disabled
            />
          </div>

          {/* <div
            className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100"
            onClick={() => dualToggele("ban")}
          >
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Banner Image
            </p>
            <input
              style={{ cursor: "pointer" }}
              value={banner ? "Banner Uploaded Successfully" : "Browse or Drag a file"}
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div> */}

          <div
            className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100"
            onClick={() => dualToggele("logo")}
          >
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Logo Image
            </p>
            <input
              style={{ cursor: "pointer" }}
              value={
                logo ? "Logo Uploaded Successfully" : "Browse or Drag a file"
              }
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
          <div
            className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100"
            onClick={() => dualToggele("ban")}
          >
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Banner Image
            </p>
            <input
              style={{ cursor: "pointer" }}
              value={
                banner
                  ? "Banner Uploaded Successfully"
                  : "Browse or Drag a file"
              }
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>

          {/* <div className="d-flex flex-wrap w-100 mt-4 ac-jc">
            <div className={croperImage?.length > 0 ? 'w-100 d-flex ac-jc' : "w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 d-flex ac-jc "}>
              <div
                className="w-xl-48 my-2 w-lg-48 w-md-100 w-sm-100 w-xs-100"
                onClick={() => dualToggele("logo")}
              >
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Logo Image
                </p>
                <input
                  style={{ cursor: "pointer" }}
                  value={logo ? "Logo Uploaded Successfully" : "Browse or Drag a file"}
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                />
              </div>
              {croperImage?.length > 0 && <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 d-flex ac-jc">
                <div className="w-100 d-flex ac-je">
                  <img src={croperImage} className="object-fit-contain uploadImgLib" />
                </div>
              </div>}
            </div>
            <div className={croperImage?.length > 0 ? 'w-100 d-flex ac-jc' : "w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 d-flex ac-jc "}>
              <div className="w-100">
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                  <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                    Banner Image
                  </p>
                  <label className="w-100 mt-2 mb-3" onClick={() => dualToggele('banner')}>
                    <div className="py-1 w-100">
                      <div className="d-flex ac-jb w-100">
                        <p className="editBtn rounded-3 cp border-2 text-nowrap py-1 px-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">{croperImage?.length === 0 ? 'Browse or Drag a file' : 'Image Uploded'}</p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              {croperImage?.length > 0 && <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <div className="w-100 d-flex ac-jc">
                  <img src={croperImage} className="object-fit-contain uploadImgLib" />
                </div>
              </div>}
            </div>
          </div>
          <div className="w-100">
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Profile Description
            </p>
            <textarea
              placeholder=""
              value="write a short description of the company"
              type='text'
              disabled
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            />
          </div> */}
        </div>
        <div className="w-100 mt-5">
          <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Departments
          </p>
          <div className="pointerView w-100 mt-3 ac-jb d-flex">
            <p className="f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
              <PlayArrowIcon
                onClick={() => setAddContact1(!addContact1)}
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
                  addContact1 ? "arrowIcon" : ""
                } `}
              />
              Management <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact1 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}

          <div className="pointerView w-100 mt-3 ac-jb d-flex">
            <p className="f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
              <PlayArrowIcon
                onClick={() => setAddContact10(!addContact10)}
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
                  addContact10 ? "arrowIcon" : ""
                } `}
              />
              Marketing <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact10 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}

          <div className="pointerView w-100 mt-3 ac-jb d-flex">
            <p className="f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
              <PlayArrowIcon
                onClick={() => setAddContact11(!addContact11)}
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
                  addContact11 ? "arrowIcon" : ""
                } `}
              />
              Logistics <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact11 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}

          <div className="pointerView w-100 mt-1 ac-jb d-flex">
            <p className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
              <PlayArrowIcon
                onClick={() => setAddContact2(!addContact2)}
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
                  addContact2 ? "arrowIcon" : ""
                } `}
              />
              Design/Creative <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact2 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}
          <div className="pointerView w-100 mt-1 ac-jb d-flex">
            <p className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
              <PlayArrowIcon
                onClick={() => setAddContact3(!addContact3)}
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
                  addContact3 ? "arrowIcon" : ""
                } `}
              />
              Sales <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact3 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}
          <div className="pointerView w-100 mt-1 ac-jb d-flex">
            <p className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
              <PlayArrowIcon
                onClick={() => setAddContact4(!addContact4)}
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
                  addContact4 ? "arrowIcon" : ""
                } `}
              />
              Production <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact4 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}
          <div className="pointerView w-100 mt-1 ac-jb d-flex">
            <p className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
              <PlayArrowIcon
                onClick={() => setAddContact5(!addContact5)}
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
                  addContact5 ? "arrowIcon" : ""
                } `}
              />
              Customer Service <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact5 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}
          <div className="pointerView w-100 mt-1 ac-jb d-flex">
            <p className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
              <PlayArrowIcon
                onClick={() => setAddContact6(!addContact6)}
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
                  addContact6 ? "arrowIcon" : ""
                } `}
              />
              Accounting <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact6 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}
          <div className="pointerView w-100 mt-1 ac-jb d-flex">
            <p className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
              <PlayArrowIcon
                onClick={() => setAddContact7(!addContact7)}
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
                  addContact7 ? "arrowIcon" : ""
                } `}
              />
              Human Resources <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact7 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}
          <div className="pointerView w-100 mt-1 ac-jb d-flex">
            <p className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
              <PlayArrowIcon
                onClick={() => setAddContact8(!addContact8)}
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
                  addContact8 ? "arrowIcon" : ""
                } `}
              />
              Procurement <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact8 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}
          <div className="pointerView w-100 mt-1 ac-jb d-flex">
            <p className="f2 mt-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
              <PlayArrowIcon
                onClick={() => setAddContact9(!addContact9)}
                className={`black f2 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 mb-1 ${
                  addContact9 ? "arrowIcon" : ""
                } `}
              />
              Inactive Contacts <span className="primary">(1)</span>
            </p>
            <div onClick={() => setContactPopup(true)} className="pointerView ">
              <p className="pointerView f2 px-2 py-1 rounded-2 bg-soft-green mt-2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13 green1">
                Add Contact
              </p>
            </div>
          </div>
          {addContact9 && (
            <AddContacts
              setContactPopup={setContactPopup}
              setPositionPopup={setPositionPopup}
            />
          )}
        </div>
        <div className="w-100 mt-5">
          <div className="w-100 ac-jb d-flex">
            <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Addresses
            </p>
            <button
              onClick={() => setAddressPopup(true)}
              className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
            >
              Add
            </button>
          </div>
          <div className="w-95 mt-4">
            <div className="w-100 ac-jb d-flex">
              <p className="black f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                Address 1{" "}
                <span className="primary1 f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                  (Shipping)
                </span>
              </p>
              <DeleteIcon className="pointerView primary1 f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
            </div>
            <p className="primary1 mt-3 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
              No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai -
              600099, Tamil Nadu, India.
            </p>
          </div>
          <div className="w-95 mt-4">
            <div className="w-100 ac-jb d-flex">
              <p className="black f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                Address 2{" "}
                <span className="primary1 f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                  (Billing){" "}
                  <span className="gray f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mt-1 ">
                    Optional
                  </span>
                </span>
              </p>
              <DeleteIcon className="primary1 pointerView f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25" />
            </div>
            <p className="primary1 mt-3 f2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-16 mt-1 ">
              No. 40/61, Rajan nagar, 2nd street, Lakshmipuram, Chennai -
              600099, Tamil Nadu, India.
            </p>
          </div>
        </div>
      </div>

      <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100">
        <div className="w-100 replyPaddings mt-1">
          {!note && !call && !meeting && (
            <p className="f4 mt-3 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
              Hey Charles,
            </p>
          )}
          {!note && !call && !meeting && (
            <p className="gray f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Find below your recent feed:
            </p>
          )}
          {!note && !call && !meeting && (
            <div className="w-100 ac-jc d-flex">
              <div className="d-flex w-lg-90 w-md-60 w-sm-70 flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row ac-jb">
                <div
                  onClick={() => setNote(true)}
                  className="d-flex pointerView px-4 py-2 rounded-3 bg-soft-primary mt-3"
                >
                  <EventNoteIcon className="primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                  <p className="black f2 ms-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Note
                  </p>
                </div>
                <div
                  onClick={() => setCall(true)}
                  className="d-flex pointerView px-4 py-2 rounded-3 bg-soft-primary mt-3"
                >
                  <CallIcon className="primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                  <p className="black f2 ms-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Call
                  </p>
                </div>
                <div
                  onClick={() => setMeeting(true)}
                  className="d-flex pointerView px-3 py-2 rounded-3 bg-soft-primary mt-3"
                >
                  <GroupsIcon className="primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                  <p className="black f2 ms-1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Meeting
                  </p>
                </div>
              </div>
            </div>
          )}
          {note && <AddNotes type="note" setNote={setNote} />}
          {call && <AddNotes type="call" setNote={setCall} />}
          {meeting && <AddNotes type="meet" setNote={setMeeting} />}
          <div className="w-100 d-flex ac-je mt-5">
            <div className="d-flex flex-row">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                Show:
              </p>
              <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
                <option value="10">All</option>
              </select>
              <p className="f2 ms-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Time Period:
              </p>
              <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
                <option value="10">All</option>
              </select>
            </div>
          </div>
          {replyData?.map((item, index) => {
            return (
              <div className=" w-xl-100 w-lg-100 w-md-90 w-sm-100 w-xs-100 d-grid ac-jc mt-5">
                <div className="w-100 d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row ac-jc gap-3">
                  <div>
                    <img src={empty_profile} className="replyImg rounded-3" />
                  </div>
                  <div>
                    <p className="primary f3 fs-xs-10 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18">
                      {item?.name}{" "}
                      <span className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        Hourglass Essentials Pvt. Ltd.
                      </span>
                    </p>
                    <p className="primary1 f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      Roger added the vendor account{" "}
                      <span className="text-black f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                        {item?.referName}
                      </span>
                    </p>
                    <p className="primary1 f3 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Tagged in this post.
                    </p>
                  </div>
                </div>
                <div className="mt-3 w-xl-100 w-lg-100 w-xs-100 w-sm-100 d-flex ac-jb">
                  <div
                    onClick={() => {
                      handleReply(index);
                      setRepIndex(index);
                      setNoteIndex("");
                    }}
                    className="d-flex pointerView"
                  >
                    <ChatBubbleOutlineIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Reply
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      setNoteIndex(index);
                      handleNotes(index);
                      setRepIndex("");
                    }}
                    className="d-flex pointerView"
                  >
                    <NoteAddOutlinedIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Add Note
                    </p>
                  </div>
                  <div
                    onClick={() => navigate("/view-thread")}
                    className="d-flex pointerView"
                  >
                    <LinkIcon className="black linkImg f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Full thread
                    </p>
                  </div>
                  <div className="d-flex pointerView">
                    <DownloadIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Download
                    </p>
                  </div>
                  {/* <div className="d-flex pointerView">
                    <DeleteIcon className="black f2 fs-xs-12 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20" />
                    <p className="black f2 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                      Delete
                    </p>
                  </div> */}
                </div>
                <div className="mt-2">
                  <p className="primary f2 ms-1 fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 fs-xl-12 fs-xxl-13">
                    {item?.date}
                  </p>
                </div>
                {repIndex === index && reply && (
                  <div className="w-100 mt-2">
                    <div className="d-flex ac-je">
                      <textarea
                        className="editBtn rounded-3 p-2 w-90"
                        placeholder="Type here..."
                      />
                      <div className="mt-4 ms-1">
                        <SendIcon className="pointerView primary f2 fs-xs-17 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21 fs-xxl-22" />
                      </div>
                    </div>
                  </div>
                )}
                {noteIndex === index && openNotes && (
                  <AddNotes type="profile" setOpenNotes={setOpenNotes} />
                )}
              </div>
            );
          })}

          <div
            onClick={() => {
              setProfile(false);
              setEditProfile(false);
              setHistory(true);
            }}
            className="w-100 ac-jc d-flex mt-4"
          >
            <p className="pointerView f3 mt-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
              See more
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
