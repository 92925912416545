import { Search } from "@mui/icons-material";
import {
  animatedImg,
  editIcon,
  toggleOff,
  toggleOn,
  viewIcon,
} from "../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import ViewCustComp from "../components/Ecommerce/ViewCustComp";
import RequestCustList from "../components/Customers/RequestCustList";

const ViewCustomer = () => {
  const location = useLocation();
  console.log("LOCATION_____", location);
  return (
    <div className="dashRightView p-5">
      <div className="d-flex w-100 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, number or PO# to search" />
        <img src={animatedImg} className="searchManImg" />
      </div>

      <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Customer Decorative Request List
        </p>
        <div className="d-flex w-xs-100 mt-xs-5 position-relative">
          <input
            className="cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            placeholder="Search"
          />
          <Search className="gray f1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-19 fs-xl-19 fs-xxl-20 mt-1 searchVendor" />
        </div>
      </div>
      {location?.pathname !== "/customer-request-list" && <ViewCustComp />}
      {location?.pathname === "/customer-request-list" && <RequestCustList />}
    </div>
  );
};

export default ViewCustomer;
