import React, { useState } from "react";
import { searchIcon } from "../../../assets/img";
import MessageIcon from "@mui/icons-material/Message";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { event_category } from "../../../redux/api/DummyJson";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const PoRejectComp = ({ onShowPopupHander, type }) => {
  const navigate = useNavigate();
  const [remove, setRemove] = useState(null);
  const [removePoopup, setRemovePoppup] = useState(false);
  const removPopp = () => {
    setRemovePoppup(false);
  };
  const CheckCl = () => {
    setRemove(!remove);
    setRemovePoppup(!removePoopup);
  };

  const data = [
    {
      id: 1,
      vendor_id: "Ven_002",
      prid: "PR-001",
      vendor_name: "Kamesh",
      mobile_no: "9000000000",
      email: "kamesh@gmail.com",
      po_date: "-",
      total: "30000",
      ex_date: "12/12/2023",
      req_qty: "2900",
      total_qty: "600",
      rejected_date: "12/12/2023",
      status: 1,
    },
  ];

  return (
    <>
      <div className="w-100 overflow-scroll mt-4 position-relative">
        <table className="w-100 position-relative">
          {removePoopup && (
            <div className="min_pop d-flex ac-jc p-2 flex-column viewBoxDes rounded-2 mx-4 mt-4">
              <p className="px-2 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 py-2">
                Are you sure want to remove the vendor
              </p>
              <button
                onClick={() => removPopp()}
                class="cust-btn addbtn bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
              >
                Submit
              </button>
            </div>
          )}
          <thead>
            <tr>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  S.No
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Vendor ID
                </p>
              </th>{" "}
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Requested PO ID
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Vendor Name
                </p>
              </th>{" "}
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Vendor Email
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Vendor Mobile Number
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Total Requested Quantity
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Total Amount
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Expected Delivery Date
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Rejected Date
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  Notes
                </p>
              </th>
              <th>
                <p className=" pb-3 f2 fs-xs-11 fs-sm-12 px-4 text-nowrap fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                  View
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, ind) => {
              return (
                <tr className="my-3">
                  <td>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {ind + 1}
                    </p>
                  </td>
                  <td>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.vendor_id}
                    </p>
                  </td>{" "}
                  <td>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.prid}
                    </p>
                  </td>
                  <td>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.vendor_name}
                    </p>
                  </td>
                  <td>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.email}
                    </p>
                  </td>
                  <td>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.mobile_no}
                    </p>
                  </td>
                  <td>
                    <div className="d-flex ac-jc">
                      <input
                        placeholder=""
                        value={item?.total_qty}
                        className="datepicker-input w-50 viewBoxDes ms-2 p-2 bg-white rounded-2 border-0 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 me-2"
                      />
                    </div>
                  </td>
                  <td>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.total}
                    </p>
                  </td>
                  <td>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.ex_date}
                    </p>
                  </td>
                  <td>
                    <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary1 f3 pb-4">
                      {item?.rejected_date}
                    </p>
                  </td>
                  <td>
                    <p
                      className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center text-dark f3 pb-4"
                      onClick={() => onShowPopupHander()}
                    >
                      <CommentOutlinedIcon />
                    </p>
                  </td>
                  <td>
                    <button
                      onClick={() =>
                        navigate("/product-vendor-poreject-view?type=3", {
                          state: { type: type },
                        })
                      }
                      className="w-100 text-center bg-transparent border-0"
                    >
                      <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3 pb-4">
                        <VisibilityIcon />
                      </p>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex ac-jb w-100 mt-3">
        <button class="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
          Previous
        </button>
        <button class="cust-btn addbtn mt-3 ms-2 bg-primar text-white f3 fs-10 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded">
          Next
        </button>
      </div>
    </>
  );
};

export default PoRejectComp;
