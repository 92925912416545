import React, { useEffect, useState } from "react";
import { profilePic, searchIcon } from "../../../assets/img";
import MessageIcon from "@mui/icons-material/Message";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { event_category } from "../../../redux/api/DummyJson";
import { CommentOutlined, Edit } from "@mui/icons-material";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import PoProcess from "./PoProcess";
import PoAccept from "./PoAccept";
import PoReject from "./PoReject";
import { useNavigate, useSearchParams } from "react-router-dom";
import PoNotesViewPopup from "../../Popup/PoNotesViewPopup";
import AddFilesPopup from "../../Popup/AddFilesOverview";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import PorejectPoppup from "../components/PODetails/PorejectPoppup";
const ProductVendPoRejectViewScreen = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [addFile, setAddFile] = useState(false);
  const [activeTab, setActiveTab] = useState("Inprocess");
  const [searchParams, setSearchParams] = useSearchParams();
  const [productqty, setProductQty] = useState(500);
  const [edit, setEdit] = useState(true);
  const [statust, setStatust] = useState("");

  const onTabClick = (tab) => {
    setActiveTab(tab);
  };
  const status = [
    { id: 4, list: "Approved" },
    { id: 3, list: "Rejected" },
    { id: 2, list: "In process" },
    { id: 1, list: "Request" },
  ];
  const statushandl = (e) => {
    setStatust(e.target.value);
    if (e.target.value == "Rejected") {
      setRejectPoppup(true);
    } else {
      setRejectPoppup(false);
    }
    console.log("statustss", statust);
  };

  console.log("statust", statust);

  const [rejectpoppup, setRejectPoppup] = useState(false);

  const rejPopFun = () => {
    setRejectPoppup(!rejectpoppup);
  };

  const onShowPopupHander = () => {
    setShow(!show);
  };

  const toggleShowPopup = () => {
    setAddFile(!addFile);
  };

  useEffect(() => {
    if (searchParams?.get("type") == 1) {
      setStatust("1");
    } else if (searchParams?.get("type") == 2) {
      setStatust("2");
    } else if (searchParams?.get("type") == 3) {
      setStatust("3");
    }
  }, [searchParams]);

  return (
    <div className="dashRightView p-5 home_section trans">
      {addFile && <AddFilesPopup toggleShowPopup={toggleShowPopup} />}
      {show && <PoNotesViewPopup onShowPopupHander={onShowPopupHander} />}
      <p
        className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-2 d-flex align-items-center gap-1"
        role={"button"}
        onClick={() => navigate(-1)}
      >
        <KeyboardBackspaceIcon /> PO Detail View
      </p>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
      </div>
      <div className="w-100 ac-js ps-4 d-flex bannerVendor p-1 mt-3 rounded-3">
        <div className="d-flex as-jb flex-md-row flex-column w-xs-100 w-sm-100 rounded-2 w-md-90 w-lg-90 w-xl-90">
          <div className="d-lg-flex d-xl-flex d-md-flex as-js bg-light p-4 gap-3 rounded-3">
            <img src={profilePic} className="profileImg" />
            <div className="">
              <p className="primary1 f4 fs-xs-22 fs-sm-23 fs-md-24 fs-lg-25 fs-xl-26 fs-xxl-27">
                Dhanush
              </p>
              <div className="d-flex ac-jb gap-3">
                <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                  Vendor ID :
                </p>
                <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-dark">
                  Ven_001
                </p>
              </div>
              <div className="d-flex ac-jb gap-3">
                <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                  Email Id :
                </p>
                <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-dark">
                  {" "}
                  Total Count
                </p>
              </div>
              <div className="d-flex ac-jb gap-3">
                <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                  Mob No :
                </p>
                <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-dark">
                  +91 99999 90908
                </p>
              </div>
              <div className="d-flex ac-jb gap-3">
                <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                  InHands Date :
                </p>
                <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-dark">
                  12/12/24
                </p>
              </div>
              <div className="d-flex ac-jb gap-3">
                <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                  Total Qty =
                </p>
                <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-dark">
                  2900
                </p>
              </div>
              <div className="d-flex ac-jb gap-3">
                <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 primary1">
                  Total Amount=
                </p>
                <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 text-dark">
                  500000
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex ac-je w-100 mt-sm-3">
        <div className="d-flex ac-jb flex-md-row flex-column">
          <select
            placeholder="Status"
            onChange={statushandl}
            value={statust}
            className="w-30 editBtnSelect f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 primary1 rounded-3"
            name=""
            id=""
          >
            {status?.map((item, ind) => {
              return (
                <option key={ind} value={item?.id}>
                  {item?.list}
                </option>
              );
            })}
            {/* <option>Rejected</option>
            <option>In process</option>
            <option>Request</option> */}
          </select>
          <p className="f2 text-nowrap fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont px-2 px-3">
            Total Count : {"02"}
          </p>
          <div className="d-flex border-search">
            <img src={searchIcon} alt="search" className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
              placeholder="Search"
            />
          </div>
        </div>
      </div>

      {/* PO REQUEST */}
      {+searchParams?.get("type") == 1 && (
        <PoProcess
          toggleShowPopup={toggleShowPopup}
          onShowPopupHander={onShowPopupHander}
        />
      )}

      {/* PO ACCEPT */}
      {+searchParams?.get("type") == 2 && (
        <PoAccept
          onShowPopupHander={onShowPopupHander}
          toggleShowPopup={toggleShowPopup}
        />
      )}

      {/* PO REJECT */}
      {+searchParams?.get("type") == 3 && (
        <PoReject
          toggleShowPopup={toggleShowPopup}
          onShowPopupHander={onShowPopupHander}
        />
      )}
    </div>
  );
};

export default ProductVendPoRejectViewScreen;
