import { useNavigate } from "react-router-dom";
import { toggleOff, toggleOn, viewIcon } from "../../assets/img";
import { customerList } from "../../redux/api/DummyJson";
import { Search } from "@mui/icons-material";

const RegisteredCustomerList = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="d-flex ac-jb mt-4 flex-wrap">
        <p className="f4 fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
          Registered Customer List
        </p>
        <div className="d-flex w-xs-100 mt-xs-5 position-relative">
          <input
            className="cust-btn searchFill w-xs-100 ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15"
            placeholder="Search"
          />
          <Search className="gray f1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-19 fs-xl-19 fs-xxl-20 mt-1 searchVendor" />
        </div>
      </div>
      <div className="wholeDesignCustomerDeco d-flex mt-4 border-bottom">
        <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          S.no
        </p>
        <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Customer ID
        </p>
        <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          First Name
        </p>
        <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Last Name
        </p>

        <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Organization
        </p>
        <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Status
        </p>
        <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex as-jc">
          Options
        </p>
      </div>
      {customerList?.map((item, index) => {
        return (
          <div className="wholeDesignCustomerDeco d-flex mt-5">
            <p className="w-10 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
              {index + 1}
            </p>
            <p className="pointerView w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
              {item?.CustomerId}
            </p>
            <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
              {item?.name}
            </p>
            <p className="w-20 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
              {item?.name}
            </p>

            <p className="w-15 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
              {item?.organization}
            </p>
            <div className="w-10 d-flex ac-jc">
              {item?.status === true && (
                <div>
                  <img src={toggleOn} className="toggleOnDes" />
                </div>
              )}
              {item?.status === false && (
                <div>
                  <img src={toggleOff} className="toggleOnDes" />
                </div>
              )}
            </div>
            <div className="w-15 d-flex ac-jc primary1">
              <div
                onClick={() =>
                  navigate("/view-customer-details", { state: { data: item } })
                }
                className="viewBoxDes pointerView p-2 bg-white rounded-2"
              >
                <img src={viewIcon} className="viewDes" />
              </div>
              {/* <div className="viewBoxDes ms-2 p-2 bg-white rounded-2">
                <img src={editIcon} className="viewDes" />
              </div> */}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default RegisteredCustomerList;
