import React, { useState } from 'react'
import TvIcon from '@mui/icons-material/Tv';
import { sub_menu, Country, Hsn_type, Hsn_type_Child2, Hsn_type_Child3, Hsn_type_Child4, Supplier, supplier, supplier_rating } from '../../redux/api/DummyJson'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Edit, EditCalendarOutlined } from '@mui/icons-material';
import EditNoteIcon from '@mui/icons-material/EditNote';

const LibraryLeftComp = ({ edit }) => {
    const [catogoryShow, setCatogoryShow] = useState(false)
    const [productNameShow, setProductNameShow] = useState(false)
    const [childMenu, setChildMenu] = useState(false)
    const [childMenu2, setChildMenu2] = useState(false)
    const [decorativeShow, setDecorativeShow] = useState(false)
    const [hsnTypeShow, setHsnTypeShow] = useState(false)
    const [PackingSizeShow, setPackingSizeShow] = useState(false)
    const [countryShow, setCountryShow] = useState(false)
    const [catogoryShow2, setCatogoryShow2] = useState(false)
    const [catogoryShow3, setCatogoryShow3] = useState(false)

    const [editComp, setEditComp] = useState(false)

    const [Dropdown, setDrop] = useState(0)


    // input box
    const [catogory, setCatogory] = useState('Mobile phones')
    const [productName, setProductName] = useState('Vivo')
    const [productSuplierCode, setProductSuplierCode] = useState('CODE1003')
    const [decorativeMethod, setDecorativeMethod] = useState('')
    const [hsnType, sethsnType] = useState('')
    const [packingSize, setPackingSize] = useState('')
    const [country, setCountry] = useState('India')
    const [MRP, setMRP] = useState('10,00.00')
    const [color, setColor] = useState('Red')
    const [subcatogory2, setSubcatogory2] = useState(false)
    const [subcatogory3, setSubcatogory3] = useState(false)


    const onCatogory = (e) => {
        setCatogory(e.target.value)
        if (catogory.length >= 2) {
            setCatogoryShow(true)
        } else {
            if (catogory.length >= 0)
                setCatogoryShow(false)
        }
    }
    const onProduct = (e) => {
        setProductName(e.target.value)
        if (productName.length >= 2) {
            setProductNameShow(true)
        } else {
            if (productName.length >= 0)
                setProductNameShow(false)
            // setProductSuplierCode('')
        }
        setCatogoryShow(false)
    }
    const onDecorative = (e) => {
        setDecorativeMethod(e.target.value)
        if (decorativeMethod.length >= 2) {
            setDecorativeShow(true)
        } else {
            if (decorativeMethod.length >= 0)
                setDecorativeShow(false)
        }
    }
    const onHsnTyoe = (e) => {
        sethsnType(e.target.value)
        if (hsnType.length >= 2) {
            setHsnTypeShow(true)
        } else {
            if (hsnType.length >= 0)
                setHsnTypeShow(false)
        }
    }
    const onPackingSize = (e) => {
        setPackingSize(e.target.value)
        if (packingSize.length >= 2) {
            setPackingSizeShow(true)
        } else {
            if (packingSize.length >= 0)
                setPackingSizeShow(false)
        }
    }
    const onCountry = (e) => {
        setCountry(e.target.value)
        if (country.length >= 2) {
            setCountryShow(true)
        } else {
            if (country.length >= 0)
                setCountryShow(false)
        }
    }
    return (
        <div className='w-100'>
            <div className='d-flex ac-jb flex-m-r'>
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                    <p className="text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        Category
                    </p>
                    <div className='position-relative'>
                        <input placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            value={catogory}
                            onChange={onCatogory}
                            disabled={edit ? false : true}
                        />
                        <button className='drop_down cust-btn' onClick={() => { edit ? setCatogoryShow(!catogoryShow) && setChildMenu(false) : setCatogoryShow(false) }}>
                            <KeyboardArrowDownIcon />
                        </button>
                    </div>
                    <div className={`${catogoryShow && 'submenu_1'} submenu_cont_1 overflow-scroll z-3`}>
                        {sub_menu?.map((item) => {
                            return (
                                <div className='d-flex hover-cust'>
                                    {item.list !== 'Game consoles' && <button className='px-2 cust-btn text-start py-1 w-100' onClick={() => { setCatogoryShow(false); setCatogory(item?.list); setChildMenu(false) }}>{item?.list}
                                    </button>}
                                    {item.list === 'Game consoles' && <button className='px-2 cust-btn text-start py-1 w-100' onClick={() => setChildMenu(!childMenu)}>{item?.list}
                                    </button>}
                                    {item.list === 'Game consoles' &&
                                        <button className='cust-btn position-relative' onClick={() => setChildMenu(!childMenu)}>
                                            {childMenu ?
                                                <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
                                        </button>}
                                </div>
                            )
                        })}
                    </div>
                    <div className={childMenu ? 'submenu_child_1' : 'submenu_child_2'}>
                        <div className={`${catogoryShow && 'submenu_2'} submenu_cont_1 overflow-scroll z-3`}>
                            {sub_menu?.map((item) => {
                                return (
                                    <div className='d-flex hover-cust'>
                                        <button className='px-2 cust-btn text-start py-1 w-100' onClick={() => { setCatogoryShow(false); setCatogory(item?.list); setChildMenu(false) }}>{item?.list}
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        Product
                    </p>
                    <div className='position-relative'>
                        <input
                            placeholder="Required Field"
                            className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            value={productName}
                            onChange={onProduct}
                            disabled={edit ? false : true}
                        />
                        {productNameShow && <button className='drop_down cust-btn' onClick={() => { setProductNameShow(!productNameShow) }}>
                            <KeyboardArrowDownIcon />
                        </button>}
                        <div className={`${productNameShow && 'submenu_1'} submenu_cont_1 overflow-scroll z-3`}>
                            {sub_menu?.map((item) => {
                                return (
                                    <div className=''>
                                        <button className='px-2 cust-btn text-start py-1 w-100' onClick={() => { setProductNameShow(false); setProductName(item?.list); setProductSuplierCode('code1003') }}>{item?.list}</button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex ac-jb flex-m-r'>
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        Product Supplier Code
                    </p>
                    <input
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        disabled={edit ? false : true}
                        value={productSuplierCode}
                    // onChange={(e) => setProductSuplierCode(e.target.value)}
                    />
                </div>
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        MRP
                    </p>
                    <input
                        placeholder="Required Field"
                        disabled={edit ? false : true}
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        value={MRP}
                        onChange={(e) => setMRP(e.target.value)}
                    />
                </div>
            </div>
            <div className="w-100">
                {/* <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                    Product Website
                </p>
                <input
                    placeholder=""
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                /> */}
            </div>
            <div className='d-flex ac-jb flex-m-r'>
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        Size or dimensions
                    </p>
                    <input
                        placeholder="Required Field"
                        disabled={edit ? false : true}
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                </div>
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        Color
                    </p>
                    <input
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                        placeholder="Required Field"
                        disabled={edit ? false : true}
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                </div>
            </div>
            <div className='d-flex ac-jb flex-m-r'>
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        Weight
                    </p>
                    <input
                        placeholder="Required Field"
                        disabled={edit ? false : true}
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                </div>
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        Materials used in construction
                    </p>
                    <input
                        placeholder="Required Field"
                        disabled={edit ? false : true}
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                </div>
            </div>
            <div className='d-flex ac-jb flex-m-r'>
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        Design features
                    </p>
                    <input
                        placeholder="Required Field"
                        disabled={edit ? false : true}
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    />
                </div>
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        Country of Origin
                    </p>
                    <div className='position-relative'>
                        <input placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            value={country}
                            onChange={onCountry}
                            disabled={edit ? false : true}
                        />
                        <button className='drop_down cust-btn' onClick={() => { edit ? setCountryShow(!countryShow) : setCountryShow(false) }}>
                            <KeyboardArrowDownIcon />
                        </button>
                    </div>
                    <div className={`${countryShow && 'submenu_1'} submenu_cont_1 overflow-scroll z-3`}>
                        {Country?.map((item) => {
                            return (
                                <div className='d-flex hover-cust'>
                                    <button className='px-2 cust-btn text-start py-1 w-100' onClick={() => { setCountryShow(false); setCountry(item?.list); }}>{item?.list}
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="w-100">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                    Product Description
                </p>
                <textarea
                    placeholder="Autogenerate"
                    disabled={edit ? false : true}
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value="Click on an image to download it, and on a file name to edit it. You can also create folders, and drag artwork into them."

                />
            </div>
            <div className='d-flex ac-jb flex-m-r'>
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        HSN Code
                    </p>
                    <div className='position-relative'>
                        <input placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            value={hsnType}
                            onChange={onHsnTyoe}
                            disabled={edit ? false : true}
                        />
                        <button className='drop_down cust-btn' onClick={() => { edit ? setHsnTypeShow(!hsnTypeShow) && setChildMenu2(false) : setHsnTypeShow(false) }}>
                            <KeyboardArrowDownIcon />
                        </button>
                    </div>
                    <div className={`${hsnTypeShow && 'submenu_1 h-auto'} submenu_cont_1 overflow-scroll z-3`}>
                        {Hsn_type?.map((item, ind) => {
                            return (
                                <div className='d-flex hover-cust'>
                                    <button className='px-2 cust-btn text-start py-1 w-100' onClick={() => { Dropdown === ind ? setChildMenu2(!childMenu2) : setDrop(ind) }}>{item?.list}
                                    </button>

                                    {/* {ind === childM/enu3 && */}
                                    <button className='cust-btn position-relative' onClick={() => { Dropdown === ind ? setChildMenu2(!childMenu2) && setDrop(ind) : setDrop(ind) }}>
                                        {/* {childMenu2 && Dropdown === ind ? */}
                                        <KeyboardArrowRightIcon />
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                    {Dropdown === 0 &&
                        <div className={childMenu2 ? 'submenu_child_1' : 'submenu_child_2'}>
                            <div className={`${hsnTypeShow && 'submenu_2 h-auto sub-2'} submenu_cont_1 overflow-scroll z-3`}>
                                {Hsn_type_Child2?.map((item) => {
                                    return (
                                        <div className='d-flex hover-cust'>
                                            <button className='px-2 cust-btn text-start py-1 w-100' onClick={() => { setHsnTypeShow(false); sethsnType(item?.list); setChildMenu2(false) }}>{item?.list}
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                    {Dropdown === 1 &&
                        <div className={childMenu2 ? 'submenu_child_1' : 'submenu_child_2'}>
                            <div className={`${hsnTypeShow && 'submenu_2 h-auto sub-2'} submenu_cont_1 overflow-scroll z-3`}>
                                {Hsn_type_Child3?.map((item) => {
                                    return (
                                        <div className='d-flex hover-cust'>
                                            <button className='px-2 cust-btn text-start py-1 w-100' onClick={() => { setHsnTypeShow(false); sethsnType(item?.list); setChildMenu2(false) }}>{item?.list}
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                    {Dropdown === 2 &&
                        <div className={childMenu2 ? 'submenu_child_1' : 'submenu_child_2'}>
                            <div className={`${hsnTypeShow && 'submenu_2 h-auto sub-2'} submenu_cont_1 overflow-scroll z-3`}>
                                {Hsn_type_Child4?.map((item) => {
                                    return (
                                        <div className='d-flex hover-cust'>
                                            <button className='px-2 cust-btn text-start py-1 w-100' onClick={() => { setHsnTypeShow(false); sethsnType(item?.list); setChildMenu2(false) }}>{item?.list}
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        Product Packaging Size
                    </p>
                    <div className='position-relative'>
                        <input placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            value={packingSize}
                            onChange={onPackingSize}
                            disabled={edit ? false : true}
                        />
                        <button className='drop_down cust-btn' onClick={() => { edit ? setPackingSizeShow(!PackingSizeShow) : setPackingSizeShow(false) }}>
                            <KeyboardArrowDownIcon />
                        </button>
                    </div>
                    <div className={`${PackingSizeShow && 'submenu_1'} submenu_cont_1 overflow-scroll z-3`}>
                        {sub_menu?.map((item) => {
                            return (
                                <div className='d-flex hover-cust'>
                                    <button className='px-2 cust-btn text-start py-1 w-100' onClick={() => { setPackingSizeShow(false); setPackingSize(item?.list); }}>{item?.list}
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className='d-flex as-jb flex-m-r'>
                <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 my-2">
                    <div className='d-flex w-50 ac-jb'>
                        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                            Supplier
                        </p>
                        {edit && <button className='cust-btn' onClick={() => setEditComp(!editComp)}>
                            <EditNoteIcon />
                        </button>}
                    </div>
                    {supplier?.map((item, ind) => {

                        return (
                            <div className='d-flex w-70 ac-jb'>
                                <p className="primary text-decoration-underline cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                    {item?.list}
                                </p>
                                {editComp && <select className='cust-btn'>
                                    <option className="primary cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                        1
                                    </option>
                                    <option className="primary cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                        2
                                    </option>
                                    <option className="primary cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                        3
                                    </option>
                                    <option className="primary cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                        4
                                    </option>
                                    <option className="primary cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                        5
                                    </option>
                                </select>}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="w-100 my-3">
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
                    Product Website
                </p>
                <div className='d-flex ac-js gap-2'>
                    <TvIcon className='primary text-decoration-underline cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start' />
                    <p className="primary text-decoration-underline cp f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                        Link will open a new tab
                    </p>
                </div>
            </div>
            <div className="w-100 mt-5">
                <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                    Last updated on August 28, 2023 at 03:10 AM</p>
            </div>
        </div>
    )
}

export default LibraryLeftComp