import React, { useState } from 'react'
import { emptyImg, uploadIcon } from '../../assets/img';
import Carousel from 'react-bootstrap/Carousel';
import { productDetailImg } from '../../redux/api/constants';


const LibraryRightComp = () => {
    const [logo, setLogo] = useState({});
    const [textShow, setTextShow] = useState(true)
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };
    return (
        <div className="d-flex gap-5 flex-column as-jc w-100">
            <h4 className='primary mb-3'>Product Image</h4>
            <div className="file-upload" style={{ cursor: 'pointer' }}>
                <div className="file-icon d-grid ac-jc">
                    <div className='d-flex flex-column ac-jc'>
                        <Carousel activeIndex={index} onSelect={handleSelect} {...productDetailImg}>
                            <Carousel.Item>
                                <img src={uploadIcon} className='placeholder_icon1 object-fit-contain' />        <Carousel.Item>
                                </Carousel.Item>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={uploadIcon} className='placeholder_icon1 object-fit-contain' />
                                <Carousel.Item>
                                </Carousel.Item>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={uploadIcon} className='placeholder_icon1 object-fit-contain' />        <Carousel.Item>
                                </Carousel.Item>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    {/* <div className='d-flex ac-js gap-3 mt-2'>
                        <img
                            className="placeholder_icon object-fit-cover mt-4 rounded-2"
                            src={emptyImg}
                            alt="logo"
                        />
                        <img
                            className="placeholder_icon object-fit-cover mt-4 rounded-2"
                            src={emptyImg}
                            alt="logo"
                        />
                        <img
                            className="placeholder_icon object-fit-cover mt-4 rounded-2"
                            src={emptyImg}
                            alt="logo"
                        />
                    </div> */}
                    {/* <div className='d-flex as-jb w-100 w-md-50 mt-3'>
                        <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100 my-2">
                            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                Colors
                            </p>

                        </div>
                        <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100 my-2">
                            <p className="black primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                Product Colors Is Blue, Secondary Color is Yellow
                            </p>
                        </div>
                    </div>
                    <div className='d-flex as-jb w-100 w-md-50 mt-2'>
                        <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100">
                            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                Sized
                            </p>
                        </div>
                        <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100 ">
                            <p className="black primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                Product Sizes is Medium                            </p>
                        </div>
                    </div>
                    <div className='d-flex as-jb w-100 w-md-50 mt-2'>
                        <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100 ">
                            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                Imprint Methods
                            </p>

                        </div>
                        <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100">
                            <p className="black primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                3D Laser Engraving </p>
                        </div>
                    </div>
                    <div className=" mt-4 w-100">
                        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                            Tags
                        </p>
                        <input
                            placeholder="Add a tag"
                            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        />
                    </div> */}
                </div>
            </div>
            <h4 className='primary mb-3'>Markup Image</h4>
            <div className="file-upload" style={{ cursor: 'pointer' }}>
                <div className="file-icon d-grid ac-jc">
                    <div className='d-flex flex-column ac-jc'>
                        <Carousel activeIndex={index} onSelect={handleSelect} {...productDetailImg}>
                            <Carousel.Item>
                                <img src={uploadIcon} className='placeholder_icon1 object-fit-contain' />        <Carousel.Item>
                                </Carousel.Item>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={uploadIcon} className='placeholder_icon1 object-fit-contain' />
                                <Carousel.Item>
                                </Carousel.Item>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={uploadIcon} className='placeholder_icon1 object-fit-contain' />        <Carousel.Item>
                                </Carousel.Item>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    {/* <div className='d-flex ac-js gap-3 mt-2'>
                        <img
                            className="placeholder_icon object-fit-cover mt-4 rounded-2"
                            src={emptyImg}
                            alt="logo"
                        />
                        <img
                            className="placeholder_icon object-fit-cover mt-4 rounded-2"
                            src={emptyImg}
                            alt="logo"
                        />
                        <img
                            className="placeholder_icon object-fit-cover mt-4 rounded-2"
                            src={emptyImg}
                            alt="logo"
                        />
                    </div> */}
                    {/* <div className='d-flex as-jb w-100 w-md-50 mt-3'>
                        <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100 my-2">
                            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                Colors
                            </p>

                        </div>
                        <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100 my-2">
                            <p className="black primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                Product Colors Is Blue, Secondary Color is Yellow
                            </p>
                        </div>
                    </div>
                    <div className='d-flex as-jb w-100 w-md-50 mt-2'>
                        <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100">
                            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                Sized
                            </p>
                        </div>
                        <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100 ">
                            <p className="black primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                Product Sizes is Medium                            </p>
                        </div>
                    </div>
                    <div className='d-flex as-jb w-100 w-md-50 mt-2'>
                        <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100 ">
                            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                Imprint Methods
                            </p>

                        </div>
                        <div className="w-xl-50 w-lg-50 w-md-100 w-sm-100 w-xs-100">
                            <p className="black primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 text-start">
                                3D Laser Engraving </p>
                        </div>
                    </div>
                    <div className=" mt-4 w-100">
                        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                            Tags
                        </p>
                        <input
                            placeholder="Add a tag"
                            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        />
                    </div> */}
                </div>
            </div>

        </div>
    )
}

export default LibraryRightComp