import React, { useState } from "react";
import {
  animatedImg,
  product,
  searchIcon,
  toggleOff,
  toggleOn,
} from "../../assets/img";
import CachedIcon from "@mui/icons-material/Cached";
import { useLocation, useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { Search } from "@mui/icons-material";

const MyProductListComp = ({
  loc,
  setDecorative,
  setProduct,
  place,
  path,
  myProducts,
  name,
}) => {
  const navigate = useNavigate();
  console.log(loc, "loc");
  console.log(place, "place");
  const location = useLocation();
  console.log(location?.pathname, "/myproduct-detail");
  const path_line = location?.pathname;
  const [permissions, setPermissions] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const rolefunction = (id) => {
    let roleId = [...permissions];
    let ids = roleId.indexOf(id);

    if (roleId.includes(id)) {
      roleId.splice(ids, 1);
    } else {
      roleId.push(id);
    }

    if (roleId.length == [1, 2, 3, 4].length) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }

    setPermissions(roleId);
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setPermissions([1, 2, 3, 4].map((li) => li));
    if (isCheckAll) {
      setPermissions([]);
    }
  };

  return (
    <>
      <div className="mt-5 banner-left">
        <div className="d-flex ac-jb flex-column flex-md-row w-100">
          <div className="d-flex gap-3">
            <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
              <option hidden>Select Categories</option>
              <option>Mobile Phone</option>
              <option>Water Bottle</option>
            </select>
            <select className="w-auto mt-2 editBtnSelect fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3">
              <option hidden>Select Status</option>
              <option>Active</option>
              <option>Deactive</option>
            </select>
          </div>
          <div className="d-flex ac-jc place-holde justify-content-md-end gap-3 flex-wrap w-100 w-md-90 mb-md-0  mb-4">
            <input
              type="text"
              className="get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4 px-3 w-auto f3 fs-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-auto"
              placeholder={"Search Product Name"}
            />
            <div className="d-flex ac-jc gap-3">
              <button
                // onClick={() => navigate("/add-vendor")}
                className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
              >
                <Search />
              </button>
              <button
                // onClick={() => navigate("/add-vendor")}
                className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
              >
                <CachedIcon />
              </button>
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary">
            <option value="10">10</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
            Total Count : 15
          </p>
        </div>
      </div>
      <div className="w-95 d-flex flex-md-row flex-column mt-4 gap-2">
        <div
          className="d-flex gap-1 mt-4"
          onClick={() => handleSelectAll()}
          role={"button"}
        >
          <DoneIcon
            className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 mt-3 ${
              isCheckAll ? "checkIcon" : "checkIcon1"
            }`}
          />
          <p
            className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 mt-3 ${
              isCheckAll ? "black" : "primary1"
            }`}
          >
            Select All
          </p>
        </div>
        {permissions?.length > 0 && (
          <div className="d-flex gap-2 mt-3">
            <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              Active
            </button>
            <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
              Deactive
            </button>
          </div>
        )}
      </div>
      <div className="w-100 d-flex ac-js flex-wrap mt-5 ">
        <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 position-relative">
          <div className="prod-list-box">
            <div
              className="cust-btn d-flex ac-jc rounded-3"
              onClick={() => {
                navigate("/myproduct-detail", {
                  state: { status: "accept", name: name },
                });
              }}
            >
              <img src={product} alt="" className="custom-w-h rounded-3" />
            </div>
            <p
              className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark mb-2 text-custom-res"
              onClick={() => {
                navigate("/myproduct-detail", {
                  state: { status: "accept", name: name },
                });
              }}
            >
              {name}
            </p>
            <p
              className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res"
              onClick={() => {
                navigate("/myproduct-detail", {
                  state: { status: "accept", name: name },
                });
              }}
            >
              Mobile Phone
            </p>
            <p className="d-flex ac-jb f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res ">
              Status
              <span>
                <img src={toggleOn} className="toggleOnDes" alt="icon" />
              </span>
            </p>
            <p
              className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Accept-text"
              onClick={() => {
                navigate("/myproduct-detail", {
                  state: { status: "accept", name: name },
                });
              }}
            >
              Accepted
            </p>
          </div>
          <div className="d-flex mt-4 ms-1 position-absolute top-0 end-0 px-4">
            <DoneIcon
              onClick={() => rolefunction(1)}
              className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
                permissions?.includes(1) ? "checkIcon" : "checkIcon1"
              }`}
            />
            <p
              className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
                permissions?.includes(1) ? "black" : "primary1"
              }`}
            ></p>
          </div>
        </div>
        <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 position-relative">
          <div className="prod-list-box">
            <div
              className="cust-btn d-flex ac-jc rounded-3"
              onClick={() => {
                navigate("/myproduct-detail", {
                  state: { status: "accept", name: name },
                });
              }}
            >
              <img src={product} alt="" className="custom-w-h rounded-3" />
            </div>
            <p
              className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark mb-2 text-custom-res"
              onClick={() => {
                navigate("/myproduct-detail", {
                  state: { status: "accept", name: name },
                });
              }}
            >
              {name}
            </p>
            <p
              className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res"
              onClick={() => {
                navigate("/myproduct-detail", {
                  state: { status: "accept", name: name },
                });
              }}
            >
              Mobile Phone
            </p>
            <p className="d-flex ac-jb f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res">
              Status
              <span>
                <img src={toggleOff} className="toggleOnDes" alt="icon" />
              </span>
            </p>
            <p
              className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Accept-text"
              onClick={() => {
                navigate("/myproduct-detail", {
                  state: { status: "accept", name: name },
                });
              }}
            >
              Accepted
            </p>
          </div>
          <div className="d-flex mt-4 ms-1 position-absolute top-0 end-0 px-4">
            <DoneIcon
              onClick={() => rolefunction(2)}
              className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
                permissions?.includes(2) ? "checkIcon" : "checkIcon1"
              }`}
            />
            <p
              className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
                permissions?.includes(2) ? "black" : "primary1"
              }`}
            ></p>
          </div>
        </div>
        <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 position-relative">
          <div className="prod-list-box">
            <div
              className="cust-btn d-flex ac-jc rounded-3"
              onClick={() => {
                navigate("/myproduct-detail", {
                  state: { status: "accept", name: name },
                });
              }}
            >
              <img src={product} alt="" className="custom-w-h rounded-3" />
            </div>
            <p
              className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark mb-2 text-custom-res"
              onClick={() => {
                navigate("/myproduct-detail", {
                  state: { status: "accept", name: name },
                });
              }}
            >
              {name}
            </p>
            <p
              className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res"
              onClick={() => {
                navigate("/myproduct-detail", {
                  state: { status: "accept", name: name },
                });
              }}
            >
              Mobile Phone
            </p>
            <p className="d-flex ac-jb f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res">
              Status
              <span>
                <img src={toggleOn} className="toggleOnDes" alt="icon" />
              </span>
            </p>
            <p
              className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Accept-text"
              onClick={() => {
                navigate("/myproduct-detail", {
                  state: { status: "accept", name: name },
                });
              }}
            >
              Accepted
            </p>
          </div>
          <div className="d-flex mt-4 ms-1 position-absolute top-0 end-0 px-4">
            <DoneIcon
              onClick={() => rolefunction(3)}
              className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
                permissions?.includes(3) ? "checkIcon" : "checkIcon1"
              }`}
            />
            <p
              className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
                permissions?.includes(3) ? "black" : "primary1"
              }`}
            ></p>
          </div>
        </div>
        <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2 position-relative">
          <div className="prod-list-box">
            <div
              className="cust-btn d-flex ac-jc rounded-3"
              onClick={() => {
                navigate("/myproduct-detail", {
                  state: { status: "accept", name: name },
                });
              }}
            >
              <img src={product} alt="" className="custom-w-h rounded-3" />
            </div>
            <p
              className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-dark mb-2 text-custom-res"
              onClick={() => {
                navigate("/myproduct-detail", {
                  state: { status: "accept", name: name },
                });
              }}
            >
              {name}
            </p>
            <p
              className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res"
              onClick={() => {
                navigate("/myproduct-detail", {
                  state: { status: "accept", name: name },
                });
              }}
            >
              Mobile Phone
            </p>
            <p className="d-flex ac-jb f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 primary1 text-custom-res">
              Status
              <span>
                <img src={toggleOff} className="toggleOnDes" alt="icon" />
              </span>
            </p>
            <p
              className="f4 fs-11 fs-sm-12 text-custom-res fs-lg-13 parag mb-0 Accept-text"
              onClick={() => {
                navigate("/myproduct-detail", {
                  state: { status: "accept", name: name },
                });
              }}
            >
              Accepted
            </p>
          </div>
          <div className="d-flex mt-4 ms-1 position-absolute top-0 end-0 px-4">
            <DoneIcon
              onClick={() => rolefunction(4)}
              className={`rounded-5 f2 p-1 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ${
                permissions?.includes(4) ? "checkIcon" : "checkIcon1"
              }`}
            />
            <p
              className={`f1 ms-2 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ${
                permissions?.includes(4) ? "black" : "primary1"
              }`}
            ></p>
          </div>
        </div>
      </div>{" "}
      <div className="d-flex justify-content-between mt-3">
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Previous
        </button>
        <button className="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
          Next
        </button>
      </div>
    </>
  );
};

export default MyProductListComp;
