import { Search } from "@mui/icons-material";
import NavBar from "../components/NavBar";
import manImage from "../assets/img/cd8c65c7813855d5f58cf91801b1d256.jpg";
import { searchIcon, toggleOff, toggleOn } from "../assets/img";
import { adminData } from "../redux/api/DummyJson";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ProfileNavBar from "../components/ProfileNavBar";
import { useNavigate } from "react-router-dom";
import { editIcon, viewIcon } from "../assets/img";
import { useDispatch } from "react-redux";
import { saveAlertMessage } from "../redux/slice/alertMessage";
import {
  useLazyProfile_listQuery,
  useLazyProfile_statusQuery,
  useProfileListMutation,
} from "../redux/api/api";
import loderimage from "../assets/img/loderimage.png";
import { toast } from "react-hot-toast";

const AdminPanel = () => {
  const [enable, setEnable] = useState(false);
  const [list, setList] = useState([]);
  const [count, setcount] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [pageRow, setPageRow] = useState(10);
  const [page, setPage] = useState(1);
  const [pagenation, setPagination] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [listAdmin] = useLazyProfile_listQuery();
  const [userList] = useProfileListMutation();
  const [status] = useLazyProfile_statusQuery();

  const user = JSON.parse(localStorage.getItem("user"));
  const getList = (event, row) => {
    let formdata = new FormData();

    let params = `?page=${page}`;
    formdata.append("vendor_id", user?.vendor?.id);

    setSearchValue(event);

    if (event?.length > 0) {
      formdata.append("search", event);
    }

    if (row) {
      setPageRow(row);
      formdata.append("rows", row);
    }
    userList({ payload: formdata, params: params })
      .unwrap()
      .then((res) => {
        console.log(res, "list");
        if (res?.status == "success") {
          setList(res?.vendor_users);
          setPagination(res?.pagination);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const change = (id) => {
    if (id !== user?.id) {
      setEnable(true);
      status(id)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            toast.success(res?.data?.message || res?.message);
            getList();
            setEnable(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setEnable(false);
        });
    }
  };

  useEffect(() => {
    if (user?.vendor?.id) {
      getList();
    }
  }, [page]);

  // console.log("user", user);

  return (
    <div className="dashRightView overflow-scroll">
      <div className="d-flex w-95 searchField p-2">
        <Search className="gray f1 fs-sm-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-1" />
        <input
          className="cust-btn ms-4 w-100"
          placeholder="Enter Project Name, Client Name, number or PO# to search"
        />
        <img src={manImage} className="searchManImg" />
      </div>
      <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary mt-5">
        Vendor Profile
      </p>
      <div className="w-100 ac-jb d-flex flex-md-row flex-column mt-4">
        <div className="d-flex w-100">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
            Show
          </p>
          <select
            className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
            onChange={(e) => getList(searchValue, e.target.value)}
            value={pageRow}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
            Entries
          </p>
        </div>
        <div className="d-flex justify-content-end w-100">
          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont px-4">
            Total Count : {pagenation?.total}
          </p>
        </div>
        <div className="d-flex flex-md-row flex-column w-100">
          <div className="d-flex search_new_design mx-2">
            <img src={searchIcon} className="searchiConImg" />
            <input
              className="cust-btn ms-1 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 w-100"
              placeholder="Search"
              onChange={(e) => getList(e.target.value, pageRow)}
              value={searchValue}
            />
          </div>
          <button
            //  onClick={() =>
            //     navigate("/vendor-details", { state: { type: "contact" } })
            //   }
            onClick={() =>
              navigate("/edit-profile", { state: { type: "add" } })
            }
            className="cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          >
            + Add Profile
          </button>
        </div>
      </div>
      <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
        <table className="table-design-new" style={{ width: "100%" }}>
          <thead>
            <tr className="border-bottom w-100">
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  S.No
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Image
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Name
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Email
                </p>
              </th>
              <th>
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Department
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Status
                </p>
              </th>
              <th>
                {" "}
                <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                  Options
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item, index) => {
              return (
                <tr>
                  <td>
                    <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {index + 1}
                    </p>
                  </td>
                  <td>
                    <div className="w-15">
                      <img
                        src={item?.image_url ? item?.image_url : loderimage}
                        className="imgDashboard1"
                      />
                    </div>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1 text-capitalize">
                      {item?.first_name ? item?.first_name : ""}{" "}
                      {item?.last_name ? item?.last_name : ""}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {item?.email}
                    </p>
                  </td>
                  <td>
                    <p className="pointerView  f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                      {/* {item?.role} */}
                      {item?.role?.role}
                    </p>
                  </td>

                  <td>
                    <div
                      onClick={() => change(item?.id)}
                      className="d-flex ac-jc"
                    >
                      {item?.status === 1 && (
                        <div>
                          <img src={toggleOn} className="toggleOnDes" />
                        </div>
                      )}
                      {item?.status === 0 && (
                        <div>
                          <img src={toggleOff} className="toggleOnDes" />
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex ac-jc primary1">
                      <div
                        onClick={() =>
                          navigate("/view-profile", {
                            state: { type: "view", data: item },
                          })
                        }
                        className="viewBoxDes p-2 mb-2 bg-white rounded-2"
                      >
                        <img src={viewIcon} className="viewDes" />
                      </div>
                      <div
                        onClick={() =>
                          navigate("/edit-profile", {
                            state: { type: "edit", data: item },
                          })
                        }
                        className="viewBoxDes ms-2 p-2 mb-2 bg-white rounded-2"
                      >
                        <img src={editIcon} className="viewDes" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == 1 && "opacity-50"
          }`}
          onClick={() => setPage(page - 1)}
          disabled={page == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
            page == pagenation?.last_page && "opacity-50"
          }`}
          onClick={() => setPage(page + 1)}
          disabled={page == pagenation?.last_page ? true : false}
        >
          Next
        </button>
      </div>
    </div>
  );
};
export default AdminPanel;
